import React, {Fragment} from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';

import StartPage from "./start";
import DashboardPage from "./dashboard";
import ProfilePage from "./profile";
import MessagePage from "./message";
import LoansPage from "./loans";
import SubscriptionsPage from "./subscriptions";
import SubscriptionCreatePage from "./subscriptions/create";
import SubscriptionViewPage from "./subscriptions/view";
import AdminLoanRequestsPage from "./admin/loans/requests";
import AdminLoanRequestViewPage from "./admin/loans/request-view";
import AdminSubscriptionsPage from "./admin/subscriptions";
import AdminSubscriptionCreatePage from "./admin/subscriptions/create";
import AdminSubscriptionViewPage from "./admin/subscriptions/view";
import UsersPage from "./admin/users";
import UserCreatePage from "./admin/users/create";
import UserEditPage from "./admin/users/edit";
import UserViewPage from "./admin/users/view";
import LogoutPage from "./logout";

import { AuthenticatedRoute} from "../components/authenticated-route";
import AdminDashboardPage from "./admin/dashboard";
import LoanRequestPage from "./loans/request";
import LoanRequestViewPage from "./loans/request-view";
import AdminInfosheetsPage from "./admin/infosheets";
import InfosheetsPage from "./infosheets";
import SubscriptionNewPage from "./subscriptions/new";
import ContactPage from "./contact";
import ProfileContractPage from "./profile/contract";

const MainPage = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/pages/start" component={StartPage}/>
        <Route path="/pages/contact" component={ContactPage} />
        <AuthenticatedRoute exact path="/pages/profile" render={(props) => <ProfilePage {...props} isAdmin={false} />}/>
        <AuthenticatedRoute excat path="/pages/profile/contract" component={ProfileContractPage} />
        <AuthenticatedRoute path="/pages/admin/profile" render={(props) => <ProfilePage {...props}  isAdmin />}/>
        <AuthenticatedRoute path="/pages/dashboard" component={DashboardPage}/>
        <AuthenticatedRoute path="/pages/loans" component={LoansPage}/>
        <AuthenticatedRoute path="/pages/loan-request" component={LoanRequestPage}/>
        <AuthenticatedRoute path="/pages/loan-request-view/:loanRequestId" component={LoanRequestViewPage}/>
        <AuthenticatedRoute path="/pages/subscriptions" component={SubscriptionsPage}/>
        <AuthenticatedRoute path="/pages/subscription/:subscriptionId" component={SubscriptionViewPage}/>
        <AuthenticatedRoute exact path="/pages/subscription-request-new" component={SubscriptionNewPage}/>
        <AuthenticatedRoute exact path="/pages/subscription-request-new/:subscriptionType" component={SubscriptionNewPage}/>
        <AuthenticatedRoute exact path="/pages/subscription-request/:subscriptionType" component={SubscriptionCreatePage}/>
        <AuthenticatedRoute path="/pages/infosheets" component={InfosheetsPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/dashboard" component={AdminDashboardPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/loan-requests" component={AdminLoanRequestsPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/loan-request/:loanRequestId" component={AdminLoanRequestViewPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/subscriptions" component={AdminSubscriptionsPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/subscription/:subscriptionId" component={AdminSubscriptionViewPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/subscription-request" component={AdminSubscriptionCreatePage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/users" render={(props) => <UsersPage scope={'users'} {...props}/>}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/user/:userId" component={UserViewPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/user-edit" component={UserEditPage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/user-create" component={UserCreatePage}/>
        <AuthenticatedRoute adminOnly path="/pages/admin/infosheets" component={AdminInfosheetsPage}/>
        <Route path="/pages/message" component={MessagePage}/>
        <Route path="/pages/logout" component={LogoutPage}/>
        <Route exact path="/" render={() => (
          <Redirect to="/pages/start"/>
        )}/>
      </Switch>
    </Fragment>
  );
};

export default MainPage;