import React, {Component, Fragment} from "react";
import {Segment, Label, Form, TextArea, Button} from "semantic-ui-react";
import {CountryDropdown} from "react-country-region-selector";
import SingleGridLayout from "../../layout/single-grid";
import {UserService} from "../../services/user-service";
import {MessageDialog} from "../../components/message-dialog";
import approve from "approvejs/dist/approve";

import {withTranslation} from "react-i18next";

class ProfilePage extends Component {

  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    passwordChanged: false,
    currentUser: {},
    currentUserErrors: {},
    saveLabel: 'button-save',
    dialogOkLabel: 'profile-update-dialog-ok'
  };

  handleChange = (e, {name, value}) => this.handleCountryChange(name, value);

  handleCountryChange = (name, value) => {
    const {currentUser} = this.state;
    currentUser[name] = value;
    this.setState({currentUser: currentUser});
  };

  componentDidMount = () => {
    if (localStorage.getItem('firstLogin') !== null)
      this.setState({saveLabel: 'button-next', dialogOkLabel: 'profile-update-dialog-next'});

    UserService.currentUser(this.onCurrentUserSuccess, console.log);
  };

  onCurrentUserSuccess = currentUser => this.setState({currentUser: currentUser});

  /* Validate */
  doValidateImpl = (value, rule, errors, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      errors[error_key] = false;
      return true;
    } else {
      errors[error_key] = error_message;
      return false;
    }
  };

  doValidate = () => {
    const {currentUser} = this.state;

    const {name, mailing_address, nationality, profession, contact_number} = currentUser;

    let requiredRule = {
      required: true
    };

    let errors = {};

    let isValid1 = this.doValidateImpl(name, requiredRule, errors, 'name_error', 'Please enter your name.');
    let isValid2 = this.doValidateImpl(mailing_address, requiredRule, errors, 'mailing_address_error', 'Please enter your mailing address.');
    let isValid3 = this.doValidateImpl(nationality, requiredRule, errors, 'nationality_error', 'Please enter your nationality.');
    let isValid4 = this.doValidateImpl(profession, requiredRule, errors, 'profession_error', 'Please enter your profession.');
    let isValid5 = this.doValidateImpl(contact_number, requiredRule, errors, 'contact_number_error', 'Please state your contact number.');

    this.setState({ currentUserErrors: errors});
    return isValid1 && isValid2 && isValid3 && isValid4 && isValid5;
  };

  /* Saving and  Message Dialog */
  doSubmit = () => {
    if (!this.doValidate()) return;

    const {currentUser} = this.state;
    if (currentUser.password && currentUser.password.toString().trim() === '') delete currentUser['password'];
    if (currentUser.password) this.setState({passwordChanged: true});
    UserService.update(currentUser, this.onSaveSuccess, this.onSaveError);
  };

  onSaveSuccess = () => {
    const {currentUser, passwordChanged} = this.state;
    delete currentUser['password'];
    const {t} = this.props;

    let dialogMessage = passwordChanged ? 'Your password was also updated. You will be required to login to the Portal again for security reasons.' : '';
    this.setState({
      currentUser: currentUser,
      dialogOpen: true,
      dialogTitle: t('profile-save-success'),
      dialogMessage: dialogMessage,
      isError: false
    });
  }

  onSaveError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Saving User Profile',
    dialogMessage: "There's an error saving user profile. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  onMessageDialogOk = () => {
    const {isError, passwordChanged} = this.state;

    this.setState({dialogOpen: false});

    if (!isError && localStorage.getItem('firstLogin') !== null) {
      localStorage.removeItem('firstLogin');
      let target = this.props.isAdmin ? '/pages/admin/dashboard' : '/pages/dashboard';
      this.props.history.push(target);
    }

    if (!isError && passwordChanged) this.props.history.push('/pages/logout');
  };

  render = () => {
    const {currentUser, currentUserErrors} = this.state;

    const {name, mailing_address, nationality, profession, contact_number, email} = currentUser;
    const {name_error, mailing_address_error, nationality_error, profession_error, contact_number_error} = currentUserErrors;

    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, dialogOkLabel, isError, saveLabel} = this.state;

    const {t} = this.props;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       okLabel={t(dialogOkLabel)}
                       onOkClick={this.onMessageDialogOk}/>

        <SingleGridLayout isAdmin={this.props.isAdmin}>
          <Segment padded>
            <Label color={'black'} attached={'top left'}>{t('profile-title')}</Label>
            <Form id={'individual-loan-form'} size='large'>
              <Form.Input
                label={t('profile-full-name')}
                name={'name'}
                value={name}
                error={name_error}
                onChange={this.handleChange}
              />
              <Form.Field
                label={t('profile-mailing-address')}
                name={'mailing_address'}
                value={mailing_address}
                error={mailing_address_error}
                control={TextArea}
                rows={2}
                onChange={this.handleChange}
              />
              <Form.Field
                label={t('profile-nationality')}
                name={'nationality'}
                error={nationality_error}
                control={() => <CountryDropdown defaultOptionLabel={t('selector-country')} value={nationality}
                                                onChange={country => this.handleCountryChange('nationality', country)}/>}
              />
              <Form.Input
                label={t('profile-profession')}
                name={'profession'}
                value={profession}
                error={profession_error}
                onChange={this.handleChange}
              />
              <Form.Input
                label={t('profile-contact')}
                name={'contact_number'}
                value={contact_number}
                type={'number'}
                error={contact_number_error}
                onChange={this.handleChange}
              />
              <Form.Input
                label={t('profile-email')}
                name={'email'}
                value={email}
                disabled={true}
              />
              <Segment color={"red"}>
                <Label attached={"top"} color={"red"}>{t('profile-change-password')}</Label>
                <Form.Input
                  label={t('profile-change-password-instruction')}
                  name={'password'}
                  type={'password'}
                  onChange={this.handleChange}
                />
              </Segment>
              <Segment basic textAlign={"right"}>
                <Button color={'blue'} onClick={this.doSubmit}>{t(saveLabel)}</Button>
              </Segment>
            </Form>
          </Segment>
        </SingleGridLayout>
      </Fragment>
    )
  };
};

export default withTranslation("translations")(ProfilePage);