import React from "react";
import {Modal, Button, Segment, Label} from "semantic-ui-react";

export const SiteTermsAndConditions = props => {
  return (
    <Modal open={props.open}>
      <Modal.Header>Portal Terms & Conditions</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <p>In these Terms and Conditions, references to “you” and “your” are references to any
            person using or accessing (or attempting to use or access) this portal.</p>
        </Segment>
        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>INFORMATION ON THIS PORTAL</Label>
          <p>
            This portal provides general information about Charismatic Capital and the investment funds it manages.
            The information on this portal (the “Information”) is directed only at persons or entities in any
            jurisdiction
            or country where access to and use of such information is not contrary to local law or regulation.
          </p>
          <p>
            Accordingly, all persons who access this portal are required to inform themselves of and to comply with any
            such restrictions. Some funds are not available, and offering materials relating to them will not be
            distributed, to persons resident in any country where such distribution would be contrary to local law or
            regulation.
          </p>
          <p>
            The Information is provided for information purposes only and does not constitute an invitation, offer or
            solicitation to engage in any investment activity, including to buy or sell any investment, including any
            units or shares in any fund. The information is provided on the basis that you make your own investment
            decisions and do not rely upon it. Charismatic Capital is not soliciting any action based on it and it does
            not constitute, and should not be construed as, a personal recommendation or investment advice to buy, sell
            or otherwise transact in any investment in any jurisdiction.
          </p>
          <p>
            The Information is not intended to be relied upon as a forecast, research or tax, accounting regulatory,
            legal, insurance or investment advice. You should seek professional advice before making any investment
            decisions. Any investment decision that you make should be based on an assessment of the risks in
            consultation with your investment adviser.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>ACCURACY OF INFORMATION</Label>
          <p>
            Although the Information has been obtained from, or is based upon, sources that Charismatic Capital consider
            to be reliable, it is not guaranteed as to its accuracy or completeness. The Information is provided without
            obligation on the part of Charismatic Capital or its directors, officers, employees and agents on the
            understanding that any person who acts upon it does so entirely at his own risk.
          </p>
          <p>
            Information is current only as at the date it is first posted and may no longer be true or complete when
            viewed by you. Charismatic Capital cannot guarantee that content will be accurate, complete and current at
            all times. All content on the portal is subject to modification from time to time without notice.
          </p>
        </Segment>
        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>CONFIDENTIALITY</Label>
          <p>
            You acknowledge that the Information is strictly confidential and agree to treat it as such.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>COPYRIGHT, TRADEMARKS AND OTHER RIGHTS</Label>
          <p>
            Copyright, trademarks, database rights, patents and all similar rights on this portal and the Information
            are owned by Charismatic Capital, its licensors or relevant third party providers. You may use the Information
            and reproduce it in hard copy for your personal reference only. The Information may not otherwise be reproduced,
            distributed, stored or transmitted to any person in any form or by any means or incorporated in any way into
            another database, document or any other materials without Charismatic Capital’s prior written consent.
            Nothing on this portal should be considered as granting any licence or right under any trademark of Charismatic
            Capital or any third party.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>ACCESS</Label>
          <p>
            Charismatic Capital reserves the right to suspend or withdraw access to any page(s) included on this portal
            without notice at any time.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>COMPLIANCE WITH LAW AND REGULATION</Label>
          <p>
            When using this portal, you must comply with all applicable local, national and international laws and
            regulations. It may be illegal to download the information contained on this portal in certain countries and
            Charismatic Capital and its directors, officers, employees and agents disclaim all responsibility if you
            download any information from this portal in breach of any law or regulation of the country in which you
            reside.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>NO LIABILITY</Label>
          <p>
            No representation, warranty or undertaking is given as to the accuracy or completeness of the Information by
            Charismatic Capital and no liability is accepted by such persons for the accuracy or completeness of such
            Information. In no event will Charismatic Capital be liable to any person for any direct, indirect, special
            or consequential damages arising out of any use of the Information, or on any other hyper-linked site,
            including, without limitation, any lost profits, business interruption, loss of programs or data on your equipment, or
            otherwise, even if Charismatic Capital is expressly advised of the possibility or likelihood of such
            damages.
          </p>
          <p>
            You should be aware that the internet is not a completely reliable transmission medium. Charismatic Capital
            does not accept any liability if, for any reason, this portal is unavailable at any time or for any period
            or for any data transmission errors such as data loss or damage or alteration of any kind or for the security
            or confidentiality of information transmitted across the internet to or from Charismatic Capital. Any such
            transmission of information is entirely at your own risk and any material downloaded from this portal is
            downloaded at your own risk.</p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>LINKED WEBSITES</Label>
          <p>
            This portal may be linked to third party portals or contain information provided by third parties.
            Charismatic Capital does not make any representation as to the accuracy or completeness of such portals or information,
            has not and will not review or update such portals or information, and cautions you that any use made of
            such portals or information is at your own risk. Charismatic Capital does not accept any liability arising out of
            the information contained on any linked portal or information on this portal provided by a third party.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>RISK WARNINGS</Label>
          <p>
            No investment strategy is without risk. All investors should consider investing only if they can accept the
            risks associated with investing including a loss of invested capital. No investor should invest without a
            thorough reading of the risk factors associated with each investment strategy in official documentation
            provided for each fund or strategy.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>INDEMNITY</Label>
          <p>
            You agree to indemnify, defend, and hold harmless Charismatic Capital, its affiliates, associates and
            licensors, and the officers, partners, employees and agents of Charismatic Capital and its affiliates,
            associates and licensors, from and against any and all claims, liabilities, damages, losses, or expenses,
            including legal fees and costs, arising out of or in any way connected with your access to or use of this
            portal and the Information or any breach of these Terms and Conditions.</p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>VARIATION</Label>
          <p>
            Charismatic Capital may delete or make changes to these Terms and Conditions and to the Information
            contained on this portal at any time. Where Charismatic Capital makes changes to these Terms and Conditions, you will
            be required to accept such changes in order to continue to use the portal. If you do not accept such revised
            Terms and Conditions, you may no longer be able to access the portal.
          </p>
          <p>
            If any provision of these Terms and Conditions is found by any court or authority of competent jurisdiction
            to be illegal, void or invalid under the laws of any jurisdiction, the legality, validity or enforceability of
            the remainder of these Terms and Conditions in that jurisdiction shall not be affected and the legality,
            validity and enforceability of these Terms and Conditions in any other jurisdiction shall not be affected.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>THIRD PARTIES</Label>
          <p>
            Charismatic Capital and its affiliates shall have the benefit of the rights conferred on them by these Terms
            and Conditions but otherwise no person who is not a party to these Terms and Conditions may enforce its
            terms under the Contracts (Rights of Third Parties) Act 1999.
          </p>
        </Segment>

        <Segment padded>
          <Label color={"black"} inverted attached={"top left"}>GOVERNING LAW</Label>
          <p>
            You agree that this portal and its Terms and Conditions (and any non-contractual obligations arising from or
            in connection with them) are governed by and shall be construed in accordance with the laws of Singapore. You agree to
            submit to the exclusive jurisdiction of the courts of Singapore in connections with any dispute arising in
            relation to this portal.
          </p>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.onOkClick}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
}