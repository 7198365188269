import React, {Component, Fragment} from 'react';
import {Button, Label, Segment, Table, Input, Icon, Pagination} from "semantic-ui-react";
import SingleGridLayout from '../../../layout/single-grid';
import {MessageDialog} from "../../../components/message-dialog";
import {UserService} from "../../../services/user-service";
import DataConfirmationDialog from "../../../components/data-confirmation-dialog";
import {StringUtils} from "../../../components/strings";

export default class UsersPage extends Component {
  USER_TYPE = 'users';

  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    users: [],
    userSearch: '',
    dataConfirmDialogOpen: false,
    sortColumn: 'created',
    sortOrder: 'DESC',
    activePage: 1,
    totalPages: 1
  };

  loadPaginator = () => UserService.listPaginator(this.USER_TYPE, '', (totalPages) => this.setState({totalPages}, this.loadData), this.onError)

  loadData = () => {
    const {activePage} = this.state;
    UserService.list(this.USER_TYPE, '', this.generateSortString(), activePage, this.onListSuccess, this.onError);
  };

  componentDidMount = this.loadPaginator();

  onListSuccess = listItems => {
    this.setState({users: listItems, isLoading: false});
  };

  onError = error => {
    if (!error.message.includes('Table not found')) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving Users',
        dialogDescription: error.message,
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => {
    this.setState({dialogOpen: false});
  };

  viewUser = (userId) => this.props.history.push(`/pages/admin/user/${userId}`);

  /* Search Form */
  onDataChange = (e, {name, value}) => this.setState({[name]: value});

  doSearchUsers = e => {
    if (e.key === 'Enter') this.doSearchUsersImpl();
  };

  doSearchUsersImpl = () => {
    const {userSearch} = this.state;
    UserService.list(this.USER_TYPE, userSearch, this.generateSortString(), this.onListSuccess, this.onError);
  };

  /* Enable / Disable */
  toggleUserStatus = (userId, status) => {
    let newStatus =  status === 'ENABLED' ? 'DISABLED' : 'ENABLED';
    UserService.updateStatus(userId, newStatus, this.onUpdateUserSuccess, this.onUpdateUserError);
  };

  onUpdateUserSuccess = response => {
    if (response) this.doSearchUsersImpl();
  };

  onUpdateUserError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Updating User',
    dialogMessage: "There's an error enabling/disabling User. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  register = () => this.setState({
    dataConfirmDialogOpen: true
  });

  onDataConfirmDialogYesClick = descriptionData => {
    this.setState({dataConfirmDialogOpen: false});
    UserService.register(descriptionData.description, this.onRegistrationSuccess, this.onRegistrationError);
  };

  onDataConfirmDialogNoClick = () => this.setState({dataConfirmDialogOpen: false});

  onRegistrationSuccess = user => {
    const {activePage} = this.state;

    if (activePage===1)
      this.loadPaginator()
    else
      this.setState({activePage: 1}, this.loadPaginator)
  }

  onRegistrationError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Registering User',
    dialogMessage: "There's an error Registering User. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  /* send password */
  showSendNewPassword = user => UserService.sendNewPassword(user, () => this.onSendNewPasswordSuccess(user), console.log);

  onSendNewPasswordSuccess = user => this.setState({
    dialogOpen: true,
    dialogTitle: 'New Password Sent',
    dialogMessage: `A new password has been sent to ${user.name} (${user.email}).`,
    dialogDescription: '',
    isError: false
  });

  sort = columnName => {
    const { sortColumn, sortOrder} = this.state;

    if (sortColumn===columnName) {
      this.setState({
        activePage: 1,
        sortOrder: sortOrder==='DESC' ? 'ASC' : 'DESC'
      }, this.loadData);
    } else {
      this.setState({
        activePage: 1,
        sortColumn: columnName,
        sortOrder: 'ASC'
      }, this.loadData);
    }
  };

  formatSort = sortColumn => {
    switch (sortColumn) {
      case 'created': return 'Date';
      case 'userStatus': return 'Status';
      default: return StringUtils.capitalize(sortColumn);
    }
  };

  formatSortOrder = sortOrder => {
    return sortOrder==='DESC' ? 'descending' : 'ascending';
  };

  generateSortString = () => {
    const { sortColumn, sortOrder} = this.state;

    return `${sortColumn} ${sortOrder}`;
  };

  /* Pagination */
  handlePaginationChange = (e, { activePage }) => this.setState({ activePage }, this.loadData)

  render = () => {
    const {dialogOpen, dataConfirmDialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, users} = this.state;

    const {sortColumn, sortOrder} = this.state;

    const {activePage, totalPages} = this.state;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <DataConfirmationDialog open={dataConfirmDialogOpen} title={'Registering New User'}
                                description={"What is the email address of the User?\nThe password will be autogenerated."} onNoClick={this.onDataConfirmDialogNoClick}
                                onYesClick={this.onDataConfirmDialogYesClick}/>

        <SingleGridLayout isAdmin>
          <Segment basic textAlign={'right'}>
            <Button color={'blue'} onClick={this.register}>
              <Icon name={'plus'}/>
              New User
            </Button>
          </Segment>
          <Segment clearing padded>
            <Label color={'black'} attached={'top left'}>Users</Label>
            { false && <Fragment>
              <Button floated={'right'} color={'green'} icon={'search'} onClick={this.doSearchUsersImpl}/>

              <Input placeholder={'Search'}
                     name={'userSearch'}
                     onChange={this.onDataChange}
                     onKeyUp={this.doSearchUsers}
                     fluid>
                <input autoComplete={'off'}/>
              </Input>
            </Fragment>}
            <Segment basic textAlign={"right"}>
              { sortColumn==='' && <Fragment>No Sorting</Fragment>}
              { sortColumn!=='' && <Fragment>Sorted by {this.formatSort(sortColumn)} {this.formatSortOrder(sortOrder)}</Fragment>}
            </Segment>
            {users.length > 0 && <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell onClick={() => this.sort('email')}>Email</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('name')}>Name</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('created')}>Date</Table.HeaderCell>
                  <Table.HeaderCell textAlign={"center"}>Details</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('userStatus')} textAlign={"center"}>Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign={"center"}>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  users.map(user => (
                      <Table.Row key={user.objectId}>
                        <Table.Cell>{user.email}</Table.Cell>
                        <Table.Cell>{user.name}</Table.Cell>
                        <Table.Cell>{new Date(user.created).toLocaleString()}</Table.Cell>
                        <Table.Cell textAlign={"center"}>
                          <Button color={'blue'} onClick={() => this.viewUser(user.objectId)}>Details</Button>
                        </Table.Cell>
                        <Table.Cell textAlign={"center"}>{user.userStatus}</Table.Cell>
                        <Table.Cell textAlign={"center"}>
                          <Button color={"blue"} inverted
                                  onClick={() => this.showSendNewPassword(user)}>Reset Password</Button>
                          <Button color={user.userStatus === 'ENABLED' ? 'red' : 'green'} inverted
                                  onClick={() => this.toggleUserStatus(user.objectId, user.userStatus)}>{user.userStatus === 'ENABLED' ? 'DISABLE' : 'ENABLE'}</Button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )
                }
              </Table.Body>
            </Table>

            }

            <Segment basic textAlign={"right"}>
              <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  size='mini'
                  siblingRange={1}
                  totalPages={totalPages}
                  ellipsisItem={undefined}
                  firstItem={undefined}
                  lastItem={undefined}
                  prevItem={undefined}
                  nextItem={undefined}
              />
            </Segment>
          </Segment>
        </SingleGridLayout>
      </Fragment>

    )
  }
}