import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import "semantic-ui-less/semantic.less";
import './index.css';
import * as serviceWorker from './serviceWorker';
import MainPage from "./pages/main";
import Backendless from "backendless";
import { BACKENDLESS_APP_ID, BACKENDLESS_APP_KEY} from "./config";
import { I18nextProvider } from "react-i18next";
import i18n from './i18n';

Backendless.initApp(BACKENDLESS_APP_ID, BACKENDLESS_APP_KEY);

const rootElement = document.getElementById('root');

const renderApp = Component => {
  ReactDOM.render(
    <HashRouter>
      <I18nextProvider i18n={i18n}>
        <Component/>
      </I18nextProvider>
    </HashRouter>,
    rootElement
  );
};

renderApp(MainPage);
if (module.hot) {
  module.hot.accept('./pages/main', () => {
    const NextApp = require('./pages/main').default;
    renderApp(NextApp);
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
