import React from "react";
import {Modal, Button, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import FileViewer from "react-file-viewer";


const onDownloadClick = (fileUrl) => window.open(fileUrl, '_blank');

export const FileViewerDialog = ({open, fileUrl, onOkClick}) => {
  const fileType = (typeof fileUrl==="undefined") ? '' : fileUrl.split(/[.]+/).pop();

  return (
    <Modal open={open}>
      <Modal.Content>
        <FileViewer filePath={fileUrl} fileType={fileType} />
      </Modal.Content>
      <Modal.Actions>
        <Button name={'downloadBtn'} color='blue' inverted onClick={() => onDownloadClick(fileUrl)}>
          <Icon name='download' /> Download
        </Button>
        <Button name={'okBtn'} color='green' inverted onClick={onOkClick}>
          <Icon name='checkmark' /> OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

FileViewerDialog.propTypes = {
  open: PropTypes.bool,
  fileUrl: PropTypes.string,
  onOkClick: PropTypes.func
};