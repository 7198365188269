import React, { Component, Fragment } from 'react';
import SingleGridLayout from "../../layout/single-grid";
import {Grid, Step, Segment, Label, Form, Button} from "semantic-ui-react";
import { LoanRequestService} from "../../services/loan-request-service";
import { MessageDialog} from "../../components/message-dialog";
import {LoanTypeSelector, UserTypeSelector, YesNoSelector} from "./components/form/account-selectors";
import approve from "approvejs/dist/approve";

import {withTranslation} from "react-i18next";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";

class LoanRequestPage extends Component {
  loanRequest = {
    status: 'new'
  };

  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '',
    isError: false,
    isEditing: false,
    isAdmin: false
  };

  componentDidMount = () => {
    if (this.props.location.state) {
      const {loanRequest, isAdmin} = this.props.location.state;
      if (loanRequest) {
        this.loanRequest = loanRequest;
        this.setState({ isEditing: true, isAdmin: isAdmin});
      }
    }
  };

  handleChange = (e, {name, value}) => {
    this.loanRequest[name] = value;
    this.setState({loanRequest: this.loanRequest});
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false});
      return true;
    } else {
      this.setState({ [error_key]: error_message});
      return false;
    }
  };

  doValidate = () => {
    const { name, stock_code, loan_type, borrower_referrer, pledge_number, loan_amount, loan_purpose, loan_is_refinancing} = this.loanRequest;
    let requiredRule = {
      required: true
    };

    let isValid1 = this.doValidateImpl(name, requiredRule, 'name_error', 'Please enter your name.');
    let isValid2 = this.doValidateImpl(stock_code, requiredRule, 'stock_code_error', 'Please enter the Company Name and its Stock Code.');
    let isValid3 = this.doValidateImpl(loan_type, requiredRule, 'loan_type_error', 'Please enter the Borrow Type.');
    let isValid4 = this.doValidateImpl(borrower_referrer, requiredRule, 'borrower_referrer_error', 'Are you the borrower or the referrer?');
    let isValid5 = this.doValidateImpl(pledge_number, requiredRule, 'pledge_number_error', 'Please enter the number of shares to pledge.');
    let isValid6 = this.doValidateImpl(loan_amount, requiredRule, 'loan_amount_error', 'Please enter the amount in USD to loan.');
    let isValid7 = this.doValidateImpl(loan_purpose, requiredRule, 'loan_purpose_error', 'Please enter the purpose of this loan.');
    let isValid8 = this.doValidateImpl(loan_is_refinancing, requiredRule, 'loan_is_refinancing_error', 'Please enter if the loan is a refinance.');

    return isValid1 && isValid2 && isValid3 && isValid4 && isValid5 && isValid6 && isValid7 && isValid8;
  };

  doSubmit = () => {
    if (this.doValidate()) LoanRequestService.save(this.loanRequest, this.onSubmitSuccess, this.onSubmitFailure);
  };

  onSubmitSuccess = savedRequest => {
    const { isEditing, isAdmin } = this.state;
    const { t} = this.props;

    if (isEditing)
      if (isAdmin)
        this.props.history.push(`/pages/admin/loan-request/${savedRequest.objectId}`);
      else
        this.props.history.push(`/pages/loan-request-view/${savedRequest.objectId}`);
    else {
      this.setState({
        dialogOpen: true,
        dialogTitle: t('loan-form-submitted'), dialogMessage: t('loan-form-submitted-message'),
        isError: false
      });
      LoanRequestService.notifyAdminOnCreate(savedRequest.objectId, console.log, console.log);
    }
  }

  onSubmitFailure = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Oops! Something went wrong!', dialogMessage: error.message,
    isError: true
  });

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (!isError) this.props.history.push("/pages/loans");
  };

  render = () => {
    const { name, stock_code, loan_type, borrower_referrer, pledge_number, loan_amount, loan_purpose, loan_is_refinancing, comments} = this.loanRequest;
    const { name_error, stock_code_error, loan_type_error, borrower_referrer_error, pledge_number_error, loan_amount_error, loan_purpose_error, loan_is_refinancing_error} = this.state;
    const { dialogOpen, dialogTitle, dialogMessage, isError, isEditing} = this.state;

    const { t} = this.props;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <SingleGridLayout>
          <Grid>
            <Grid.Column width={5}>
              <Step.Group ordered vertical fluid>
                <Step>
                  <Step.Content>
                    <Step.Title>{t('loan-form-title')}</Step.Title>
                  </Step.Content>
                </Step>
              </Step.Group>
            </Grid.Column>
            <Grid.Column width={11}>
              <Segment padded>
                <Label color={'black'} attached={'top left'}>{t('loan-form-title')}</Label>
                <Form id={'loan-request-form'} size='large'>
                  <Form.Input
                    label={t('loan-form-loan_type')}
                    error={loan_type_error}
                    control={() => (<LoanTypeSelector t={t} name={'loan_type'} value={loan_type}
                                                              onChange={this.handleChange} />)}
                  />
                  <Form.Input
                    label={t('loan-form-borrower_referrer')}
                    error={borrower_referrer_error}
                    control={() => (<UserTypeSelector t={t} name={'borrower_referrer'} value={borrower_referrer}
                                                      onChange={this.handleChange} />)}
                  />
                  <Form.Input
                    label={t('loan-form-name')}
                    name={'name'}
                    value={name}
                    error={name_error}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label={t('loan-form-stock_code')}
                    name={'stock_code'}
                    value={stock_code}
                    error={stock_code_error}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label={t('loan-form-pledge_number')}
                    name={'pledge_number'}
                    value={pledge_number}
                    error={pledge_number_error}
                    onChange={this.handleChange}
                    type={'number'}
                  />
                  <Form.Input
                    label={t('loan-form-loan_amount')}
                    name={'loan_amount'}
                    value={loan_amount}
                    error={loan_amount_error}
                    onChange={this.handleChange}
                    type={'number'}
                  />
                  <Form.Input
                    label={t('loan-form-loan_purpose')}
                    name={'loan_purpose'}
                    value={loan_purpose}
                    error={loan_purpose_error}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label={t('loan-form-loan_is_refinancing')}
                    error={loan_is_refinancing_error}
                    control={() => (<YesNoSelector t={t} name={'loan_is_refinancing'}
                                                   value={loan_is_refinancing}
                                                   placeholder='-'
                                                   onChange={this.handleChange} />)}
                  />
                  <Form.Input
                    label={t('loan-form-comments')}
                    name={'comments'}
                    value={comments}
                    onChange={this.handleChange}
                    control={TextArea}
                    rows={3}
                  />
                </Form>
              </Segment>

              <Segment padded textAlign={'right'}>
                <Button color={'blue'} onClick={this.doSubmit}>{ isEditing ? t('button-update') : t('button-submit') }</Button>
              </Segment>
            </Grid.Column>
          </Grid>
        </SingleGridLayout>
      </Fragment>
    )
  }
}

export default withTranslation("translations")(LoanRequestPage)