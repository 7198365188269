import React, {Component, Fragment} from 'react';
import {Button, Form, Icon, Label, Message, Segment, Table} from "semantic-ui-react";
import {MessageDialog} from "../../../../components/message-dialog";
import {FileViewerDialog} from "../../../../components/file-viewer-dialog";
import DataConfirmationDialog from "../../../../components/data-confirmation-dialog";
import {LoanRequestService} from "../../../../services/loan-request-service";
import {KycDocumentEditDialog} from "./kyc-document-edit-dialog";
import {UserService} from "../../../../services/user-service";
import {StringUtils} from "../../../../components/strings";

export default class AdminLoanKycDocumentsComponent extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    kycDocuments: [],
    kycDocumentDescription: '',
    kycDocumentFile: [],
    currentDocumentId: 0,
    editKycDialogOpen: false,
    editKycDocumentId: '',
    editKycDescription: '',
    dataConfirmDialogOpen: false,
    dataConfirmDialogTitle: '',
    dataConfirmDialogDescription: '',
    dataConfirmDialogOnYesClick: () => {}
  };

  getKycDocuments = () => LoanRequestService.getDocuments(this.props.loanRequestId, 'kyc', this.onListSuccess, this.onError);

  componentDidMount = () => this.getKycDocuments();

  onListSuccess = listItems => this.setState({kycDocuments: listItems, isLoading: false});

  onError = error => {
    if (!(error.message.includes('Table not found') || error.message.includes("Column 'attachments' does not exist"))) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving KYC Documents',
        dialogDescription: error.message,
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => this.setState({dialogOpen: false});

  viewKycDocument = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  /* Accept Reject KYC */
  processKyc = (documentId, action) => {
    if (action === 'accepted') {
      let data = {
        objectId: documentId,
        status: action,
        approval_rejection_date: new Date()
      };

      LoanRequestService.updateDocument(data, this.onKycUpdateSuccess, this.onKycUpdateError);
    }

    if (action === 'rejected') this.setState({
      currentDocumentId: documentId,
      dataConfirmDialogOpen: true,
      dataConfirmDialogTitle: 'Rejecting KYC Document',
      dataConfirmDialogDescription: 'What is the Reason for Rejection?',
      dataConfirmDialogOnYesClick: descriptionData => this.onYesClick(descriptionData)
      });

  };

  onKycUpdateSuccess = updatedDocument => {
    if (updatedDocument.status === 'accepted')
      this.props.onKycDocumentApproved(updatedDocument);
    this.getKycDocuments();
  };

  onKycUpdateError = error => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Updating KYC Document Status',
      dialogDescription: error.message,
      isError: true
    });
  };

  onYesClick = descriptionData => {
    const {currentDocumentId} = this.state;

    let data = {
      objectId: currentDocumentId,
      status: 'rejected',
      approval_rejection_reason: descriptionData.description,
      approval_rejection_date: new Date()
    };

    LoanRequestService.updateDocument(data, this.onKycUpdateSuccess, this.onKycUpdateError);

    LoanRequestService.save({
      objectId: this.props.loanRequestId,
      status: 'kyc_incomplete'
    }, this.props.onKycIncomplete, console.log);

    this.setState({dataConfirmDialogOpen: false});
  };

  onDataConfirmDialogNoClick = () => this.setState({dataConfirmDialogOpen: false});

  /* Editing Metadata */
  editKycMetadata = (documentId, description) => this.setState({
    editKycDialogOpen: true,
    editKycDocumentId: documentId,
    editKycDescription: description
  });

  onKycEditDialogYesClick = ({documentId, description}) => {
    let data = {
      objectId: documentId,
      document_description: description
    };

    LoanRequestService.updateDocument(data, (_) => {
      this.getKycDocuments();
      this.setState({
        editKycDialogOpen: false
      })
    }, this.onError);
  };

  onKycEditDialogNoClick = () => this.setState({editKycDialogOpen: false});

  /*
    Messaging the User
   */
  messageUser = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Message User',
    dataConfirmDialogDescription: 'Type in your message here.',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick(descriptionData.description)
  });

  onConfirmDialogYesClick = (message) => {
    UserService.getUser(this.props.ownerId,
      (user) => {
        let subject = 'New KYC Documents Updated';
        LoanRequestService.notifyUser(user, this.props.loanRequestId, subject, message, console.log, console.log);
      }, console.log);

    this.setState({confirmDialogOpen: false, dataConfirmDialogOpen: false});
  }

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, kycDocuments} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl} = this.state;

    const {editKycDialogOpen, editKycDocumentId, editKycDescription} = this.state;
    const {dataConfirmDialogOpen, dataConfirmDialogTitle, dataConfirmDialogDescription, dataConfirmDialogOnYesClick} = this.state;

    const { setKycExpiryDates, kyc_expiry_date, kyc_extended_expiry_date} = this.props;

    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <DataConfirmationDialog open={dataConfirmDialogOpen} title={dataConfirmDialogTitle}
                                description={dataConfirmDialogDescription} onNoClick={this.onDataConfirmDialogNoClick}
                                onYesClick={dataConfirmDialogOnYesClick}/>

        <KycDocumentEditDialog open={editKycDialogOpen}
                               identifier={editKycDocumentId}
                               description={editKycDescription}
                               onYesClick={this.onKycEditDialogYesClick}
                               onNoClick={this.onKycEditDialogNoClick}/>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>KYC Expiry Dates</Label>
          <Form id={'kcy-form'} size='large'>
            <Form.Input
              label={'Expiry Date (MM/DD/YYYY)'}
              name={'kyc_expiry_date'}
              value={StringUtils.toISOString(kyc_expiry_date)}
              type={'date'}
              onChange={this.props.handleChange}
            />
            <Form.Input
              label={'Extended Expiry Date (MM/DD/YYYY)'}
              name={'kyc_extended_expiry_date'}
              value={StringUtils.toISOString(kyc_extended_expiry_date)}
              type={'date'}
              onChange={this.props.handleChange}
            />
          </Form>
          <Segment basic textAlign={'right'}>
            <Button color={'blue'} onClick={setKycExpiryDates}>Set KYC Expiry Dates</Button>
          </Segment>
        </Segment>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>KYC Documents</Label>
          {kycDocuments.length === 0 && <Message negative>
            <Message.Header>
              No Data Found
            </Message.Header>
            <p>
              There is no KYC Documents uploaded
            </p>
          </Message>}
          {kycDocuments.length > 0 && <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Upload Date</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>View</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell/>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                kycDocuments.map(kycDocument => (
                    <Table.Row key={kycDocument.objectId} positive={kycDocument.status === 'accepted'}
                               negative={kycDocument.status === 'rejected'}>
                      <Table.Cell>{kycDocument.document_description}</Table.Cell>
                      <Table.Cell>{new Date(kycDocument.created).toLocaleString()}</Table.Cell>
                      <Table.Cell textAlign={"center"}>
                        <Button color={'blue'} onClick={() => this.viewKycDocument(kycDocument.fileURL)}>View</Button>
                      </Table.Cell>
                      <Table.Cell>
                        {LoanRequestService.translateKYCStatus(kycDocument.status)}
                        <br/>
                        {['rejected', 'accepted'].includes(kycDocument.status) && new Date(kycDocument.approval_rejection_date).toLocaleString()}
                        <br/>
                        {['rejected', 'accepted'].includes(kycDocument.status) && kycDocument.approval_rejection_reason}
                      </Table.Cell>
                      <Table.Cell>
                        {!['rejected', 'accepted'].includes(kycDocument.status) && <Fragment>
                          <Button onClick={() => this.processKyc(kycDocument.objectId, 'rejected')}
                                  color={'red'}>Reject</Button>
                          <Button onClick={() => this.processKyc(kycDocument.objectId, 'accepted')}
                                  color={'green'}>Accept</Button>
                        </Fragment>}
                        <Fragment>
                          <Button color={'blue'} inverted
                                  onClick={() => this.editKycMetadata(kycDocument.objectId, kycDocument.document_description)}>Edit</Button>
                        </Fragment>
                      </Table.Cell>
                    </Table.Row>
                  )
                )
              }
            </Table.Body>
          </Table>}

          <Segment textAlign={'right'}>
            <Button basic color={'blue'} onClick={this.messageUser}>
              <Icon name={'mail'}/>
              Message User
            </Button>
          </Segment>
        </Segment>
      </Fragment>

    )
  }
}