import React from 'react'
import { Select } from 'semantic-ui-react'

export const ProcessorSelector = ({ t, name, value, onChange}) => {
    const processors = [
        { key: 'charismatic', value: 'charismatic', text: "Charismatic" },
        { key: 'charismatic_debt', value: 'charismatic_debt', text: "Charismatic Debt" }
    ];

    return (
        <Select placeholder='Processor Selector'
                options={processors}
                name={name}
                value={value} onChange={onChange} />
    );
}
