import React, {Fragment} from "react";
import {Label, Segment, Table} from "semantic-ui-react";

const CreateRow = (props) => {
  return (<Table.Row>
      <Table.Cell>{props.title}</Table.Cell>
      <Table.Cell>{props.value}</Table.Cell>
    </Table.Row>
  );
};

export const SignatoryView = (props) => {
  let subscription = props.subscription;

  return (
    <Fragment>
      <Segment padded>
        <Label color={'black'} attached={'top left'}>Information on Authorised Signatory</Label>

        <Table definition>
          <Table.Body>
            <CreateRow title={'Name of Director/Power of Attorney'} value={subscription.signatory_name}/>
            <CreateRow title={'Contact'} value={subscription.signatory_contact_number}/>
            <CreateRow title={'Email'} value={subscription.signatory_email}/>
          </Table.Body>
        </Table>
      </Segment>
    </Fragment>
  );
};