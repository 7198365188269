import React, {Component, Fragment} from 'react';
import SingleGridLayout from '../../../layout/single-grid';
import {SubscriptionService} from "../../../services/subscription-service";
import {SubscriptionView} from "../../subscriptions/components/subscription-view";
import {SubscriptionCorporateView} from "../../subscriptions/components/subscription-corporate-view";
import {SignatoryView} from "../../subscriptions/components/signatory-view";
import {Button, Label, Menu, Segment} from "semantic-ui-react";
import ProfileViewer from "../../profile/components/view";
import {MessageDialog} from "../../../components/message-dialog";
import AdminSubscriptionsComponent from "./components/subscription-documents";
import DataLoader from "../../../components/data-loader";
import DataConfirmationDialog from "../../../components/data-confirmation-dialog";
import {ConfirmationDialog} from "../../../components/confirmation-dialog";
import {UserService} from "../../../services/user-service";
import AdminSubscriptionInternalDocumentsComponent from "./components/internal-documents";

import {withTranslation} from "react-i18next";

class AdminSubscriptionViewPage extends Component {
  state = {
    subscription: {},
    isLoading: true,
    confirmDialogOpen: false,
    confirmDialogMessage: '',
    confirmDialogYesClick: () => {},
    activeItem: 'subscription-documents',
    dataConfirmDialogOpen: false,
    dataConfirmDialogTitle: '',
    dataConfirmDialogDescription: '',
    dataConfirmDialogInitialValue: '',
    dataConfirmDialogOnYesClick: () => {}
  };

  componentDidMount = () => {
    const { subscriptionId} = this.props.match.params;
    SubscriptionService.get(subscriptionId, (subscription) => this.setState({subscription: subscription, isLoading: false}), this.onError)
  };

  onError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Retrieving Subscription',
    dialogMessage: "There's an error retrieving the requested record. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/admin/subscriptions");
  };


  /* Approve / Reject */
  showApproveConfirmationDialog = () => this.setState({
    confirmDialogOpen: true,
    confirmDialogMessage: 'Do you want to approve this Subscription Request for further processing?',
    confirmDialogYesClick: () => this.onConfirmDialogYesClick('approve', '')
  });

  showRejectConfirmationDialog = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Reject Subscription Request',
    dataConfirmDialogDescription: 'What is the Reason for Rejection?',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick('reject', descriptionData.description)
  });

  /* Approve / Reject Application */
  showApproveApplicationConfirmationDialog = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Execute Subscription',
    dataConfirmDialogDescription: 'Enter your notes for this Subscription execution.',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick('execute_subscription', descriptionData.description)
  });

  showRejectApplicationConfirmationDialog = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Reject Subscription Application',
    dataConfirmDialogDescription: 'What is the Reason for rejecting this request as a Subscription?',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick('reject_subscription', descriptionData.description)
  });

  onConfirmDialogYesClick = (action, reason) => {
    const {subscription} = this.state;

    let data = {};

    switch (action) {
      case 'approve': data = {
        objectId: subscription.objectId,
        status: 'approved',
        approval_rejection_date: new Date(),
        approval_rejection_reason: reason
      }; break;
      case 'reject': data = {
        objectId: subscription.objectId,
        status: 'rejected',
        approval_rejection_date: new Date(),
        approval_rejection_reason: reason
      }; break;
      case 'execute_subscription': data = {
        objectId: subscription.objectId,
        status: 'subscription_executed',
        subscription_approval_rejection_date: new Date(),
        subscription_approval_rejection_reason: reason
      }; break;
      case 'reject_subscription': data = {
        objectId: subscription.objectId,
        status: 'subscription_rejected',
        subscription_approval_rejection_date: new Date(),
        subscription_approval_rejection_reason: reason
      }; break;
      default: break;
    }

    SubscriptionService.save(data, this.subscriptionUpdated, this.onError);
    this.setState({confirmDialogOpen: false, dataConfirmDialogOpen: false});
  };

  subscriptionUpdated = subscription => {
    this.setState({subscription: subscription});

    UserService.getUser(subscription.ownerId,
      (user) => {
        let subject = '';
        let message = '';

        if (subscription.status==='rejected') {
          subject = 'Subscription Request Rejected';
          message = "Your subscription requested has been rejected. This is because:- " + subscription.approval_rejection_reason
        }

        if (subscription.status==='approved') {
          subject = 'Subscription Request Approved';
          message = "Your subscription request has been approved for processing."
        }

        if (subscription.status==='subscription_rejected') {
          subject = 'Subscription Application Rejected';
          message = "Your application for a subscription has been rejected. This is because:- " + subscription.subscription_approval_rejection_reason
        }

        if (subscription.status==='subscription_executed') {
        }

        if (subject!=='') SubscriptionService.notifyUser(user, subscription.objectId, subject, message, console.log, console.log);
      }, console.log);
  };

  onConfirmDialogNoClick = () => this.setState({confirmDialogOpen: false});

  onDataConfirmDialogNoClick = () => this.setState({dataConfirmDialogOpen: false});

  onFileUpload = () => {
    const {subscription} = this.state;

    if (['new', 'approved'].includes(subscription.status)) {
      subscription.status = 'processing';
      SubscriptionService.save(subscription, (sub) => this.setState({subscription: sub}), this.onError);
    }
  };

  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  edit = () => {
    const {subscription} = this.state;
    this.props.history.push({
      pathname: '/pages/subscription-request/don-care',
      state: {subscription: subscription, isAdmin: true}
    });
  };

  /* Edit Comments */
  editStatusComments = () => {
    const {subscription} = this.state;

    let field = '';
    if (subscription.status === 'rejected')
      field = 'approval_rejection_reason';
    else if (['subscription_rejected', 'subscription_executed'].includes(subscription.status))
      field = 'subscription_approval_rejection_reason';

    this.setState({
      dataConfirmDialogOpen: true,
      dataConfirmDialogTitle: 'Edit Reason',
      dataConfirmDialogDescription: 'Please edit your comments on Accepting/Rejecting this Request.',
      dataConfirmDialogInitialValue: subscription[field],
      dataConfirmDialogOnYesClick: descriptionData => this.onEditReasonConfirmDialogYesClick(field, descriptionData.description)
    });
  };

  onEditReasonConfirmDialogYesClick = (field, description) => {
    const {subscription} = this.state;

    let data = {
      objectId: subscription.objectId,
      [field]: description
    };

    SubscriptionService.save(data, (sub) => this.setState({subscription: sub}), this.onError);
    this.setState({confirmDialogOpen: false, dataConfirmDialogOpen: false});
  }

  render = () => {
    const { dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError} = this.state;
    const {subscription, activeItem, isLoading} = this.state;
    const {confirmDialogMessage, confirmDialogOpen, confirmDialogYesClick} = this.state;
    const {dataConfirmDialogOpen, dataConfirmDialogTitle, dataConfirmDialogDescription, dataConfirmDialogOnYesClick, dataConfirmDialogInitialValue} = this.state;

    if (typeof subscription === "undefined") return null;

    const isIndividual = subscription.account_type === 'individual';

    const {t} = this.props;
    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <ConfirmationDialog open={confirmDialogOpen}
                            message={confirmDialogMessage}
                            onYesClick={confirmDialogYesClick}
                            onNoClick={this.onConfirmDialogNoClick}/>

        <DataConfirmationDialog open={dataConfirmDialogOpen} title={dataConfirmDialogTitle}
                                description={dataConfirmDialogDescription}
                                initialValue={dataConfirmDialogInitialValue}
                                onNoClick={this.onDataConfirmDialogNoClick}
                                onYesClick={dataConfirmDialogOnYesClick}/>

         <SingleGridLayout isAdmin>
          <DataLoader isLoading={isLoading}
                      itemsLength={1}
                      notFoundMessage={'There are no such Subscription.'}
          />

          { !isLoading && isIndividual && <SubscriptionView t={t} subscription={subscription} edit={this.edit} editStatusComments={this.editStatusComments}/> }
          { !isLoading && !isIndividual && <SubscriptionCorporateView t={t} subscription={subscription} edit={this.edit} editStatusComments={this.editStatusComments} /> }
          { !isLoading && !['subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Segment textAlign={"right"}>
            <Button onClick={this.showRejectConfirmationDialog} color={'red'} disabled={!['new'].includes(subscription.status)}>Reject Subscription Request</Button>
            <Button onClick={this.showApproveConfirmationDialog} color={'green'} disabled={!['new'].includes(subscription.status)}>Approve Subscription Request</Button>
          </Segment>}
          { !isLoading && !['subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Segment textAlign={"right"}>
            <Button onClick={this.showRejectApplicationConfirmationDialog} color={'red'} disabled={['rejected'].includes(subscription.status)}>Reject Subscription</Button>
            <Button onClick={this.showApproveApplicationConfirmationDialog} color={'green'} disabled={['rejected'].includes(subscription.status)}>Execute Subscription</Button>
          </Segment>}
          { !isLoading && !isIndividual && <SignatoryView subscription={subscription} />}

          {!isLoading && <Menu pointing secondary>
            <Menu.Item
              name='subscription-documents'
              active={activeItem === 'subscription-documents'}
              onClick={this.handleItemClick}
            >Subscription Documents</Menu.Item>

            <Menu.Item
              name='internal-documents'
              active={activeItem === 'internal-documents'}
              onClick={this.handleItemClick}
            >Internal Documents</Menu.Item>
          </Menu>}

          { !isLoading &&  activeItem === 'details' && <Segment padded>

            <Label color={'black'} attached={'top left'}>Referred/Requested by</Label>
            <ProfileViewer userId={subscription.ownerId}/>
          </Segment>}

          { !isLoading && activeItem==='subscription-documents' && <AdminSubscriptionsComponent subscriptionId={subscription.objectId} onFileUpload={this.onFileUpload} />}
          { !isLoading && activeItem==='internal-documents' && <AdminSubscriptionInternalDocumentsComponent subscriptionId={subscription.objectId} onFileUpload={this.onFileUpload} />}

        </SingleGridLayout>
      </Fragment>

    )
  }
}

export default withTranslation("translations")(AdminSubscriptionViewPage)