import React, {Component, Fragment} from 'react';
import {Button, Form, Label, Message, Segment, Table} from "semantic-ui-react";
import {MessageDialog} from "../../../../components/message-dialog";
import {LoanRequestService} from "../../../../services/loan-request-service";
import {FileViewerDialog} from "../../../../components/file-viewer-dialog";

export default class AdminLoanInternalDocumentsComponent extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    internalDocuments: [],
    internalDocumentDescription: '',
    internalDocumentFile: []
  };

  componentDidMount = () => LoanRequestService.getDocuments(this.props.loanRequestId, 'internal', this.onListSuccess, this.onError);

  onListSuccess = listItems => this.setState({internalDocuments: listItems, isLoading: false});

  onError = error => {
    if (!(error.message.includes('Table not found') || error.message.includes("Column 'attachments' does not exist"))) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving Internal Documents',
        dialogDescription: error.message,
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => this.setState({dialogOpen: false});

  viewInternalDocument = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  /* InternalDocument Form */
  onDataChange = (e, {name, value}) => this.setState({[name]: value});
  onFileChange = (e, {name}) => this.setState({[name]: e.target.files});

  uploadInternalDocument = () => {
    const {internalDocumentDescription, internalDocumentFile} = this.state;
    LoanRequestService.uploadDocument(this.props.loanRequestId, internalDocumentFile, 'internal', internalDocumentDescription, null, null, this.onSaveInternalDocumentSuccess, this.onUploadInternalDocumentError);

    document.getElementById("internalDocument-form").reset();
  };

  onSaveInternalDocumentSuccess = internalDocument => LoanRequestService.getDocuments(this.props.loanRequestId, 'internal', this.onListSuccess, this.onError);
  
  onUploadInternalDocumentError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading Internal Document',
    dialogMessage: "There's an error uploading or saving Internal Document. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, internalDocuments} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl} = this.state;
    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>Upload</Label>
          <Form id={'internalDocument-form'}>
            <Form.Input
              label={'Description of Internal Document'}
              name={'internalDocumentDescription'}
              onChange={this.onDataChange}
            />
            <Form.Input
              label={'Internal Document'}
              name={'internalDocumentFile'}
              type={'file'}
              accept={'.pdf'}
              onChange={this.onFileChange}
            />
            <Button onClick={this.uploadInternalDocument} color={'blue'}>Upload Internal Document</Button>
          </Form>
        </Segment>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>Internal Documents</Label>
          {internalDocuments.length === 0 && <Message negative>
            <Message.Header>
              No Data Found
            </Message.Header>
            <p>
              There is no Internal Documents uploaded
            </p>
          </Message>}
          {internalDocuments.length > 0 && <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Upload Date</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>View</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                internalDocuments.map(internalDocument => (
                    <Table.Row key={internalDocument.objectId}>
                      <Table.Cell>{internalDocument.document_description}</Table.Cell>
                      <Table.Cell>{new Date(internalDocument.created).toLocaleString()}</Table.Cell>
                      <Table.Cell textAlign={"center"}>
                        <Button color={'blue'} onClick={() => this.viewInternalDocument(internalDocument.fileURL)}>View</Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )
              }
            </Table.Body>
          </Table>}
        </Segment>
      </Fragment>

    )
  }
}