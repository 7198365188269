import React, { Fragment} from "react";
import {Form, Label, TextArea} from "semantic-ui-react";
import {CorporateAccountSelector} from "./corporate-account-selector";
import {CountryDropdown} from "react-country-region-selector";

export const SubscriptionCorporateInformationForm = props => {
  const {
    name_of_company,
    nature_of_business,
    registered_address,
    mailing_address,
    contact_number,
    email,
    relation_to_usa,
    corporate_account_type,
    place_of_incorporation,
    comments
  } = props.data;

  const {
    name_of_company_error,
    nature_of_business_error,
    registered_address_error,
    mailing_address_error,
    contact_number_error,
    email_error,
    relation_to_usa_error,
    corporate_account_type_error,
    place_of_incorporation_error
  } = props.errors;

  const { t} = props;

  return (
    <Fragment>
      <Label color={'black'} attached={'top left'}>{t('subscription-form-corp-title')}</Label>
      <Form id={'corporate-subscription-form'} size='large'>
        <Form.Input
          label={t('subscription-form-corp-account-type')}
          error={corporate_account_type_error}
          control={() => (<CorporateAccountSelector t={t} name={'corporate_account_type'} value={corporate_account_type}
                                                    onChange={props.handleChange} />)}
        />
        <Form.Input
          label={t('subscription-form-corp-company-name')}
          name={'name_of_company'}
          value={name_of_company}
          error={name_of_company_error}
          onChange={props.handleChange}
        />
        <Form.Input
          label={t('subscription-form-corp-nature')}
          name={'nature_of_business'}
          value={nature_of_business}
          error={nature_of_business_error}
          onChange={props.handleChange}
        />
        <Form.Field
          label={t('subscription-form-corp-registered-address')}
          name={'registered_address'}
          value={registered_address}
          error={registered_address_error}
          control={TextArea}
          rows={2}
          onChange={props.handleChange}
        />
        <Form.Field
          label={t('subscription-form-corp-mailing-address')}
          name={'mailing_address'}
          value={mailing_address}
          error={mailing_address_error}
          control={TextArea}
          rows={2}
          onChange={props.handleChange}
        />
        <Form.Input
          label={t('subscription-form-corp-country')}
          name={'place_of_incorporation'}
          value={place_of_incorporation}
          error={place_of_incorporation_error}
          control={() => <CountryDropdown defaultOptionLabel={t('selector-country')} value={place_of_incorporation} onChange={country => props.handleSelectorChange('place_of_incorporation', country)}/>}
        />
        <Form.Input
          label={t('subscription-form-corp-contact')}
          name={'contact_number'}
          value={contact_number}
          type={'number'}
          error={contact_number_error}
          onChange={props.handleChange}
        />
        <Form.Input
          label={t('subscription-form-corp-email')}
          name={'email'}
          value={email}
          type={'email'}
          error={email_error}
          onChange={props.handleChange}
        />
        <Form.Field
          label={t('subscription-form-corp-usa')}
          name={'relation_to_usa'}
          value={relation_to_usa}
          error={relation_to_usa_error}
          control={TextArea}
          rows={2}
          onChange={props.handleChange}
        />
        <Form.Input
          label={t('subscription-form-comments')}
          name={'comments'}
          value={comments}
          onChange={props.handleChange}
          control={TextArea}
          rows={3}
        />
      </Form>
    </Fragment>
  );
};