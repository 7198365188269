import React, {Component, Fragment} from 'react';
import {Button, Form, Icon, Label, Message, Segment, Table} from "semantic-ui-react";
import {MessageDialog} from "../../../../components/message-dialog";
import {LoanRequestService} from "../../../../services/loan-request-service";
import {FileViewerDialog} from "../../../../components/file-viewer-dialog";
import {UserService} from "../../../../services/user-service";
import DataConfirmationDialog from "../../../../components/data-confirmation-dialog";
import {StringUtils} from "../../../../components/strings";
import {LoanDocumentEditDialog} from "./loan-document-edit-dialog";

export default class AdminLoanDocumentsComponent extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    loanDocuments: [],
    loanDocumentDescription: '',
    loanDocumentFile: [],
    loanDocumentExpiryDate: null,
    loanDocumentExtendedExpiryDate: null,
    dataConfirmDialogOpen: false,
    dataConfirmDialogTitle: '',
    dataConfirmDialogDescription: '',
    dataConfirmDialogOnYesClick: () => {},
    editLoanDocumentDialogOpen: false,
    editLoanDocumentId: '',
    editLoanDocumentDescription: '',
    editLoanDocumentExpiryDate: null,
    editLoanDocumentExtendedExpiryDate: null
  };

  componentDidMount = () => LoanRequestService.getDocuments(this.props.loanRequestId, 'loan', this.onListSuccess, this.onError);

  onListSuccess = listItems => this.setState({loanDocuments: listItems, isLoading: false, editLoanDocumentDialogOpen: false});

  onError = error => {
    if (!(error.message.includes('Table not found') || error.message.includes("Column 'attachments' does not exist"))) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving Loan Documents',
        dialogDescription: error.message,
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => this.setState({dialogOpen: false});

  viewLoanDocument = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  /* LoanDocument Form */
  onDataChange = (e, {name, value}) => this.setState({[name]: value});
  onFileChange = (e, {name}) => this.setState({[name]: e.target.files});

  uploadLoanDocument = () => {
    const {loanDocumentDescription, loanDocumentFile, loanDocumentExpiryDate, loanDocumentExtendedExpiryDate} = this.state;
    LoanRequestService.uploadDocument(this.props.loanRequestId, loanDocumentFile, 'loan', loanDocumentDescription, loanDocumentExpiryDate, loanDocumentExtendedExpiryDate, this.onSaveLoanDocumentSuccess, this.onUploadLoanDocumentError);

    document.getElementById("loanDocument-form").reset();
  };

  onSaveLoanDocumentSuccess = loanDocument => {
    LoanRequestService.getDocuments(this.props.loanRequestId, 'loan', this.onListSuccess, this.onError);
    //this.props.onLoadDocumentPublished(loanDocument);
  };

  onUploadLoanDocumentError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading Loan Document',
    dialogMessage: "There's an error uploading or saving Loan Document. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  toggleLoanDocumentPublished = (loanDocumentId, published) => {
    let data = {
      objectId: loanDocumentId,
      published: !published
    };

    LoanRequestService.updateDocument(data, this.onSaveLoanDocumentSuccess, this.onLoanDocumentSaveError);
  };

  onLoanDocumentSaveError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Updating LoanDocument',
    dialogMessage: "There's an error publishing/unpublishing Loan Document. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  /*
    Messaging the User
   */
  messageUser = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Message User',
    dataConfirmDialogDescription: 'Type in your message here.',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick(descriptionData.description)
  });

  onConfirmDialogYesClick = (message) => {
    UserService.getUser(this.props.ownerId,
        (user) => {
          let subject = 'New Loan Documents uploaded/updated';
          LoanRequestService.notifyUser(user, this.props.loanRequestId, subject, message, console.log, console.log);
        }, console.log);

    this.setState({confirmDialogOpen: false, dataConfirmDialogOpen: false});
  }

  onDataConfirmDialogNoClick = () => this.setState({dataConfirmDialogOpen: false});

  /* Editing Loan Document Metadata */
  editLoanDocumentMetadata = (loanDocumentId, description, expiryDate, extendedExpiryDate) => this.setState({
    editLoanDocumentDialogOpen: true,
    editLoanDocumentId: loanDocumentId,
    editLoanDocumentDescription: description,
    editLoanDocumentExpiryDate: expiryDate,
    editLoanDocumentExtendedExpiryDate: extendedExpiryDate
  });

  onLoanDocumentEditDialogYesClick = ({loanDocumentId, description, expiryDate, extendedExpiryDate}) => {
    let data = {
      objectId: loanDocumentId,
      document_description: description
    };

    if (expiryDate) data['expiry_date'] = new Date(expiryDate);
    if (extendedExpiryDate) data['extended_expiry_date'] = new Date(extendedExpiryDate);

    LoanRequestService.updateDocument(data, this.onSaveLoanDocumentSuccess, this.onLoanDocumentSaveError);
  };

  onLoanDocumentEditDialogNoClick = () => this.setState({editLoanDocumentDialogOpen: false});
  
  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, loanDocuments} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl} = this.state;
    const {dataConfirmDialogOpen, dataConfirmDialogTitle, dataConfirmDialogDescription, dataConfirmDialogOnYesClick} = this.state;
    const {editLoanDocumentDialogOpen, editLoanDocumentId, editLoanDocumentDescription, editLoanDocumentExpiryDate, editLoanDocumentExtendedExpiryDate} = this.state;

    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <DataConfirmationDialog open={dataConfirmDialogOpen} title={dataConfirmDialogTitle}
                                description={dataConfirmDialogDescription} onNoClick={this.onDataConfirmDialogNoClick}
                                onYesClick={dataConfirmDialogOnYesClick}/>

        <LoanDocumentEditDialog open={editLoanDocumentDialogOpen}
                                identifier={editLoanDocumentId}
                                description={editLoanDocumentDescription}
                                expiryDate={editLoanDocumentExpiryDate}
                                extendedExpiryDate={editLoanDocumentExtendedExpiryDate}
                                onYesClick={this.onLoanDocumentEditDialogYesClick}
                                onNoClick={this.onLoanDocumentEditDialogNoClick} />
        <Segment padded>
          <Label color={'black'} attached={'top left'}>Upload</Label>
          <Form id={'loanDocument-form'}>
            <Form.Input
              label={'Description of Loan Document'}
              name={'loanDocumentDescription'}
              onChange={this.onDataChange}
            />
            <Form.Input
              label={'Loan Document'}
              name={'loanDocumentFile'}
              type={'file'}
              accept={'.pdf'}
              onChange={this.onFileChange}
            />
            <Form.Input
              label={'Expiry Date (MM/DD/YYYY)'}
              name={'loanDocumentExpiryDate'}
              type={'date'}
              onChange={this.onDataChange}
            />
            <Form.Input
              label={'Extended Expiry Date (MM/DD/YYYY)'}
              name={'loanDocumentExtendedExpiryDate'}
              type={'date'}
              onChange={this.onDataChange}
            />
            <Button onClick={this.uploadLoanDocument} color={'blue'}>Upload Loan Document</Button>
          </Form>
        </Segment>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>Loan Documents</Label>
          {loanDocuments.length === 0 && <Message negative>
            <Message.Header>
              No Data Found
            </Message.Header>
            <p>
              There is no Loan Documents uploaded
            </p>
          </Message>}
          {loanDocuments.length > 0 && <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Upload Date</Table.HeaderCell>
                <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                <Table.HeaderCell>Extended Expiry Date</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>View</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>Status</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                loanDocuments.map(loanDocument => (
                    <Table.Row key={loanDocument.objectId}>
                      <Table.Cell>{loanDocument.document_description}</Table.Cell>
                      <Table.Cell>{new Date(loanDocument.created).toLocaleString()}</Table.Cell>
                      <Table.Cell>{StringUtils.toLocaleDateString(loanDocument.expiry_date)}</Table.Cell>
                      <Table.Cell>{StringUtils.toLocaleDateString(loanDocument.extended_expiry_date)}</Table.Cell>
                      <Table.Cell textAlign={"center"}>
                        <Button color={'blue'} onClick={() => this.viewLoanDocument(loanDocument.fileURL)}>View</Button>
                      </Table.Cell>
                      <Table.Cell
                        textAlign={"center"}>{loanDocument.published ? 'Published' : 'Not Published'}</Table.Cell>
                      <Table.Cell textAlign={"center"}>
                        <Button color={loanDocument.published ? 'red' : 'green'} inverted
                                onClick={() => this.toggleLoanDocumentPublished(loanDocument.objectId, loanDocument.published)}>{loanDocument.published ? 'Unpublish' : 'Publish'}</Button>
                        <Button color={'blue'} inverted
                                onClick={() => this.editLoanDocumentMetadata(loanDocument.objectId, loanDocument.document_description, loanDocument.expiry_date, loanDocument.extended_expiry_date)}>Edit</Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )
              }
            </Table.Body>
          </Table>}

          <Segment textAlign={'right'}>
            <Button basic color={'blue'} onClick={this.messageUser}>
              <Icon name={'mail'}/>
              Message User
            </Button>
          </Segment>
        </Segment>
      </Fragment>

    )
  }
}