import React, {Fragment} from "react";
import {SignatoryForm} from "../../../shared/signatory-form";

export const SubscriptionCorporateSignatoryForm = props => {
  return (
    <Fragment>
      <SignatoryForm title={'Information on Authorised Signatory'}
                     nameLabel={'Name of Director/Power of Attorney'}
                     data={props.data}
                     errors={props.errors}
                     onDataChange={props.onDataChange}/>
    </Fragment>
  )
};