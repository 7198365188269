import React, {Component} from "react";
import Flag from "semantic-ui-react/dist/commonjs/elements/Flag";
import {Segment} from "semantic-ui-react";
import {withTranslation} from "react-i18next";

class LanguageToggler extends Component {
  state = {
    flag: '',
    language: '',
    code: ''
  }

  english = {
    flag: 'gb',
    language: "English",
    code: 'en-GB'
  }

  chinese = {
    flag: 'cn',
    language: "Chinese",
    code: 'zh-CN'
  }

  componentDidMount = () => {
    const { i18n } = this.props;

    if (i18n.language === 'zh-CN')
      this.setState(this.english);
    else
      this.setState(this.chinese);
  }

  changeLanguage = () => {
    const { i18n } = this.props;

    switch (i18n.language) {
      case "en-GB":
        this.setState(this.english);
        i18n.changeLanguage(this.chinese.code);
        break;
      case "zh-CN":
        this.setState(this.chinese);
        i18n.changeLanguage(this.english.code);
        break;
      default:
        this.setState(this.english);
        i18n.changeLanguage(this.chinese.code);
    }
  }

  render = () => {
    const {flag, language} = this.state;

    return (
      <Segment basic onClick={this.changeLanguage}>
        <Flag name={flag}/> {language}
      </Segment>
    );
  };
}

export default withTranslation("translations")(LanguageToggler);