import React, {Component, Fragment} from 'react';
import {Button, Icon, Label, Segment, Table} from "semantic-ui-react";
import SingleGridLayout from '../../layout/single-grid';
import { LoanRequestService} from "../../services/loan-request-service";
import {MessageDialog} from "../../components/message-dialog";
import DataLoader from "../../components/data-loader";
import {StringUtils} from "../../components/strings";

import {withTranslation} from "react-i18next";

class LoansPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    loanRequests: [],
    isLoading: true
  };

  loadData = () => {
    let ownerId = localStorage.getItem('userId');
    LoanRequestService.list(`ownerId='${ownerId}'`, '', 1, this.onListSuccess, this.onError);
  };

  componentDidMount = () => this.loadData();

  onListSuccess = listItems => {
    this.setState({ loanRequests: listItems, isLoading: false});
  };

  onError = error => {
    if (error.message.toString().includes("Table not found by name")) {
      this.setState({ isLoading: false});
      return;
    }

    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Retrieving Loan Requests',
      dialogMessage: this.props.t('general-error-retrieving-records'),
      dialogDescription: error.message,
      isError: true,
      isLoading: false
    });
  };

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/dashboard");
  };

  viewLoanRequest = loanRequestId => this.props.history.push(`/pages/loan-request-view/${loanRequestId}`);

  requestLoan = () => this.props.history.push("/pages/loan-request");

  getTranslationKey = input => {
    return `loans-table-loan_type-${input}`;
  }

  getRefTranslationKey = input => {
    return `button-${input}`;
  }

  render = () => {
    const { dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, isLoading, loanRequests} = this.state;

    const { t} = this.props;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <SingleGridLayout>
          <Segment basic textAlign={'right'}>
            <Button color={'blue'} onClick={this.requestLoan}>
              <Icon name={'plus'} />
              {t('loans-request')}
            </Button>
          </Segment>
          <DataLoader isLoading={isLoading}
                      itemsLength={loanRequests.length}
                      notFoundMessage={t('loans-not-found')}
          />
          { !isLoading && loanRequests.length>0 &&
            <Segment>
              <Label color={'black'} attached={'top left'}>{t('loans-table-requests')}</Label>

              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('loans-table-name')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-created')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-loan_type')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-stock_code')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-pledge_number')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-loan_amount')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-loan_purpose')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-loan_is_refinancing')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('loans-table-status')}</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    loanRequests.map(loanRequest => (
                        <Table.Row key={loanRequest.objectId}>
                          <Table.Cell>
                            { loanRequest.name } <br />
                            ({ StringUtils.capitalize(loanRequest.borrower_referrer) })
                          </Table.Cell>
                          <Table.Cell>{ new Date(loanRequest.created).toLocaleString() }</Table.Cell>
                          <Table.Cell>{t(this.getTranslationKey(loanRequest.loan_type))}</Table.Cell>
                          <Table.Cell>{ StringUtils.upCase(loanRequest.stock_code) }</Table.Cell>
                          <Table.Cell textAlign={'right'}>{ loanRequest.pledge_number }</Table.Cell>
                          <Table.Cell textAlign={'right'}>{ loanRequest.loan_amount }</Table.Cell>
                          <Table.Cell>{ loanRequest.loan_purpose }</Table.Cell>
                          <Table.Cell>{t(this.getRefTranslationKey(loanRequest.loan_is_refinancing))}</Table.Cell>
                          <Table.Cell
                            negative={loanRequest.status === 'rejected' || loanRequest.status === 'loan_rejected'}  positive={loanRequest.status === 'approved' || loanRequest.status === 'loan_executed'}>
                            {LoanRequestService.translateStatus(loanRequest.status)} { ['rejected','approved'].includes(loanRequest.status) && new Date(loanRequest.approval_rejection_date).toLocaleString()}{ ['loan_rejected','loan_executed'].includes(loanRequest.status) && new Date(loanRequest.loan_approval_rejection_date).toLocaleString()}
                            { loanRequest.status==='rejected' && <Fragment><br/>{loanRequest.approval_rejection_reason}</Fragment>}
                            { loanRequest.status==='loan_rejected' && <Fragment><br/>{loanRequest.loan_approval_rejection_reason}</Fragment>}
                            { loanRequest.status==='indicative_terms_sent' && <Fragment><br/><b>Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.indicative_terms_expiry_date)}</Fragment>}
                            { loanRequest.status==='indicative_terms_sent' && <Fragment><br/><b>Extended Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.indicative_terms_extended_expiry_date)}</Fragment>}
                            { loanRequest.status==='term_sheet' && <Fragment><br/><b>Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.term_sheet_expiry_date)}</Fragment>}
                            { loanRequest.status==='term_sheet' && <Fragment><br/><b>Extended Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.term_sheet_extended_expiry_date)}</Fragment>}
                            { ['kyc_requested', 'kyc_incomplete'].includes(loanRequest.status) && <Fragment><br/><b>Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.kyc_expiry_date)}</Fragment>}
                            { ['kyc_requested', 'kyc_incomplete'].includes(loanRequest.status)  && <Fragment><br/><b>Extended Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.kyc_extended_expiry_date)}</Fragment>}
                          </Table.Cell>
                          <Table.Cell>
                            <Button floated={'right'} color={'blue'} onClick={() => this.viewLoanRequest(loanRequest.objectId)}>{t('loan-details')}</Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )

                  }
                </Table.Body>
              </Table>
            </Segment>
          }
        </SingleGridLayout>
      </Fragment>

    )
  }
}

export default withTranslation("translations")(LoansPage);