import React, {Component, Fragment} from 'react';
import {Button, Form, Label, Segment, Table} from "semantic-ui-react";
import SingleGridLayout from '../../../layout/single-grid';
import {MessageDialog} from "../../../components/message-dialog";
import {InfosheetService} from "../../../services/infosheet-service";
import {FileViewerDialog} from "../../../components/file-viewer-dialog";
import {InfosheetEditDialog} from "./components/infosheet-edit-dialog";

export default class AdminInfosheetsPage extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    infosheets: [],
    infosheetDescription: '',
    infosheetFile: [],
    editInfosheetDialogOpen: false,
    editInfosheetId: '',
    editInfosheetDescription: '',
    editInfosheetPriority: 0
  };


  componentDidMount = () => InfosheetService.list(this.onListSuccess, this.onError);

  onListSuccess = listItems => {
    this.setState({infosheets: listItems, isLoading: false, editInfosheetDialogOpen: false});
  };

  onError = error => {
    if (!error.message.includes('Table not found')) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving Infosheet',
        dialogDescription: error.message,
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => {
    const {isError} = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/admin/dashboard");
  };

  viewInfosheet = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  /* Infosheet Form */
  onDataChange = (e, {name, value}) => this.setState({[name]: value});
  onFileChange = (e, {name}) => this.setState({[name]: e.target.files});

  uploadInfosheet = () => {
    const {infosheetDescription, infosheetFile} = this.state;
    InfosheetService.upload(infosheetFile, infosheetDescription, this.onSaveInfosheetSuccess, this.onUploadInfosheetError)

    document.getElementById("infosheet-form").reset();
  };

  onSaveInfosheetSuccess = infosheet => InfosheetService.list(this.onListSuccess, this.onError);

  onUploadInfosheetError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading Infosheet',
    dialogMessage: "There's an error uploading or saving Infosheet. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  toggleInfosheetPublished = (infosheetId, published) => {
    let data = {
      objectId: infosheetId,
      published: !published
    };

    InfosheetService.save(data, this.onSaveInfosheetSuccess, this.onInfosheetSaveError);
  };

  onInfosheetSaveError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Updating Infosheet',
    dialogMessage: "There's an error publishing/unpublishing Infosheet. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  /* Editing InfoSheet Metadata */
  editInfosheetMetadata = (infosheetId, description, priority) => this.setState({
    editInfosheetDialogOpen: true,
    editInfosheetId: infosheetId,
    editInfosheetDescription: description,
    editInfosheetPriority: priority
  });

  onInfosheetEditDialogYesClick = ({infosheetId, description, priority}) => {
    let data = {
      objectId: infosheetId,
      description: description,
      priority: Number(priority)
    };

    InfosheetService.save(data, this.onSaveInfosheetSuccess, this.onInfosheetSaveError);
  };

  onInfosheetEditDialogNoClick = () => this.setState({editInfosheetDialogOpen: false});

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, infosheets} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl} = this.state;
    const {editInfosheetDialogOpen, editInfosheetId, editInfosheetDescription, editInfosheetPriority} = this.state;
    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <InfosheetEditDialog
          open={editInfosheetDialogOpen}
          identifier={editInfosheetId}
          description={editInfosheetDescription}
          priority={editInfosheetPriority}
          onYesClick={this.onInfosheetEditDialogYesClick}
          onNoClick={this.onInfosheetEditDialogNoClick}/>

        <SingleGridLayout isAdmin>
          <Segment padded>
            <Label color={'black'} attached={'top left'}>Upload</Label>
            <Form id={'infosheet-form'}>
              <Form.Input
                label={'Description of Infosheet'}
                name={'infosheetDescription'}
                onChange={this.onDataChange}
              />
              <Form.Input
                label={'Infosheet'}
                name={'infosheetFile'}
                type={'file'}
                accept={'.pdf'}
                onChange={this.onFileChange}
              />
              <Button onClick={this.uploadInfosheet} color={'blue'}>Upload Infosheet</Button>
            </Form>
          </Segment>
          {infosheets.length > 0 &&
          <Segment padded>
            <Label color={'black'} attached={'top left'}>Infosheets</Label>

            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Priority</Table.HeaderCell>
                  <Table.HeaderCell>Upload Date</Table.HeaderCell>
                  <Table.HeaderCell textAlign={"center"}>View</Table.HeaderCell>
                  <Table.HeaderCell textAlign={"center"}>Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign={"center"}>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  infosheets.map(infosheet => (
                      <Table.Row key={infosheet.objectId}>
                        <Table.Cell>{infosheet.description}</Table.Cell>
                        <Table.Cell>{infosheet.priority}</Table.Cell>
                        <Table.Cell>{new Date(infosheet.created).toLocaleString()}</Table.Cell>
                        <Table.Cell textAlign={"center"}>
                          <Button color={'blue'}
                                  onClick={() => this.viewInfosheet(infosheet.fileURL)}>View</Button>
                        </Table.Cell>
                        <Table.Cell
                          textAlign={"center"}>{infosheet.published ? 'Published' : 'Not Published'}</Table.Cell>
                        <Table.Cell textAlign={"center"}>
                          <Button color={infosheet.published ? 'red' : 'green'} inverted
                                  onClick={() => this.toggleInfosheetPublished(infosheet.objectId, infosheet.published)}>{infosheet.published ? 'Unpublish' : 'Publish'}</Button>
                          <Button color={'blue'} inverted
                                  onClick={() => this.editInfosheetMetadata(infosheet.objectId, infosheet.description, infosheet.priority)}>Edit</Button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )

                }
              </Table.Body>
            </Table>
          </Segment>

          }
        </SingleGridLayout>
      </Fragment>

    )
  }
}