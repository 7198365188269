import React, {Component, Fragment} from 'react';
import SingleGridLayout from '../../layout/single-grid';
import {Button, Form, Label, Menu, Message, Segment, Table, TextArea} from "semantic-ui-react";
import {LoanRequestService} from "../../services/loan-request-service";
import {MessageDialog} from "../../components/message-dialog";
import DataLoader from "../../components/data-loader";
import {StringUtils} from "../../components/strings";
import {FileViewerDialog} from "../../components/file-viewer-dialog";
import {ConfirmationDialog} from "../../components/confirmation-dialog";
import LoanDocumentsComponent from "./components/loan-documents";
import LoanKycDocumentsComponent from "./components/kyc-documents";
import {BACKENDLESS_REST_KEY, BACKENDLESS_APP_ID} from "../../config";

import {withTranslation} from "react-i18next";

class LoanRequestViewPage extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    loanRequest: {},
    activeTermSheet: {},
    signedTermSheet: {},
    isLoading: true,
    confirmDialogOpen: false,
    confirmDialogMessage: '',
    confirmDialogYesClick: () => {},
    activeItem: 'details'
  };

  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  componentDidMount = () => {
    const { loanRequestId} = this.props.match.params;
    LoanRequestService.get(loanRequestId, this.onGetSuccess, this.onError)
  };

  onGetSuccess = loanRequest => {
    this.setState({ loanRequest: loanRequest});
    LoanRequestService.getTermSheets(loanRequest.objectId, this.onGetTermSheetsSuccess, this.onGetDocumentsError);
  };

  onGetTermSheetsSuccess = termSheets => {
    termSheets.forEach(termSheet => {
      if (termSheet.document_type==='term-sheet') this.setState({ activeTermSheet: termSheet});
      if (termSheet.document_type==='signed-term-sheet') this.setState({ signedTermSheet: termSheet});
    });
    this.setState({ isLoading: false});
  };

  onGetDocumentsError = error => this.setState({ isLoading: false});

  onError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Retrieving Loan Request',
    dialogMessage: this.props.t('general-error-retrieving-record'),
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/loan-requests");
  };

  /* Term Sheet */
  onFileChange = (e, {name}) => this.setState({ [name]: e.target.files});

  viewTermSheet = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({ fileViewerDialogOpen: false});

  /* Accepting Indicative Financing Term */

  /* Accepting Term Sheet */
  acceptTermSheet = () => this.setState({
    confirmDialogOpen: true,
    confirmDialogMessage: this.props.t('loan-teamsheet-confirm-upload'),
    confirmDialogYesClick: this.onConfirmDialogYesClick
  });

  onConfirmDialogYesClick = () => {
    const { loanRequest, signed_term_sheet } = this.state;
    if (typeof signed_term_sheet === "undefined") {
      this.setState({confirmDialogOpen: false});
      return;
    }

    LoanRequestService.uploadDocument(loanRequest.objectId, signed_term_sheet, 'signed-term-sheet', 'Signed Term Sheet', null, null, this.termSheetUploadSuccess, this.termSheetUploadError);
  };

  termSheetUploadSuccess = termSheet => {
    const { loanRequest } = this.state;
    this.setState({confirmDialogOpen: false});

    LoanRequestService.getTermSheets(loanRequest.objectId, this.onGetTermSheetsSuccess, this.onGetDocumentsError());

    LoanRequestService.save({ objectId: loanRequest.objectId, status: 'term_sheet_signed'}, this.loanRequestUpdated, this.onError)
  };

  loanRequestUpdated = loanRequest => {
    this.setState({loanRequest: loanRequest});
    LoanRequestService.notifyAdminOnTermSheetSigned(loanRequest.objectId, console.log, console.log);
  }

  termSheetUploadError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading Term Sheet',
    dialogMessage: "There's an error uploading the Term Sheet. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onConfirmDialogNoClick  = () => this.setState({confirmDialogOpen: false});

  getKycLink = () => {
    const {loanRequest} = this.state;
    return `https://backendlessappcontent.com/${BACKENDLESS_APP_ID}/${BACKENDLESS_REST_KEY}/files/ols_templates/${loanRequest.kyc_type}_${loanRequest.loan_type}_kyc.pdf`;
  };

  edit = () => {
    const {loanRequest} = this.state;
    this.props.history.push({
      pathname: '/pages/loan-request',
      state: {loanRequest: loanRequest}
    });
  }

  render = () => {
    const { dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, isLoading, loanRequest, activeTermSheet, signedTermSheet, activeItem} = this.state;
    const { fileViewerDialogOpen, fileViewerFileUrl} = this.state;
    const { confirmDialogOpen, confirmDialogMessage, confirmDialogYesClick} = this.state;

    const { t} = this.props;

    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl} onOkClick={this.onFileViewerDialogOkClick} />

        <ConfirmationDialog open={confirmDialogOpen}
                            message={confirmDialogMessage}
                            description={t('general-irreversible-message')}
                            onYesClick={confirmDialogYesClick}
                            onNoClick={this.onConfirmDialogNoClick}
                            t={t}
        />

        <MessageDialog open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}/>
        <SingleGridLayout>
          <DataLoader isLoading={isLoading}
                      itemsLength={1}
                      notFoundMessage={'There are no such Loan Request.'}
          />
          {!isLoading && <Segment padded>
            <Label color={'black'} attached={'top left'}>{t('loan-detail-title')}</Label>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-types')}</Table.Cell>
                  <Table.Cell>{StringUtils.capitalize(loanRequest.loan_type)} ({StringUtils.capitalize(loanRequest.borrower_referrer)})</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-name')}</Table.Cell>
                  <Table.Cell>{loanRequest.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-created')}</Table.Cell>
                  <Table.Cell>{new Date(loanRequest.created).toLocaleString()}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-stock_code')}</Table.Cell>
                  <Table.Cell>{loanRequest.stock_code}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-pledge_number')}</Table.Cell>
                  <Table.Cell>{loanRequest.pledge_number}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-loan_amount')}</Table.Cell>
                  <Table.Cell>{loanRequest.loan_amount}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-loan_purpose')}</Table.Cell>
                  <Table.Cell>{loanRequest.loan_purpose}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-loan_is_refinancing')}</Table.Cell>
                  <Table.Cell>{StringUtils.capitalize(StringUtils.denull(loanRequest.loan_is_refinancing))}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-status')}</Table.Cell>
                  <Table.Cell>{LoanRequestService.translateStatus(loanRequest.status)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-detail-comments')}</Table.Cell>
                  <Table.Cell className={'multiline'}>{loanRequest.comments}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            { !['loan_rejected', 'loan_executed', 'rejected'].includes(loanRequest.status) && <Segment basic textAlign={"right"}>
              <Button color={"blue"} onClick={this.edit}>{t('button-edit')}</Button>
            </Segment>}

            { loanRequest.status==='kyc_requested' && <Message attached={"top"} warning>
              <p>
                KYC has been requested. Please upload your signed KYC form and supporting documents in the <b>KYC Documents</b> tab below.
                <br />
                This may be a request to re-upload invalid or incomplete KYC Form.
              </p>
              <p>
                Please download the KYC form for this Loan Request by <Label as={'a'} color={'green'} target={'_blank'} href={this.getKycLink()}>clicking here</Label>.
              </p>
            </Message>}
          </Segment>
          }

          { !isLoading && <Menu pointing secondary>
            <Menu.Item
              name='details'
              active={activeItem === 'details'}
              onClick={this.handleItemClick}
            >{t('loan-detail-submenu-details')}</Menu.Item>

            <Menu.Item
              name='kyc-documents'
              active={activeItem === 'kyc-documents'}
              onClick={this.handleItemClick}
            >{t('loan-detail-submenu-kyc')}</Menu.Item>

            <Menu.Item
              name='loan-documents'
              active={activeItem === 'loan-documents'}
              onClick={this.handleItemClick}
            >{t('loan-detail-submenu-documents')}</Menu.Item>

          </Menu> }

          {!isLoading && activeItem==='details' && <Segment padded>
            <Label color={'black'} attached={'top left'}>{ t('loan-ift-label')}</Label>
            <Form id={'indicative-terms-form'} size='large'>
              <Form.Field
                label={t('loan-ift-label')}
                name={'indicative_terms'}
                value={loanRequest.indicative_terms}
                control={TextArea}
                rows={10}
                editable={'false'}
              />
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{t('loan-ift-expiry')}</Table.Cell>
                    <Table.Cell>{StringUtils.toLocaleDateString(loanRequest.indicative_terms_expiry_date)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t('loan-ift-extended')}</Table.Cell>
                    <Table.Cell>{StringUtils.toLocaleDateString(loanRequest.indicative_terms_extended_expiry_date)}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Form>
            {loanRequest.indicative_terms_date && <Segment padded style={{minHeight: 80}}>
              {loanRequest.indicative_terms_date && `Indicative Financing Terms on ${new Date(loanRequest.indicative_terms_date).toLocaleString()}`}
              {loanRequest.status !== 'rejected' && loanRequest.indicative_terms_date && false &&
              <Button floated={"right"} color={'blue'} onClick={this.acceptIndicativeTerms}>Accept Indicative Financing
                Terms</Button>}
            </Segment>
            }
          </Segment>}

          {!isLoading && activeItem==='details' && <Segment padded>
            <Label color={'black'} attached={'top left'}>{ t('loan-termsheet-title')}</Label>
            {!activeTermSheet.created && <Message negative>
              <Message.Header>
                { t('general-no-data')}
              </Message.Header>
              <p>
                { t('loan-termsheet-not-found')}
              </p>
            </Message>
            }
            {activeTermSheet.created &&
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{ t('loan-termsheet-title')}</Table.Cell>
                  <Table.Cell>
                    <Button color={'blue'} onClick={() => this.viewTermSheet(activeTermSheet.fileURL)}>{t('button-view')}</Button>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{ t('loan-termsheet-table-upload')}</Table.Cell>
                  <Table.Cell>{new Date(activeTermSheet.created).toLocaleString()}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{ t('loan-termsheet-title')} {t('loan-ift-expiry')}</Table.Cell>
                  <Table.Cell>{StringUtils.toLocaleDateString(loanRequest.term_sheet_expiry_date)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{ t('loan-termsheet-title')} {t('loan-ift-extended')}</Table.Cell>
                  <Table.Cell>{StringUtils.toLocaleDateString(loanRequest.term_sheet_extended_expiry_date)}</Table.Cell>
                </Table.Row>
                { !signedTermSheet.created && <Table.Row>
                  <Table.Cell>{ t('button-accept')}</Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Form.Input
                        label={t('loan-termsheet-table-signed')}
                        name={'signed_term_sheet'}
                        type={'file'}
                        accept={'.pdf'}
                        onChange={this.onFileChange}
                      />
                      <Button color={'red'} onClick={() => this.acceptTermSheet()}>{t('loan-termsheet-table-upload-button')}</Button>
                    </Form>
                  </Table.Cell>
                </Table.Row>}
              </Table.Body>
            </Table>
            }
          </Segment>
          }

          {!isLoading && activeItem==='details' && signedTermSheet.created && <Segment padded>
            <Label color={'black'} attached={'top left'}>{t('loan-termsheet-table-signed')}</Label>

            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{t('loan-termsheet-title')}</Table.Cell>
                  <Table.Cell>
                    <Button color={'blue'} onClick={() => this.viewTermSheet(signedTermSheet.fileURL)}>{t('button-view')}</Button>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('loan-termsheet-table-upload')}</Table.Cell>
                  <Table.Cell>{new Date(signedTermSheet.created).toLocaleString()}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          }

          {!isLoading && activeItem==='kyc-documents' && <LoanKycDocumentsComponent loanRequestId={loanRequest.objectId} kyc_expiry_date={loanRequest.kyc_expiry_date} kyc_extended_expiry_date={loanRequest.kyc_extended_expiry_date}  />}
          {!isLoading && activeItem==='loan-documents' && <LoanDocumentsComponent loanRequestId={loanRequest.objectId} />}
        </SingleGridLayout>
      </Fragment>

    )
  }
}

export default withTranslation("translations")(LoanRequestViewPage)