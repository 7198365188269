import React from "react";
import {Menu, Image, Icon, Responsive} from "semantic-ui-react";
import LanguageToggler from "./language-toggler";
export const OLSHeader = props => {
  const doLogout = () => window.location.href = '/#/pages/logout';
  const doEditProfile = () => {
    if (props.isAdmin)
      window.location.href = '/#/pages/admin/profile';
    else
      window.location.href = '/#/pages/profile';
  }

  const doGoHome = () => {
    if (props.isAdmin)
      window.location.href = '/#/pages/admin/dashboard';
    else
      window.location.href = '/#/pages/dashboard';
  }

  return (
    <Menu attached={'top'}>
      <Menu.Item>
        <Icon name={ props.isShowing ? 'cancel' : 'bars'} size={'big'} onClick={props.onHamburgerClick}/>
      </Menu.Item>
      <Responsive as={Menu.Item} minWidth={768}>
        <Image size={'large'} src={'/logo-banner.png'}/>
      </Responsive>
      <Menu.Menu position={'right'}>
        { !props.isAdmin && <Menu.Item>
          <LanguageToggler />
        </Menu.Item>}
        <Menu.Item>
          <Icon name={'home'} size={'large'} onClick={doGoHome}/>
        </Menu.Item>
        <Menu.Item>
          <Icon name={'user'} size={'large'} onClick={doEditProfile}/>
        </Menu.Item>
        <Menu.Item>
          <Icon name={'power off'} size={'large'} onClick={doLogout}/>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};
