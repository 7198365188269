import React, {Component, Fragment} from 'react';
import {Button, Label, Message, Segment, Table} from "semantic-ui-react";
import {MessageDialog} from "../../../components/message-dialog";
import {LoanRequestService} from "../../../services/loan-request-service";
import {FileViewerDialog} from "../../../components/file-viewer-dialog";

import { withTranslation} from "react-i18next";
import {StringUtils} from "../../../components/strings";

class LoanDocumentsComponent extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    loanDocuments: []
  };


  componentDidMount = () => LoanRequestService.getDocumentsByQuery(this.props.loanRequestId, 'loan', "published = true", this.onListSuccess, this.onError);

  onListSuccess = listItems => this.setState({loanDocuments: listItems, isLoading: false});

  onError = error => {
    if (!(error.message.includes('Table not found') || error.message.includes("Column 'attachments' does not exist"))) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving Loan Documents',
        dialogDescription: this.props.t('general-error-retrieving-records'),
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => this.setState({dialogOpen: false});

  viewLoanDocument = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, loanDocuments} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl} = this.state;

    const { t} = this.props;
    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>{ t('loan-documents-title')}</Label>
          {loanDocuments.length === 0 && <Message negative>
            <Message.Header>
              { t('general-no-data')}
            </Message.Header>
            <p>
              { t('loan-documents-not-found')}
            </p>
          </Message>}


          {loanDocuments.length > 0 && <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Upload Date</Table.HeaderCell>
                <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                <Table.HeaderCell>Extended Expiry Date</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>View</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                loanDocuments.map(loanDocument => (
                    <Table.Row key={loanDocument.objectId}>
                      <Table.Cell>{loanDocument.document_description}</Table.Cell>
                      <Table.Cell>{new Date(loanDocument.created).toLocaleString()}</Table.Cell>
                      <Table.Cell>{StringUtils.toLocaleDateString(loanDocument.expiry_date)}</Table.Cell>
                      <Table.Cell>{StringUtils.toLocaleDateString(loanDocument.extended_expiry_date)}</Table.Cell>
                      <Table.Cell textAlign={"center"}>
                        <Button color={'blue'} onClick={() => this.viewLoanDocument(loanDocument.fileURL)}>View</Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )
              }
            </Table.Body>
          </Table>}
        </Segment>
      </Fragment>
    )
  }
}

export default withTranslation("translations")(LoanDocumentsComponent);