import React, {Component} from "react";
import SingleGridLayout from "../../layout/single-grid";
import {SubscriptionQualification} from "./components/qualification";

import { withTranslation} from "react-i18next";

class SubscriptionNewPage extends Component {
  state = {
  };

  onSubscribe = (subscriptionType, corporateAccountType) => {
    localStorage.setItem('corporateAccountType', corporateAccountType);
    this.props.history.push(`/pages/subscription-request/${subscriptionType}`);
  };

  render = () => {
    const { subscriptionType } = this.props.match.params;
    const { t} = this.props;

    let sType = 'all';
    if (typeof subscriptionType!=="undefined") sType = subscriptionType;

    return (
      <SingleGridLayout>
        <SubscriptionQualification t={t} subscriptionType={sType} onSubscribe={this.onSubscribe}/>
      </SingleGridLayout>
    );
  }
}

export default withTranslation("translations")(SubscriptionNewPage)