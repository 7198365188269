import React from 'react'
import { Select } from 'semantic-ui-react'



export const CorporateAccountSelector = ({t, name, value, onChange}) => {
  const corporateTypes = [
    { key: 'corporation', value: 'corporation', text: t('selector-corporate-account-corporation') },
    { key: 'partnership', value: 'partnership', text: t('selector-corporate-account-partnership') },
    { key: 'trust', value: 'trust', text: t('selector-corporate-account-trust') }
  ];
  return (
    <Select placeholder='Select the Corporate Type'
            options={corporateTypes}
            name={name}
            value={value} onChange={onChange} disabled={true}/>
  );
}