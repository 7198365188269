import Backendless from "backendless";

import {TBL_INFOSHEETS} from "./tables";
import { v4 as uuid} from "uuid";

export const InfosheetService = {
  upload: (fileRef, description, onSuccess, onError) => {
    let objectId = uuid().toUpperCase();
    Array.from(fileRef).forEach(fileObj => Backendless.Files.upload(fileObj, `infosheets/${objectId}/${new Date().getTime()}`)
      .then(fileUrl => {
        fileUrl['description'] = description;
        fileUrl['published'] = false;
        Backendless.Data.of(TBL_INFOSHEETS).save(fileUrl)
          .then(onSuccess)
          .catch(onError);
      })
      .catch(onError));
  },

  list: (onSuccess, onError) => {
    let queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder = queryBuilder.setPageSize(100).setSortBy("priority");

    Backendless.Data.of(TBL_INFOSHEETS).find(queryBuilder).then(onSuccess).catch(onError);
  },

  listPublished: (onSuccess, onError) => {
    let queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder = queryBuilder.setPageSize(100).setWhereClause("published=true").setSortBy("priority");

    Backendless.Data.of(TBL_INFOSHEETS).find(queryBuilder).then(onSuccess).catch(onError);
  },

  save: (infosheet, onSuccess, onError) => Backendless.Data.of(TBL_INFOSHEETS).save(infosheet)
    .then(onSuccess)
    .catch(onError)

};