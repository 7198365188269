import React, {Component, Fragment} from "react";
import {Segment, Label, Message, Table, Button, Form} from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import {UserService} from "../../services/user-service";

import { withTranslation} from "react-i18next";
import {FileViewerDialog} from "../../components/file-viewer-dialog";
import {ConfirmationDialog} from "../../components/confirmation-dialog";

class ProfileContractPage extends Component {

  state = {
    currentUser: {}
  };

  componentDidMount = () => UserService.currentUser(this.onCurrentUserSuccess, console.log);

  onCurrentUserSuccess = currentUser => this.setState({currentUser: currentUser});

  /* Saving and  Message Dialog */
  onMessageDialogOk = () => this.setState({dialogOpen: false});

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});
  /* Term Contract */
  onFileChange = (e, {name}) => this.setState({ [name]: e.target.files});

  viewTermsContract = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  /* Accepting Term Contract */
  acceptTermContract = () => this.setState({
    confirmDialogOpen: true,
    confirmDialogMessage: this.props.t('loan-teamsheet-confirm-upload'),
    confirmDialogYesClick: this.onConfirmDialogYesClick
  });

  onConfirmDialogYesClick = () => {
    const { currentUser, signed_contract } = this.state;
    console.log(signed_contract);
    if (typeof signed_contract === "undefined") {
      this.setState({confirmDialogOpen: false});
      return;
    }

    if (currentUser && currentUser.objectId && signed_contract)
      UserService.uploadTermsContract(currentUser.objectId, true, signed_contract, this.termsContractUploaded, this.termsContractUploadError);
  };

  termsContractUploaded = user => {
    console.log(user);
    this.setState({currentUser: user, confirmDialogOpen: false});
  }

  termsContractUploadError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading Term Sheet',
    dialogMessage: "There's an error uploading the Term Sheet. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onConfirmDialogNoClick  = () => this.setState({confirmDialogOpen: false});


  render = () => {
    const {currentUser, fileViewerDialogOpen, fileViewerFileUrl} = this.state;

    const {terms_contract_date, terms_contract_url, signed_terms_contract_date, signed_terms_contract_url} = currentUser;
    //const fileType = (typeof terms_contract_url==="undefined" || terms_contract_url===null) ? '' : terms_contract_url.split(/[.]+/).pop();
    const { confirmDialogOpen, confirmDialogMessage, confirmDialogYesClick} = this.state;

    const {t} = this.props;
    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl} onOkClick={this.onFileViewerDialogOkClick} />

        <ConfirmationDialog open={confirmDialogOpen}
                            message={confirmDialogMessage}
                            description={t('general-irreversible-message')}
                            onYesClick={confirmDialogYesClick}
                            onNoClick={this.onConfirmDialogNoClick}
                            t={t}
        />

        <SingleGridLayout>
          <Segment padded>
            <Label color={'black'} attached={'top left'}>{t('sidebar-tnc')}</Label>
            {!terms_contract_date && <Message negative>
              <Message.Header>
                {t('general-no-data')}
              </Message.Header>
              <p>
                {t('profile-no-tnc')}
              </p>
            </Message>
            }
            {terms_contract_date &&
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{ t('profile-contract-title')}</Table.Cell>
                  <Table.Cell>
                    <Button color={'blue'} onClick={() => this.viewTermsContract(terms_contract_url)}>{t('button-view')}</Button>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell> {t('profile-contract-upload-date')}</Table.Cell>
                  <Table.Cell>{new Date(terms_contract_date).toLocaleString()}</Table.Cell>
                </Table.Row>
                { signed_terms_contract_date && <Fragment>
                  <Table.Row>
                    <Table.Cell>{ t('profile-contract-title')}</Table.Cell>
                    <Table.Cell>
                      <Button color={'blue'} onClick={() => this.viewTermsContract(signed_terms_contract_url)}>{t('button-view')}</Button>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell> {t('profile-contract-upload-date')}</Table.Cell>
                    <Table.Cell>{new Date(signed_terms_contract_date).toLocaleString()}</Table.Cell>
                  </Table.Row>
                </Fragment>
                }
                { !signed_terms_contract_date && <Table.Row>
                  <Table.Cell>{ t('button-accept')}</Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Form.Input
                          label={t('profile-signed-contract-title')}
                          name={'signed_contract'}
                          type={'file'}
                          accept={'.pdf'}
                          onChange={this.onFileChange}
                      />
                      <Button color={'red'} onClick={() => this.acceptTermContract()}>{t('profile-signed-contract-upload-button')}</Button>
                    </Form>
                  </Table.Cell>
                </Table.Row>}
              </Table.Body>
            </Table>
            }
          </Segment>
        </SingleGridLayout>
      </Fragment>
    )
  };
};

export default withTranslation("translations")(ProfileContractPage);