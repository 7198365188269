import React, {Component, Fragment} from 'react';
import {Button, Icon, Label, Menu, Segment, Table} from "semantic-ui-react";
import SingleGridLayout from '../../layout/single-grid';
import {MessageDialog} from "../../components/message-dialog";
import DataLoader from "../../components/data-loader";
import {SubscriptionService} from "../../services/subscription-service";

import {withTranslation} from "react-i18next";

class SubscriptionsPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    subscriptions: [],
    corporateSubscriptions: [],
    isLoading: true,
    isCorporateLoading: true,
    activeItem: 'individual'
  };

  labels = {
    individual: {
      button: 'Subscribe for an Individual Account',
      title: this.props.t('subscription-table-ind-title')
    },
    corporate: {
      button: 'Subscribe for a Corporate Account',
      title: this.props.t('subscription-table-corp-title')
    }
  };


  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });


  loadData = () => {
    let ownerId = localStorage.getItem('userId');
    SubscriptionService.listByOwner(ownerId, 'individual', '', this.onListSuccess, this.onError);
    SubscriptionService.listByOwner(ownerId, 'corporate', '', this.onCorporateListSuccess, this.onCorporateError);
  };

  componentDidMount = () => this.loadData();

  onListSuccess = listItems => this.setState({ subscriptions: listItems, isLoading: false});
  onCorporateListSuccess = listItems => this.setState({ corporateSubscriptions: listItems, isCorporateLoading: false});

  onError = error => {
    if (error.message.toString().includes("Table not found by name")) {
      this.setState({ isLoading: false});
      return;
    }

    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Retrieving Loan Requests',
      dialogMessage: "There's an error retrieving records. Please contact administrator.",
      dialogDescription: error.message,
      isError: true,
      isLoading: false
    });
  };

  onCorporateError = error => {
    if (error.message.toString().includes("Table not found by name")) {
      this.setState({ isCorporateLoading: false});
      return;
    }

    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Retrieving Corporate Subscriptions.',
      dialogMessage: "There's an error retrieving records. Please contact administrator.",
      dialogDescription: error.message,
      isError: true,
      isCorporateLoading: false
    });
  };

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/dashboard");
  };

  viewSubscription = subscriptionId => this.props.history.push(`/pages/subscription/${subscriptionId}`);

  subscribe = subscriptionType => {
    this.props.history.push(`/pages/subscription-request-new`);
  };

  render = () => {
    const { dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, activeItem} = this.state;
    const { isLoading, subscriptions, isCorporateLoading, corporateSubscriptions} = this.state;

    const { t} = this.props;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <SingleGridLayout>

          <Segment basic textAlign={'right'}>
            <Button color={'blue'} onClick={this.subscribe}>
              <Icon name={'plus'} />
              { (subscriptions.length + corporateSubscriptions.length)===0 && <Fragment>{t('subscriptions-new')}</Fragment>}
              { (subscriptions.length + corporateSubscriptions.length)>0 && <Fragment>{t('subscriptions-additional')}</Fragment>}
            </Button>
          </Segment>

          { <Menu pointing secondary>
            <Menu.Item
              name='individual'
              active={activeItem === 'individual'}
              onClick={this.handleItemClick}
            >{t('subscriptions-individual')}</Menu.Item>

            <Menu.Item
              name='corporate'
              active={activeItem === 'corporate'}
              onClick={this.handleItemClick}
            >{t('subscriptions-corporate')}</Menu.Item>

          </Menu> }

          { activeItem==='individual' && <Fragment>
            <DataLoader isLoading={isLoading}
                        itemsLength={subscriptions.length}
                        notFoundMessage={t('subscriptions-individual-not-found')}
            />
            { !isLoading && subscriptions.length>0 &&
            <Segment padded>
              <Label color={'black'} attached={'top left'}>{ this.labels.individual.title}</Label>

              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('subscription-table-ind-full-name')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-ind-date')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-ind-nationality')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-ind-profession')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-ind-contact')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-ind-status')}</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    subscriptions.map(subscription => (
                        <Table.Row key={subscription.objectId}>
                          <Table.Cell>{ subscription.name }</Table.Cell>
                          <Table.Cell>{ new Date(subscription.created).toLocaleString() }</Table.Cell>
                          <Table.Cell>{ subscription.nationality}</Table.Cell>
                          <Table.Cell>{ subscription.profession }</Table.Cell>
                          <Table.Cell>
                            { subscription.contact_number }<br />
                            { subscription.email }
                          </Table.Cell>
                          <Table.Cell
                            negative={['rejected', 'subscription_rejected'].includes(subscription.status)}  positive={['approved', 'subscription_executed'].includes(subscription.status)}>
                            {SubscriptionService.translateStatus(subscription.status)} { ['rejected','approved'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.approval_rejection_date).toLocaleString()}</Fragment>}{ ['subscription_rejected','subscription_executed'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.subscription_approval_rejection_date).toLocaleString()}</Fragment>}
                            { subscription.status==='rejected' && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                            { ['subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                          </Table.Cell>
                          <Table.Cell>
                            <Button color={'blue'} onClick={() => this.viewSubscription(subscription.objectId)}>{t('button-view')}</Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )

                  }
                </Table.Body>
              </Table>
            </Segment>
            }
          </Fragment>}

          { activeItem==='corporate' && <Fragment>
            <DataLoader isLoading={isCorporateLoading}
                        itemsLength={corporateSubscriptions.length}
                        notFoundMessage={t('subscriptions-corporate-not-found')}
            />
            { !isCorporateLoading && corporateSubscriptions.length>0 &&
            <Segment padded>
              <Label color={'black'} attached={'top left'}>{ this.labels.corporate.title}</Label>

              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('subscription-table-corp-account-type')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-corp-name')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-corp-date')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-corp-nationality')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-corp-contact')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('subscription-table-corp-status')}</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    corporateSubscriptions.map(subscription => (
                        <Table.Row key={subscription.objectId}>
                          <Table.Cell>{ SubscriptionService.translateCorporateAccountType(subscription.corporate_account_type) }</Table.Cell>
                          <Table.Cell>{ subscription.name_of_company }</Table.Cell>
                          <Table.Cell>{ new Date(subscription.created).toLocaleString() }</Table.Cell>
                          <Table.Cell>{ subscription.place_of_incorporation}</Table.Cell>
                          <Table.Cell>
                            { subscription.contact_number }<br />
                            { subscription.email }
                          </Table.Cell>
                          <Table.Cell
                            negative={['rejected', 'subscription_rejected'].includes(subscription.status)}  positive={['approved', 'subscription_executed'].includes(subscription.status)}>
                            {SubscriptionService.translateStatus(subscription.status)} { ['rejected','approved'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.approval_rejection_date).toLocaleString()}</Fragment>}{ ['subscription_rejected','subscription_executed'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.subscription_approval_rejection_date).toLocaleString()}</Fragment>}
                            { subscription.status==='rejected' && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                            { ['subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                          </Table.Cell>
                          <Table.Cell>
                            <Button color={'blue'} onClick={() => this.viewSubscription(subscription.objectId)}>{t('button-view')}</Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )
                  }
                </Table.Body>
              </Table>
            </Segment>
            }
          </Fragment>}
        </SingleGridLayout>
      </Fragment>
    )
  }
}

export default withTranslation("translations")(SubscriptionsPage);