import React from "react";
import {Modal, Header, Button, Icon, Form, TextArea} from "semantic-ui-react";
import PropTypes from "prop-types";

export const AccessRequestDialog = ({t, open, onYesClick, onNoClick}) => {
  let descriptionData = {};

  const handleChange = (e, { name, value}) => descriptionData[name] = value;

  return (
    <Modal open={open}>
      <Header icon={'question circle outline'} color={'red'} content={t('start-access-request-title')} />
      <Modal.Content>
        <Form id={'description-form'} size='large'>
          <Form.Input
            name={'email'}
            label={t('start-access-request-email')}
            onChange={handleChange}
          />
          <Form.Field
            label={t('start-access-request-message')}
            name={'message'}
            control={TextArea}
            rows={10}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button name={'noBtn'} color='red' inverted onClick={onNoClick}>
          <Icon name='cancel' /> {t('button-cancel')}
        </Button>
        <Button name={'yesBtn'} color='green' inverted onClick={() => onYesClick(descriptionData)}>
          <Icon name='checkmark' /> {t('start-access-request')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

AccessRequestDialog.propTypes = {
  open: PropTypes.bool,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func
};