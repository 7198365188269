import React, {Component, Fragment} from "react";
import {Segment, Step, Grid, Button} from "semantic-ui-react";
import { SubscriptionCorporateSignatoryForm} from "./corporate-signatory";
import {SubscriptionDeclaration} from "../declaration";
import {SubscriptionCorporateInformationForm} from "./corporate-info";
import {SubscriptionService} from "../../../../services/subscription-service";
import approve from "approvejs/dist/approve";

import { withTranslation} from "react-i18next";

class SubscriptionCorporateForm extends Component {
  corporateInfo = {};
  corporateSign = {
  };

  subscription_metadata = {
    account_type: 'corporate',
    status: 'new'
  };

  state = {
    step: 1,
    isEditing: false,
    corporateInformation: this.corporateInfo,
    corporateSignatory: this.corporateSign,
    corporateInformationErrors: {},
    corporateSignatoryErrors: {}
  };

  componentDidMount = () => {
    if (this.props.isEditing) {
      let corpInfo = this.props.subscription;

      this.corporateSign = {
        signatory_name: corpInfo.signatory_name,
        signatory_contact_number: corpInfo.signatory_contact_number,
        signatory_email: corpInfo.signatory_email
      };

      this.corporateInfo = corpInfo;

      this.setState({ isEditing: true, corporateSignatory: this.corporateSign, corporateInformation: this.corporateInfo, isAdmin: this.props.isAdmin});
    } else {
      let corporateAccountType = localStorage.getItem('corporateAccountType');
      if (corporateAccountType) {
        this.corporateInformationHandleSelectorChange('corporate_account_type', corporateAccountType);
        localStorage.removeItem("corporateAccountType");
      }
    }

  };

  stepping = delta => {
    const {step} = this.state;
    this.setState({step: (step + delta)});
    window.scroll(0, 0);
  };

  upStep = () => {
    const {step} = this.state;

    if (step===1 && !this.doValidateCorporateInformation()) return;
    if (step===2 && !this.doValidateCorporateSignatory()) return;
    this.stepping(1);
  }

  downStep = () => this.stepping(-1);


  /* Corporate Information */
  corporateInformationHandleChange = (e, {name, value}) => {
    this.corporateInfo[name] = value;
    this.setState({ corporateInformation: this.corporateInfo});
  };

  corporateInformationHandleSelectorChange = (name, value) => {
    this.corporateInfo[name] = value;
    this.setState({ corporateInformation: this.corporateInfo});
  };

  /* Corporate Signatory */
  corporateSignatoryDataChange = (e, {name, value}) => {
    this.corporateSign[`signatory_${name}`] = value;
    this.setState({ corporateSignatory: this.corporateSign});
  };

  /* Validation */
  doValidateImpl = (value, rule, errors, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      errors[error_key] = false;
      return true;
    } else {
      errors[error_key] = error_message;
      return false;
    }
  };

  doValidateCorporateInformation = () => {
    const {
      name_of_company,
      nature_of_business,
      registered_address,
      mailing_address,
      contact_number,
      email,
      relation_to_usa,
      corporate_account_type,
      place_of_incorporation
    } = this.corporateInfo;

    let requiredRule = {
      required: true
    };

    let requiredEmailRule = {
      required: true, email: true
    };

    let errors = {};

    let isValid1 = this.doValidateImpl(name_of_company, requiredRule, errors, 'name_of_company_error', 'Please enter the name of your company.');
    let isValid2 = this.doValidateImpl(nature_of_business, requiredRule, errors, 'nature_of_business_error', "Please enter the nature of the company's business.");
    let isValid3 = this.doValidateImpl(registered_address, requiredRule, errors, 'registered_address_error', 'Please enter the registered address of your company.');
    let isValid4 = this.doValidateImpl(mailing_address, requiredRule, errors, 'mailing_address_error', 'Please enter the mailing address of your company.');
    let isValid5 = this.doValidateImpl(contact_number, requiredRule, errors, 'contact_number_error', 'Please enter your contact number.');
    let isValid6 = this.doValidateImpl(email, requiredEmailRule, errors, 'email_error', 'Please enter your email address.');
    let isValid7 = this.doValidateImpl(relation_to_usa, requiredRule, errors, 'relation_to_usa_error', 'Please state any relation the company has to the USA.');
    let isValid8 = this.doValidateImpl(corporate_account_type, requiredRule, errors, 'corporate_account_type_error', 'Please select the appropriate corporation type.');
    let isValid9 = this.doValidateImpl(place_of_incorporation, requiredRule, errors, 'place_of_incorporation_error', 'Please state the country where the company is incorporated.');

    this.setState({ corporateInformationErrors: errors});
    return isValid1 && isValid2 && isValid3 && isValid4 && isValid5 && isValid6 && isValid7 && isValid8 && isValid9;
  };

  doValidateCorporateSignatory = () => {
    const {signatory_name, signatory_contact_number, signatory_email} = this.corporateSign;

    let requiredRule = {
      required: true
    };

    let requiredEmailRule = {
      required: true, email: true
    };

    let errors = {};

    let isValid1 = this.doValidateImpl(signatory_name, requiredRule, errors, 'signatory_name_error', 'Please enter the name of the authorised signatory.');
    let isValid2 = this.doValidateImpl(signatory_contact_number, requiredRule, errors, 'signatory_contact_number_error', 'Please enter the contact number of the authorised signatory.');
    let isValid3 = this.doValidateImpl(signatory_email, requiredEmailRule, errors, 'signatory_email_error', 'Please enter the email address of the authorised signatory.');

    this.setState({ corporateSignatoryErrors: errors});
    return isValid1 && isValid2 && isValid3;
  };

  /* Saving */
  doSubmit = () =>  {
    const {step} = this.state;
    if (step===2 && !this.doValidateCorporateSignatory()) return;
    SubscriptionService.subscribe({ ...this.subscription_metadata, ...this.corporateInfo, ...this.corporateSign}, this.onSubmitSuccess, this.props.onError);
  }

  onSubmitSuccess = savedSubscription => {
    console.log("Done. Check Backendless DB");
    const { isEditing, isAdmin } = this.state;

    if (isEditing)
      if (isAdmin)
        this.props.history.push(`/pages/admin/subscription/${savedSubscription.objectId}`);
      else
        this.props.history.push(`/pages/subscription/${savedSubscription.objectId}`);
    else {
      SubscriptionService.notifyAdminOnCreate(savedSubscription.objectId, console.log, console.log);
      this.props.history.push('/pages/subscriptions');
    }
  };

  render = () => {
    const {isEditing, step, corporateInformation, corporateInformationErrors, corporateSignatory, corporateSignatoryErrors} = this.state;

    const { t} = this.props;

    return (
      <Fragment>
        <Grid>
          <Grid.Column width={5}>
            <Step.Group ordered vertical fluid>
              <Step completed={step >= 2} disabled={step < 1}>
                <Step.Content>
                  <Step.Title>{t('subscription-form-corp-step1')}</Step.Title>
                </Step.Content>
              </Step>
              <Step completed={step >= 3} disabled={step < 2}>
                <Step.Content>
                  <Step.Title>{t('subscription-form-corp-step2')}</Step.Title>
                </Step.Content>
              </Step>
              { !isEditing && <Step disabled={step < 4}>
                <Step.Content>
                  <Step.Title>{t('subscription-form-corp-step3')}</Step.Title>
                </Step.Content>
              </Step> }
            </Step.Group>
          </Grid.Column>
          <Grid.Column width={11}>
            { step===1 &&
            <Segment >
              <SubscriptionCorporateInformationForm t={t}
                                                    data={corporateInformation}
                                                    errors={corporateInformationErrors}
                                                    handleChange={this.corporateInformationHandleChange}
                                                    handleSelectorChange={this.corporateInformationHandleSelectorChange} />
            </Segment>
            }

            { step===2 && <SubscriptionCorporateSignatoryForm t={t} data={corporateSignatory} errors={corporateSignatoryErrors} onDataChange={this.corporateSignatoryDataChange} /> }
            { !isEditing && <Fragment>
              { step===3 && <SubscriptionDeclaration t={t}/> }
              { step !== 3 && <Segment clearing>
                { step!==1 && <Button color={'red'} onClick={this.downStep}>{t('button-back')}</Button> }
                <Button floated={"right"} color={'black'} onClick={this.upStep}>{t('button-next')}</Button>
              </Segment>}

              { step===3 && <Segment padded>
                <Button color={'red'} onClick={this.downStep}>{t('button-back')}</Button>
                <Button floated={"right"} color={'blue'} onClick={this.doSubmit}>{t('button-submit')}</Button>
              </Segment>}
            </Fragment>}

            { isEditing && <Fragment>
              { step !== 2 && <Segment clearing>
                <Button floated={"right"} color={'black'} onClick={this.upStep}>{t('button-next')}</Button>
              </Segment>}
              { step===2 && <Segment padded>
                { step!==1 && <Button color={'red'} onClick={this.downStep}>{t('button-back')}</Button> }
                <Button floated={"right"} color={'blue'} onClick={this.doSubmit}>{t('button-save')}</Button>
              </Segment>}
            </Fragment>}
          </Grid.Column>
        </Grid>
      </Fragment>

    );
  };
};

export default withTranslation("translations")(SubscriptionCorporateForm)