import React, {Component, Fragment} from 'react';
import {Button, Form, Label, Message, Segment, Table} from "semantic-ui-react";
import {MessageDialog} from "../../../components/message-dialog";
import {SubscriptionService} from "../../../services/subscription-service";
import {FileViewerDialog} from "../../../components/file-viewer-dialog";

import {withTranslation} from "react-i18next";

class SubscriptionDocumentsComponent extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    subscriptions: [],
    subscriptionDescription: '',
    subscriptionFile: []
  };

  componentDidMount = () => SubscriptionService.getDocuments(this.props.subscriptionId, 'subscription', true, this.onListSuccess, this.onError);

  onListSuccess = listItems => this.setState({subscriptions: listItems, isLoading: false});

  onError = error => {
    if (!(error.message.includes('Table not found') || error.message.includes("Column 'attachments' does not exist"))) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving Subscription Documents',
        dialogDescription: error.message,
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => this.setState({dialogOpen: false});

  viewSubscription = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  /* Subscription Form */
  onDataChange = (e, {name, value}) => this.setState({[name]: value});
  onFileChange = (e, {name}) => this.setState({[name]: e.target.files});

  uploadSubscription = () => {
    const {subscriptionDescription, subscriptionFile} = this.state;
    SubscriptionService.uploadDocument(this.props.subscriptionId, 'subscription', subscriptionFile, subscriptionDescription, 'user', this.onSaveSubscriptionSuccess, this.onUploadSubscriptionError);

    document.getElementById("subscription-form").reset();
  };

  onSaveSubscriptionSuccess = subscription => {
    SubscriptionService.getDocuments(this.props.subscriptionId, 'subscription', true, this.onListSuccess, this.onError);
    this.props.onFileUpload();
  }

  onUploadSubscriptionError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading Subscription Documents',
    dialogMessage: "There's an error uploading or saving Subscription. Documents Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, subscriptions} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl} = this.state;
    const {t} = this.props;

    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('subscription-documents-upload-title')}</Label>
          <Form id={'subscription-form'}>
            <Form.Input
              label={t('subscription-documents-upload-description')}
              name={'subscriptionDescription'}
              onChange={this.onDataChange}
            />
            <Form.Input
              label={t('subscription-documents-upload-document')}
              name={'subscriptionFile'}
              type={'file'}
              accept={'.pdf'}
              onChange={this.onFileChange}
            />
            <Button onClick={this.uploadSubscription} color={'blue'}>{t('subscription-documents-upload')}</Button>
          </Form>
        </Segment>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('subscription-documents-upload-title')}</Label>
          {subscriptions.length === 0 && <Message negative>
            <Message.Header>
              No Data Found
            </Message.Header>
            <p>
              There is no Subscription Documents uploaded
            </p>
          </Message>}
          {subscriptions.length > 0 && <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Upload Date</Table.HeaderCell>
                <Table.HeaderCell>Uploaded By</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>View</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                subscriptions.map(subscription => (
                    <Table.Row key={subscription.objectId}>
                      <Table.Cell>{subscription.document_description}</Table.Cell>
                      <Table.Cell>{new Date(subscription.created).toLocaleString()}</Table.Cell>
                      <Table.Cell>{SubscriptionService.translateUploadedBy(subscription.uploaded_by_role)}</Table.Cell>
                      <Table.Cell textAlign={"center"}>
                        <Button color={'blue'} onClick={() => this.viewSubscription(subscription.fileURL)}>View</Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )

              }
            </Table.Body>
          </Table>}
        </Segment>
      </Fragment>

    )
  }
}

export default withTranslation("translations")(SubscriptionDocumentsComponent);