import React, {Component} from 'react';
import {SubscriptionService} from "../../services/subscription-service";
import {SubscriptionView} from "./components/subscription-view";
import SingleGridLayout from "../../layout/single-grid";
import {SignatoryView} from "./components/signatory-view";
import {SubscriptionCorporateView} from "./components/subscription-corporate-view";
import {Button, Menu, Segment} from "semantic-ui-react";
import SubscriptionDocumentsComponent from "./components/subscription-documents";

import {withTranslation} from "react-i18next";

class SubscriptionViewPage extends Component {
  state = {
    subscription: {},
    activeItem: 'subscription-documents',
    isLoading: true
  };

  componentDidMount = () => {
    const { subscriptionId} = this.props.match.params;
    SubscriptionService.get(subscriptionId, (subscription) => this.setState({subscription: subscription, isLoading: false}), this.onError)
  };

  onError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Retrieving Subscription',
    dialogMessage: this.props.t('general-error-retrieving-record'),
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/admin/subscriptions");
  };

  edit = () => {
    const {subscription} = this.state;
    this.props.history.push({
      pathname: '/pages/subscription-request/don-care',
      state: {subscription: subscription}
    });
  };

  render = () => {
    const {subscription, activeItem, isLoading} = this.state;
    if (typeof subscription === "undefined") return null;

    const isIndividual = subscription.account_type === 'individual';
    const {t} = this.props;

    return (

      <SingleGridLayout>
        { !isLoading && isIndividual && <SubscriptionView t={t} subscription={subscription} edit={this.edit}  /> }
        { !isLoading && !isIndividual && <SubscriptionCorporateView t={t} subscription={subscription} edit={this.edit}  /> }
        { !isLoading && !isIndividual && <SignatoryView subscription={subscription} />}
        { subscription.status==='new' && !isLoading && !isIndividual && <Segment textAlign={"right"}>
          <Button color={"blue"} onClick={this.edit}>{t('button-edit')}</Button>
        </Segment>}

        { !isLoading && <Menu pointing secondary>
          <Menu.Item
            name='subscription-documents'
            active={true}
          >{t('subscription-documents-title')}</Menu.Item>
        </Menu>}
        {!isLoading && activeItem==='subscription-documents' && <SubscriptionDocumentsComponent subscriptionId={subscription.objectId} />}

      </SingleGridLayout>
    )
  }
}

export default withTranslation("translations")(SubscriptionViewPage);