import React, {Fragment} from "react";
import {Button, Label, Segment, Table} from "semantic-ui-react";
import {SubscriptionService} from "../../../services/subscription-service";

const CreateRow = (props) => {
  return (<Table.Row>
      <Table.Cell>{props.title}</Table.Cell>
      <Table.Cell className={'multiline'}>{props.value}</Table.Cell>
    </Table.Row>
  );
};

export const SubscriptionCorporateView = (props) => {
  let { subscription, t} = props;

  return (
    <Fragment>
      <Segment padded>
        <Label color={'black'} attached={'top left'}>{t('subscription-form-ind-view-title')}</Label>

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{t('subscription-table-corp-status')}</Table.Cell>
              <Table.Cell
                negative={subscription.status === 'rejected' || subscription.status === 'subscription_rejected'}  positive={subscription.status === 'approved'}>
                {SubscriptionService.translateStatus(subscription.status)} { ['rejected','approved'].includes(subscription.status) && new Date(subscription.approval_rejection_date).toLocaleString()}
                { subscription.status==='rejected' && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                { ['subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Fragment><br/>{subscription.subscription_approval_rejection_reason}</Fragment>}
                { props.editStatusComments && ['rejected', 'subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Fragment><br/><br/><Button color={'blue'} onClick={props.editStatusComments}>Edit Status Comments</Button></Fragment>}
              </Table.Cell>
            </Table.Row>
            <CreateRow title={t('subscription-form-corp-account-type')} value={SubscriptionService.translateCorporateAccountType(subscription.corporate_account_type)}/>
            <CreateRow title={t('subscription-form-corp-company-name')} value={subscription.name_of_company}/>
            <CreateRow title={t('subscription-form-corp-nature')} value={subscription.nature_of_business}/>
            <CreateRow title={t('subscription-form-corp-registered-address')} value={subscription.registered_address}/>
            <CreateRow title={t('subscription-form-corp-mailing-address')} value={subscription.mailing_address}/>
            <CreateRow title={t('subscription-form-corp-country')} value={subscription.place_of_incorporation}/>
            <CreateRow title={t('subscription-form-corp-contact')} value={subscription.contact_number}/>
            <CreateRow title={t('subscription-form-corp-email')} value={subscription.email}/>
            <CreateRow title={t('subscription-form-corp-usa')} value={subscription.relation_to_usa}/>
            <CreateRow title={t('subscription-form-comments')} value={subscription.comments}/>
          </Table.Body>
        </Table>

        { subscription.status==='new' && <Segment basic textAlign={"right"}>
          <Button color={"blue"} onClick={props.edit}>{t('button-edit')}</Button>
        </Segment>}
      </Segment>
    </Fragment>
  );
};