import React, {Component, Fragment} from 'react';
import {Button, Label, Pagination, Segment, Table} from "semantic-ui-react";
import SingleGridLayout from '../../../layout/single-grid';
import {LoanRequestService} from "../../../services/loan-request-service";
import {MessageDialog} from "../../../components/message-dialog";
import DataLoader from "../../../components/data-loader";
import {StringUtils, Thousander} from "../../../components/strings";

export default class AdminLoanRequestsPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    loanRequests: [],
    isLoading: true,
    processedLoanRequests: [],
    processedIsLoading: true,
    sortColumn: 'created',
    sortOrder: 'DESC',
    activePage: 1,
    totalPages: 1
  };

  componentDidMount = () => {
    LoanRequestService.list("status='new'", '', 1, this.onNewListSuccess, this.onError);
    this.loadProcessDataPaginator();
  };

  onNewListSuccess = listItems => this.setState({loanRequests: listItems, isLoading: false});

  loadProcessDataPaginator = () => LoanRequestService.listPaginator("status<>'new'", (totalPages) => this.setState({totalPages}, this.loadProcessedData), this.onError);

  loadProcessedData = () => {
    const {activePage} = this.state;
    LoanRequestService.list("status<>'new'", this.generateSortString(), activePage, this.onProcessedListSuccess, this.onError);
  }

  onProcessedListSuccess = listItems => this.setState({processedLoanRequests: listItems, processedIsLoading: false});

  onError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Retrieving Loan Requests',
    dialogMessage: "There's an error retrieving records. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onMessageDialogOk = (e, {name}) => {
    const {isError} = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/admin/dashboard");
  };

  viewLoanRequest = loanRequestId => this.props.history.push(`/pages/admin/loan-request/${loanRequestId}`);

  sort = columnName => {
    const { sortColumn, sortOrder} = this.state;

    if (sortColumn===columnName) {
      this.setState({
        activePage: 1,
        sortOrder: sortOrder==='DESC' ? 'ASC' : 'DESC'
      }, this.loadProcessedData);
    } else {
      this.setState({
        activePage: 1,
        sortColumn: columnName,
        sortOrder: 'ASC'
      }, this.loadProcessedData);
    }
  };

  formatSort = sortColumn => {
    switch (sortColumn) {
      case 'name': return 'Name of Borrower / Referrer';
      case 'created': return 'Request Date';
      case 'loan_type': return 'Borrower Type';
      case 'stock_code': return 'Listed Company / Stock Code';
      case 'loan_amount': return 'Loan Amount (USD)';
      default: return StringUtils.capitalize(sortColumn);
    }
  };

  formatSortOrder = sortOrder => {
    return sortOrder==='DESC' ? 'descending' : 'ascending';
  };

  generateSortString = () => {
    const { sortColumn, sortOrder} = this.state;

    return `${sortColumn} ${sortOrder}`;
  };

  /* Pagination */
  handlePaginationChange = (e, { activePage }) => this.setState({ activePage }, this.loadProcessedData)

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError} = this.state;
    const {isLoading, loanRequests, processedIsLoading, processedLoanRequests} = this.state;
    const {sortColumn, sortOrder} = this.state;
    const {activePage, totalPages} = this.state;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <SingleGridLayout isAdmin>
          <DataLoader isLoading={isLoading}
                      itemsLength={loanRequests.length}
                      notFoundMessage={'There are no New Loan Requests.'}
          />

          <DataLoader isLoading={processedIsLoading}
                      itemsLength={processedLoanRequests.length}
                      notFoundMessage={'There are no Processed Loan Requests.'}
          />

          {!isLoading && loanRequests.length > 0 &&
          <Segment padded>
            <Label color={'black'} attached={'top left'}>New Loan Requests</Label>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name of Borrower / Referrer</Table.HeaderCell>
                  <Table.HeaderCell>Request Date</Table.HeaderCell>
                  <Table.HeaderCell>Borrower Type</Table.HeaderCell>
                  <Table.HeaderCell>Listed Company / Stock Code</Table.HeaderCell>
                  <Table.HeaderCell>#Shares to Pledge</Table.HeaderCell>
                  <Table.HeaderCell>Loan Amount (USD)</Table.HeaderCell>
                  <Table.HeaderCell>Purpose</Table.HeaderCell>
                  <Table.HeaderCell>Refinancing</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  loanRequests.map(loanRequest => (
                      <Table.Row key={loanRequest.objectId}>
                        <Table.Cell>
                          { loanRequest.name } <br />
                          ({ StringUtils.capitalize(loanRequest.borrower_referrer) })
                        </Table.Cell>
                        <Table.Cell>{ new Date(loanRequest.created).toLocaleString() }</Table.Cell>
                        <Table.Cell>{ StringUtils.capitalize(loanRequest.loan_type)}</Table.Cell>
                        <Table.Cell>{ StringUtils.upCase(loanRequest.stock_code) }</Table.Cell>
                        <Table.Cell textAlign={'right'}>{ Thousander.format(loanRequest.pledge_number) }</Table.Cell>
                        <Table.Cell textAlign={'right'}>{ Thousander.format(loanRequest.loan_amount) }</Table.Cell>
                        <Table.Cell>{ StringUtils.denull(loanRequest.loan_purpose)}</Table.Cell>
                        <Table.Cell>{ StringUtils.capitalize(StringUtils.denull(loanRequest.loan_is_refinancing))}</Table.Cell>
                        <Table.Cell>
                          <Button floated={'right'} color={'blue'}
                                  onClick={() => this.viewLoanRequest(loanRequest.objectId)}>Details</Button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )

                }
              </Table.Body>
            </Table>
          </Segment>
          }

          {!processedIsLoading && processedLoanRequests.length > 0 &&
          <Segment padded>
            <Label color={'black'} attached={'top left'}>Processed Loan Requests</Label>
            <Segment basic textAlign={"right"}>
              { sortColumn==='' && <Fragment>No Sorting</Fragment>}
              { sortColumn!=='' && <Fragment>Sorted by {this.formatSort(sortColumn)} {this.formatSortOrder(sortOrder)}</Fragment>}
            </Segment>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell onClick={() => this.sort('name')}>Name of Borrower / Referrer</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('created')}>Request Date</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('loan_type')}>Borrower Type</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('stock_code')}>Listed Company / Stock Code</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('pledge_number')}>#Shares to Pledge</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('loan_amount')}>Loan Amount (USD)</Table.HeaderCell>
                  <Table.HeaderCell>Purpose</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('loan_is_refinancing')}>Refinancing</Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.sort('status')}>Status</Table.HeaderCell>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  processedLoanRequests.map(loanRequest => (
                      <Table.Row key={loanRequest.objectId}>
                        <Table.Cell>
                          { loanRequest.name } <br />
                          ({ StringUtils.capitalize(loanRequest.borrower_referrer) })
                        </Table.Cell>
                        <Table.Cell>{ new Date(loanRequest.created).toLocaleString() }</Table.Cell>
                        <Table.Cell>{ StringUtils.capitalize(loanRequest.loan_type)}</Table.Cell>
                        <Table.Cell>{ StringUtils.upCase(loanRequest.stock_code) }</Table.Cell>
                        <Table.Cell textAlign={'right'}>{ Thousander.format(loanRequest.pledge_number) }</Table.Cell>
                        <Table.Cell textAlign={'right'}>{ Thousander.format(loanRequest.loan_amount) }</Table.Cell>
                        <Table.Cell>{ StringUtils.denull(loanRequest.loan_purpose)}</Table.Cell>
                        <Table.Cell>{ StringUtils.capitalize(StringUtils.denull(loanRequest.loan_is_refinancing))}</Table.Cell>
                        <Table.Cell
                          negative={['rejected','loan_rejected'].includes(loanRequest.status)}  positive={['loan_executed','approved'].includes(loanRequest.status)}>
                          {LoanRequestService.translateStatus(loanRequest.status)} { ['rejected','approved'].includes(loanRequest.status) && new Date(loanRequest.approval_rejection_date).toLocaleString()}{ ['loan_rejected','loan_executed'].includes(loanRequest.status) && new Date(loanRequest.loan_approval_rejection_date).toLocaleString()}
                          { loanRequest.status==='indicative_terms_sent' && <Fragment><br/><b>Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.indicative_terms_expiry_date)}</Fragment>}
                          { loanRequest.status==='indicative_terms_sent' && <Fragment><br/><b>Extended Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.indicative_terms_extended_expiry_date)}</Fragment>}
                          { loanRequest.status==='term_sheet' && <Fragment><br/><b>Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.term_sheet_expiry_date)}</Fragment>}
                          { loanRequest.status==='term_sheet' && <Fragment><br/><b>Extended Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.term_sheet_extended_expiry_date)}</Fragment>}
                          { ['kyc_requested', 'kyc_incomplete'].includes(loanRequest.status) && <Fragment><br/><b>Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.kyc_expiry_date)}</Fragment>}
                          { ['kyc_requested', 'kyc_incomplete'].includes(loanRequest.status)  && <Fragment><br/><b>Extended Expiry Date</b> {StringUtils.toLocaleDateString(loanRequest.kyc_extended_expiry_date)}</Fragment>}
                          { loanRequest.status==='rejected' && <Fragment><br/>{loanRequest.approval_rejection_reason}</Fragment>}
                          { ['loan_rejected', 'loan_executed'].includes(loanRequest.status) && <Fragment><br/>{loanRequest.loan_approval_rejection_reason}</Fragment>}
                        </Table.Cell>
                        <Table.Cell>
                          <Button floated={'right'} color={'blue'}
                                  onClick={() => this.viewLoanRequest(loanRequest.objectId)}>Details</Button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )

                }
              </Table.Body>
            </Table>

            <Segment basic textAlign={"right"}>
              <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  size='mini'
                  siblingRange={1}
                  totalPages={totalPages}
                  ellipsisItem={undefined}
                  firstItem={undefined}
                  lastItem={undefined}
                  prevItem={undefined}
                  nextItem={undefined}
              />
            </Segment>
          </Segment>

          }
        </SingleGridLayout>
      </Fragment>

    )
  }
}