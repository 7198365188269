import React, {Component} from 'react';
import SingleGridLayout from '../layout/single-grid';
import { Segment } from "semantic-ui-react";
import {UserService} from "../services/user-service";

const title = 'Message!';

export default class LogoutPage extends Component {
  state = {
    message: "Logging out...."
  };
  
  componentDidMount = () => UserService.logout(() => {
    localStorage.removeItem('userId');
    this.props.history.push("/pages/start")
  });

  render = () => {
    const {message} = this.state;
    
    return (
      <SingleGridLayout title={title}>
        <Segment color={'red'}>
          {message}
        </Segment>
      </SingleGridLayout>
    )
  }
}