export const Thousander = new Intl.NumberFormat('en-US', {});

export const StringUtils = {
  capitalize: string => string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : string,
  upCase: string => string[0] ? `${string.toString().toUpperCase()}` : string,
  denull: string => (string===null ||typeof string==='undefined') ? '' : string,
  toISOString: (date) => {
    if (date===null ||typeof date==='undefined') return '';
    return new Date(date).toISOString().split('T')[0];
  },
  toLocaleDateString: (date) => {
    if (date===null ||typeof date==='undefined') return '';
    return new Date(date).toLocaleDateString();
  }
};