import React, {Fragment} from "react";
import {Segment, Header, Label, List, Button} from "semantic-ui-react";

export const SubscriptionQualification = (props) => {
  const {subscriptionType, t} = props;

  return (
    <Fragment>
      <Segment color={'black'}>
        <Header size={'small'}>{ t('qualification-title')}</Header>
      </Segment>

      { ['all', 'individual'].includes(subscriptionType) && <Segment padded>
        <Label color={'black'} attached={'top left'}>{t('qualification-individual-title')}</Label>
        <List bulleted>
          <List.Item>
            {t('qualification-individual-line1')}
          </List.Item>
          <List.Item>
            {t('qualification-individual-line2')}
          </List.Item>
          <List.Item>
            {t('qualification-individual-line3')}
          </List.Item>
          <List.Item>
            {t('qualification-individual-line4')}
          </List.Item>
        </List>
        <Button attached={'top'} color={'blue'} onClick={() => props.onSubscribe('individual', '')}>{t('subscription-button')}</Button>
      </Segment>}

      { ['all', 'corporate'].includes(subscriptionType) && <Fragment>
        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('qualification-corporation-title')}</Label>
          <List bulleted>
            <List.Item>
              {t('qualification-corporation-line1')}
            </List.Item>
            <List.Item>
              {t('qualification-corporation-line2')}
            </List.Item>
          </List>
          <Button attached={'top'} color={'blue'} onClick={() => props.onSubscribe('corporate', 'corporation')}>{t('subscription-button')}</Button>
        </Segment>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('qualification-partnership-title')}</Label>
          <p>{t('qualification-partnership-line1')}</p>
          <Button attached={'top'} color={'blue'} onClick={() => props.onSubscribe('corporate', 'partnership')}>{t('subscription-button')}</Button>
        </Segment>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('qualification-trustee-title')}</Label>
          <List bulleted>
            <List.Item>
              {t('qualification-trustee-line1')}
            </List.Item>
            <List.Item>
              {t('qualification-trustee-line2')}
            </List.Item>
            <List.Item>
              {t('qualification-trustee-line3')}
            </List.Item>
          </List>
          <Button attached={'top'} color={'blue'} onClick={() => props.onSubscribe('corporate', 'trust')}>{t('subscription-button')}</Button>
          </Segment>
        </Fragment>}
      </Fragment>
  )
};