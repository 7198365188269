import React, {Component, Fragment} from 'react';
import SingleGridLayout from '../../../layout/single-grid';
import {Button, Label, Segment, Table, Form, Message, TextArea, Menu} from "semantic-ui-react";
import {LoanRequestService} from "../../../services/loan-request-service";
import {MessageDialog} from "../../../components/message-dialog";
import DataLoader from "../../../components/data-loader";
import {StringUtils} from "../../../components/strings";
import {ConfirmationDialog} from "../../../components/confirmation-dialog";
import {FileViewerDialog} from "../../../components/file-viewer-dialog";
import ProfileViewer from "../../profile/components/view";
import AdminLoanKycDocumentsComponent from "./components/kyc-documents";
import AdminLoanDocumentsComponent from "./components/loan-documents";
import AdminLoanInternalDocumentsComponent from "./components/internal-documents";
import DataConfirmationDialog from "../../../components/data-confirmation-dialog";
import {UserService} from "../../../services/user-service";
import {ProcessorSelector} from "./components/form/processor-selector";

export default class AdminLoanRequestViewPage extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    loanRequest: {},
    indicative_terms: '',
    activeTermSheet: {},
    signedTermSheet: {},
    isLoading: true,
    confirmDialogOpen: false,
    confirmDialogMessage: '',
    confirmDialogYesClick: () => {},
    activeItem: 'details',
    dataConfirmDialogOpen: false,
    dataConfirmDialogTitle: '',
    dataConfirmDialogDescription: '',
    dataConfirmDialogInitialValue: '',
    dataConfirmDialogOnYesClick: () => {},
    kycType: 'charismatic'
  };

  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  onChange = (e, { name, value }) => this.setState({ [name]: value });

  componentDidMount = () => {
    const {loanRequestId} = this.props.match.params;
    LoanRequestService.get(loanRequestId, this.onGetSuccess, this.onError)
  };

  onGetSuccess = loanRequest => {
    this.setState({
      loanRequest: loanRequest,
      indicative_terms: loanRequest.indicative_terms,
      indicative_terms_expiry_date: loanRequest.indicative_terms_expiry_date,
      indicative_terms_extended_expiry_date: loanRequest.indicative_terms_extended_expiry_date,
      term_sheet_expiry_date: loanRequest.term_sheet_expiry_date,
      term_sheet_extended_expiry_date: loanRequest.term_sheet_extended_expiry_date,
      kyc_expiry_date: loanRequest.kyc_expiry_date,
      kyc_extended_expiry_date: loanRequest.kyc_extended_expiry_date
    });
    LoanRequestService.getTermSheets(loanRequest.objectId, this.onGetTermSheetsSuccess, this.onGetDocumentsError);
  };

  onGetTermSheetsSuccess = termSheets => {
    termSheets.forEach(termSheet => {
      if (termSheet.document_type === 'term-sheet') this.setState({activeTermSheet: termSheet});
      if (termSheet.document_type === 'signed-term-sheet') this.setState({signedTermSheet: termSheet});
    });
    this.setState({isLoading: false});
  };

  onGetDocumentsError = error => this.setState({isLoading: false});

  onError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Retrieving Loan Request',
    dialogMessage: "There's an error retrieving the requested record. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onMessageDialogOk = (e, {name}) => {
    const {isError} = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/admin/loan-requests");
  };

  /* Approve / Reject */
  showApproveConfirmationDialog = () => this.setState({
    confirmDialogOpen: true,
    confirmDialogMessage: 'Do you want to approve this Loan Request for further processing?',
    confirmDialogYesClick: () => this.onConfirmDialogYesClick('approve', '')
  });

  showRejectConfirmationDialog = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Reject Loan Request',
    dataConfirmDialogDescription: 'What is the Reason for Rejection?',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick('reject', descriptionData.description)
  });


  /* Approve / Reject Application */
  showApproveApplicationConfirmationDialog = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Execute Loan',
    dataConfirmDialogDescription: 'Enter your notes for this Loan execution.',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick('execute_loan', descriptionData.description)
  });

  showRejectApplicationConfirmationDialog = () => this.setState({
    dataConfirmDialogOpen: true,
    dataConfirmDialogTitle: 'Reject Loan Application',
    dataConfirmDialogDescription: 'What is the Reason for rejecting this request as a Loan?',
    dataConfirmDialogOnYesClick: descriptionData => this.onConfirmDialogYesClick('reject_loan', descriptionData.description)
  });

  onConfirmDialogYesClick = (action, reason) => {
    const {loanRequest} = this.state;

    let data = {};

    switch (action) {
      case 'approve': data = {
        objectId: loanRequest.objectId,
        status: 'approved',
        approval_rejection_date: new Date(),
        approval_rejection_reason: reason
      }; break;
      case 'reject': data = {
        objectId: loanRequest.objectId,
        status: 'rejected',
        approval_rejection_date: new Date(),
        approval_rejection_reason: reason
      }; break;
      case 'execute_loan': data = {
        objectId: loanRequest.objectId,
        status: 'loan_executed',
        loan_approval_rejection_date: new Date(),
        loan_approval_rejection_reason: reason
      }; break;
      case 'reject_loan': data = {
        objectId: loanRequest.objectId,
        status: 'loan_rejected',
        loan_approval_rejection_date: new Date(),
        loan_approval_rejection_reason: reason
      }; break;
      default: break;
    }

    LoanRequestService.save(data, this.loanRequestUpdated, this.onError);
    this.setState({confirmDialogOpen: false, dataConfirmDialogOpen: false});
  };

  onConfirmDialogNoClick = () => this.setState({confirmDialogOpen: false});

  onDataConfirmDialogNoClick = () => this.setState({dataConfirmDialogOpen: false});

  /* Indicative Financing Terms */
  handleChange = (e, {name, value}) => this.setState({[name]: value});

  doSendIndicativeTerms = () => {
    const {loanRequest, indicative_terms, indicative_terms_expiry_date, indicative_terms_extended_expiry_date} = this.state;

    let data = {
      objectId: loanRequest.objectId,
      indicative_terms: indicative_terms,
      indicative_terms_date: new Date(),
      status: 'indicative_terms_sent'
    };

    if (indicative_terms_expiry_date) data['indicative_terms_expiry_date'] = new Date(indicative_terms_expiry_date);
    if (indicative_terms_extended_expiry_date) data['indicative_terms_extended_expiry_date'] = new Date(indicative_terms_extended_expiry_date);

    LoanRequestService.save(data, this.indicativeTermsUpdated, this.onError)
  };

  indicativeTermsUpdated = loanRequest => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Indicative Financing Terms Sent',
      dialogMessage: "Indicative Financing Terms saved successfully",
      isError: false,
      loanRequest: loanRequest
    });

    UserService.getUser(loanRequest.ownerId,
      (user) => {
        let subject = 'Indicative Financing Terms Sent';
        let message = 'Please review the Indicative Financing Terms set in the following loan request..';

        LoanRequestService.notifyUser(user, loanRequest.objectId, subject, message, console.log, console.log);
      }, console.log);
  };

  /* Term Sheet */
  onFileChange = (e, {name}) => this.setState({[name]: e.target.files});

  uploadTermSheet = () => {
    const {loanRequest, term_sheet} = this.state;

    if (loanRequest && loanRequest.objectId && term_sheet) {
      LoanRequestService.uploadDocument(loanRequest.objectId, term_sheet, 'term-sheet', 'Term Sheet', null, null, this.termSheetUploaded, this.termSheetUploadError)
    }
  };

  termSheetUploaded = (termSheet, relationCount) => {
    const {loanRequest} = this.state;
    LoanRequestService.getTermSheets(loanRequest.objectId, this.onGetTermSheetsSuccess, this.onError);

    LoanRequestService.save({
      objectId: loanRequest.objectId,
      status: 'term_sheet'
    }, this.loanRequestUpdated, this.onError)
  };

  loanRequestUpdated = loanRequest => {
    this.setState({loanRequest: loanRequest});

    UserService.getUser(loanRequest.ownerId,
      (user) => {
        let subject = '';
        let message = '';

        if (loanRequest.status==='rejected') {
          subject = 'Loan Request Rejected';
            message = "Your loan request has been rejected. This is because:- " + loanRequest.approval_rejection_reason
        }

        if (loanRequest.status==='approved') {
          subject = 'Loan Request Approved';
          message = "Your loan request has been approved for processing."
        }

        if (loanRequest.status==='term_sheet') {
          subject = 'Term Sheet Available';
          message = "The term sheet for your loan request is now available for your signature."
        }

        if (loanRequest.status==='loan_rejected') {
          subject = 'Loan Application Rejected';
          message = "Your application for a loan has been rejected. This is because:- " + loanRequest.loan_approval_rejection_reason
        }

        if (loanRequest.status==='loan_executed') {
          //subject = 'Loan Application Approved';
          //message = "Your application for a loan has been approved."
        }

        if (subject!=='') LoanRequestService.notifyUser(user, loanRequest.objectId, subject, message, console.log, console.log);
      }, console.log);
  };

  termSheetUploadError = error => {
    console.log(error);
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Uploading Term Sheet',
      dialogMessage: "There's an error uploading the Term Sheet. Please contact administrator.",
      dialogDescription: error.message,
      isError: true,
      isLoading: false
    });
  };

  viewTermSheet = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  /* Term Sheet Expiry Date */
  setTermSheetExpiryDates = () => {
    const {loanRequest, term_sheet_expiry_date, term_sheet_extended_expiry_date} = this.state;

    let data = {
      objectId: loanRequest.objectId
    };

    if (term_sheet_expiry_date) data['term_sheet_expiry_date'] = new Date(term_sheet_expiry_date);
    if (term_sheet_extended_expiry_date) data['term_sheet_extended_expiry_date'] = new Date(term_sheet_extended_expiry_date);

    LoanRequestService.save(data, this.termSheetExpiryDatesUpdated, this.onError)
  };

  termSheetExpiryDatesUpdated = loanRequest => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Term Sheet Updated',
      dialogMessage: "The Expiry Dates for the Term Sheet offer have been updated.",
      isError: false,
      loanRequest: loanRequest
    });
  };

  /* KCY Expiry Date */
  setKycExpiryDates = () => {
    const {loanRequest, kyc_expiry_date, kyc_extended_expiry_date} = this.state;

    let data = {
      objectId: loanRequest.objectId
    };

    if (kyc_expiry_date) data['kyc_expiry_date'] = new Date(kyc_expiry_date);
    if (kyc_extended_expiry_date) data['kyc_extended_expiry_date'] = new Date(kyc_extended_expiry_date);

    LoanRequestService.save(data, this.kycExpiryDatesUpdated, this.onError)
  };

  kycExpiryDatesUpdated = loanRequest => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'KYC Updated',
      dialogMessage: "The Expiry Dates for KYC have been updated.",
      isError: false,
      loanRequest: loanRequest
    });
  };

  requestKYC = () => {
    const {loanRequest, kycType} = this.state;
    LoanRequestService.save({
      objectId: loanRequest.objectId,
      kyc_type: kycType,
      status: 'kyc_requested'
    }, this.onKycRequestSuccess, this.onError)
  };

  onKycRequestSuccess = loanRequest => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'KYC Requested',
      dialogMessage: "KYC has been requested from Borrower.",
      loanRequest: loanRequest
    });

    UserService.getUser(loanRequest.ownerId,
      (user) => {
        let subject = 'KYC Requested';
        let message = 'Please fill up, sign and upload your KYC documents.';

        LoanRequestService.notifyUser(user, loanRequest.objectId, subject, message, console.log, console.log);
      }, console.log);
  };

  onKycDocumentApproved = kycDocument => {
    const {loanRequest} = this.state;
    /*
    UserService.getUser(loanRequest.ownerId,
      (user) => {
        let subject = 'KYC Document approved';
        let message = 'One of your KYC documents has been approved.';

        LoanRequestService.notifyUser(user, loanRequest.objectId, subject, message, console.log, console.log);
      }, console.log);
    */
    LoanRequestService.get(loanRequest.objectId, this.onGetSuccess, this.onError);
  };

  onKycIncomplete = loanRequest => {
    /*UserService.getUser(loanRequest.ownerId,
      (user) => {
        let subject = 'KYC Document rejected';
        let message = 'One of your KYC documents has been rejected.';

        LoanRequestService.notifyUser(user, loanRequest.objectId, subject, message, console.log, console.log);
      }, console.log);
    */
    LoanRequestService.get(loanRequest.objectId, this.onGetSuccess, this.onError);
  };

  /*
  onLoadDocumentPublished = loanDocument => {
    const {loanRequest} = this.state;
    UserService.getUser(loanRequest.ownerId,
      (user) => {
        let subject = 'New Loan Documents uploaded';
        let message = 'There are new documents pertaining to your loan request, available for viewing.';

        LoanRequestService.notifyUser(user, loanRequest.objectId, subject, message, console.log, console.log);
      }, console.log)
  };
  */

  /* Edit */
  edit = () => {
    const {loanRequest} = this.state;
    this.props.history.push({
      pathname: '/pages/loan-request',
      state: {loanRequest: loanRequest, isAdmin: true}
    });
  }

  /* Edit Reason */
  showEditReasonConfirmationDialog = () => {
    const { loanRequest} = this.state;

    let field = '';
    if (loanRequest.status === 'rejected')
      field = 'approval_rejection_reason';
    else if (['loan_rejected', 'loan_executed'].includes(loanRequest.status))
      field = 'loan_approval_rejection_reason';

    this.setState({
      dataConfirmDialogOpen: true,
      dataConfirmDialogTitle: 'Edit Reason',
      dataConfirmDialogDescription: 'Please edit your comments on Accepting/Rejecting this Request.',
      dataConfirmDialogInitialValue: loanRequest[field],
      dataConfirmDialogOnYesClick: descriptionData => this.onEditReasonConfirmDialogYesClick(field, descriptionData.description)
    });
  }

  onEditReasonConfirmDialogYesClick = (field, description) => {
    const {loanRequest} = this.state;

    let data = {
      objectId: loanRequest.objectId,
      [field]: description
    };

    LoanRequestService.save(data, (updatedLoanRequest) => this.setState({loanRequest: updatedLoanRequest}), this.onError);
    this.setState({confirmDialogOpen: false, dataConfirmDialogOpen: false});
  }

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, isLoading, loanRequest, indicative_terms, indicative_terms_expiry_date, indicative_terms_extended_expiry_date, term_sheet_expiry_date, term_sheet_extended_expiry_date, kyc_expiry_date, kyc_extended_expiry_date, confirmDialogOpen, confirmDialogMessage, confirmDialogYesClick, activeTermSheet, signedTermSheet} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl, activeItem} = this.state;
    const {dataConfirmDialogOpen, dataConfirmDialogTitle, dataConfirmDialogDescription, dataConfirmDialogOnYesClick, dataConfirmDialogInitialValue} = this.state;

    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>
        <ConfirmationDialog open={confirmDialogOpen}
                            message={confirmDialogMessage}
                            onYesClick={confirmDialogYesClick}
                            onNoClick={this.onConfirmDialogNoClick}/>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <DataConfirmationDialog open={dataConfirmDialogOpen} title={dataConfirmDialogTitle}
                                description={dataConfirmDialogDescription}
                                initialValue={dataConfirmDialogInitialValue}
                                onNoClick={this.onDataConfirmDialogNoClick}
                                onYesClick={dataConfirmDialogOnYesClick}/>

        <SingleGridLayout isAdmin>
          <DataLoader isLoading={isLoading}
                      itemsLength={1}
                      notFoundMessage={'There are no such Loan Request.'}
          />

          {!isLoading && <Segment padded>
            <Label color={'black'} attached={'top'}>Loan Request</Label>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Name of Borrower / Referrer</Table.Cell>
                  <Table.Cell>{loanRequest.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Request Date</Table.Cell>
                  <Table.Cell>{new Date(loanRequest.created).toLocaleString()}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Borrower/Referrer Type</Table.Cell>
                  <Table.Cell>{StringUtils.capitalize(loanRequest.loan_type)} ({StringUtils.capitalize(loanRequest.borrower_referrer)})</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Stock Code</Table.Cell>
                  <Table.Cell>{loanRequest.stock_code}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Number of Shares to Pledge</Table.Cell>
                  <Table.Cell>{loanRequest.pledge_number}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Loan Amount (USD)</Table.Cell>
                  <Table.Cell>{loanRequest.loan_amount}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Loan Purpose</Table.Cell>
                  <Table.Cell>{loanRequest.loan_purpose}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Refinancing</Table.Cell>
                  <Table.Cell>{StringUtils.capitalize(StringUtils.denull(loanRequest.loan_is_refinancing))}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Status</Table.Cell>
                  <Table.Cell
                    negative={loanRequest.status === 'rejected' || loanRequest.status === 'loan_rejected'}  positive={loanRequest.status === 'approved' || loanRequest.status === 'loan_executed'}>
                    {LoanRequestService.translateStatus(loanRequest.status)} { ['rejected','approved'].includes(loanRequest.status) && new Date(loanRequest.approval_rejection_date).toLocaleString()}{ ['loan_rejected','loan_executed'].includes(loanRequest.status) && new Date(loanRequest.loan_approval_rejection_date).toLocaleString()}
                    { loanRequest.status==='rejected' && <Fragment><br/>{loanRequest.approval_rejection_reason}</Fragment>}
                    { ['loan_rejected', 'loan_executed'].includes(loanRequest.status) && <Fragment><br/>{loanRequest.loan_approval_rejection_reason}</Fragment>}
                    { ['rejected', 'loan_rejected', 'loan_executed'].includes(loanRequest.status) && <Fragment><br/><br/><Button color={'blue'} onClick={this.showEditReasonConfirmationDialog}>Edit Status Comments</Button></Fragment>}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Comments</Table.Cell>
                  <Table.Cell className={'multiline'}>{loanRequest.comments}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            { !['loan_rejected', 'loan_executed', 'rejected'].includes(loanRequest.status) && <Segment basic textAlign={"right"}>
              <Button color={"blue"} onClick={this.edit}>Edit</Button>
            </Segment>}

            {!['loan_rejected', 'loan_executed'].includes(loanRequest.status) && <Segment textAlign={"right"}>
              <Button onClick={this.showRejectConfirmationDialog} color={'red'} disabled={!['new'].includes(loanRequest.status)}>Reject Loan Request</Button>
              <Button onClick={this.showApproveConfirmationDialog} color={'green'} disabled={!['new'].includes(loanRequest.status)}>Approve Loan Request</Button>
            </Segment>}
            {!['loan_rejected', 'loan_executed'].includes(loanRequest.status) && <Segment textAlign={"right"}>
              <Button onClick={this.showRejectApplicationConfirmationDialog} color={'orange'} disabled={loanRequest.status==='rejected'}>Reject Loan Application</Button>
              <Button onClick={this.showApproveApplicationConfirmationDialog} color={'teal'} disabled={loanRequest.status==='rejected'}>Execute Loan</Button>
            </Segment>}
          </Segment>
          }

          { !isLoading && <Menu pointing secondary>
            <Menu.Item
              name='details'
              active={activeItem === 'details'}
              onClick={this.handleItemClick}
            >Loan Request Details</Menu.Item>

            <Menu.Item
              name='kyc-documents'
              active={activeItem === 'kyc-documents'}
              onClick={this.handleItemClick}
            >KYC Documents</Menu.Item>

            <Menu.Item
              name='loan-documents'
              active={activeItem === 'loan-documents'}
              onClick={this.handleItemClick}
            >Loan Documents</Menu.Item>

            <Menu.Item
              name='internal-documents'
              active={activeItem === 'internal-documents'}
              onClick={this.handleItemClick}
            >Internal Documents</Menu.Item>
          </Menu> }

          {!isLoading && activeItem==='details'  && <Segment padded>
            <Label color={'black'} attached={'top left'}>Referred/Requested by</Label>
            <ProfileViewer userId={loanRequest.ownerId}/>
          </Segment>
          }

          {!isLoading && activeItem==='details' && <Segment padded>
            <Label color={'black'} attached={'top left'}>Indicative Financing Terms</Label>
            <Form id={'indicative-terms-form'} size='large'>
              <Form.Field
                label={'Indicative Financing Terms'}
                name={'indicative_terms'}
                value={indicative_terms}
                control={TextArea}
                rows={10}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'Expiry Date (MM/DD/YYYY)'}
                name={'indicative_terms_expiry_date'}
                value={StringUtils.toISOString(indicative_terms_expiry_date)}
                type={'date'}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'Extended Expiry Date (MM/DD/YYYY)'}
                name={'indicative_terms_extended_expiry_date'}
                value={StringUtils.toISOString(indicative_terms_extended_expiry_date)}
                type={'date'}
                onChange={this.handleChange}
              />
            </Form>
            <Segment padded style={{minHeight: 80}}>
              {loanRequest.indicative_terms_date && `Indicative Financing Terms saved on ${new Date(loanRequest.indicative_terms_date).toLocaleString()}`}
              {!['rejected', 'loan_rejected', 'loan_executed'].includes(loanRequest.status) &&
              <Button floated={"right"} color={'blue'} onClick={this.doSendIndicativeTerms}>Send Terms</Button>}
            </Segment>
          </Segment>}

          {!isLoading && activeItem==='details' && <Segment padded>
            <Label color={'black'} attached={'top left'}>Term Sheet</Label>
            {!activeTermSheet.created && <Message negative>
              <Message.Header>
                No Data Found
              </Message.Header>
              <p>
                No Term Sheet Uploaded.
              </p>
            </Message>
            }
            {activeTermSheet.created &&
            <Fragment>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Upload Date</Table.Cell>
                    <Table.Cell>{new Date(activeTermSheet.created).toLocaleString()}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Term Sheet</Table.Cell>
                    <Table.Cell>
                      <Button color={'blue'} onClick={() => this.viewTermSheet(activeTermSheet.fileURL)}>View</Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Segment padded>
                <Label color={'black'} attached={'top left'}>Term Sheet Expiry</Label>
                <Form id={'term-sheet-expiry-form'} size='large'>
                  <Form.Input
                    label={'Expiry Date (MM/DD/YYYY)'}
                    name={'term_sheet_expiry_date'}
                    value={StringUtils.toISOString(term_sheet_expiry_date)}
                    type={'date'}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label={'Extended Expiry Date (MM/DD/YYYY)'}
                    name={'term_sheet_extended_expiry_date'}
                    value={StringUtils.toISOString(term_sheet_extended_expiry_date)}
                    type={'date'}
                    onChange={this.handleChange}
                  />
                </Form>
                <Segment basic textAlign={'right'}>
                  <Button color={'blue'} onClick={this.setTermSheetExpiryDates}>Set Term Sheet Expiry Dates</Button>
                </Segment>
              </Segment>
            </Fragment>
            }

            {!activeTermSheet.created && !['rejected', 'loan_rejected', 'loan_executed'].includes(loanRequest.status) &&
            <Segment>
              <Form>
                <Form.Input
                  label={'Term Sheet'}
                  name={'term_sheet'}
                  type={'file'}
                  accept={'.pdf'}
                  onChange={this.onFileChange}
                />
                <Button onClick={this.uploadTermSheet} color={'blue'}>Upload Term Sheet</Button>
              </Form>
            </Segment>}
          </Segment>
          }

          {!isLoading && activeItem==='details' && signedTermSheet.created && <Segment padded>
            <Label color={'black'} attached={'top left'}>Signed Term Sheet</Label>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Upload Date</Table.Cell>
                  <Table.Cell>{new Date(signedTermSheet.created).toLocaleString()}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Term Sheet</Table.Cell>
                  <Table.Cell>
                    <Button color={'blue'} onClick={() => this.viewTermSheet(signedTermSheet.fileURL)}>View</Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          }

          {((!['kyc_requested', 'rejected', 'loan_rejected', 'loan_executed'].includes(loanRequest.status) && activeItem==='details') || (loanRequest.status==='kyc_incomplete' && activeItem==='kyc-documents')) && <Segment textAlign={"right"}>
            <ProcessorSelector name={'kycType'} onChange={this.onChange} /><Button onClick={this.requestKYC} color={'red'}>Request KYC</Button>
          </Segment>}
          {!isLoading && activeItem==='kyc-documents' && <AdminLoanKycDocumentsComponent ownerId={loanRequest.ownerId} loanRequestId={loanRequest.objectId} handleChange={this.handleChange} kyc_expiry_date={kyc_expiry_date} kyc_extended_expiry_date={kyc_extended_expiry_date} setKycExpiryDates={this.setKycExpiryDates} onKycDocumentApproved={this.onKycDocumentApproved} onKycIncomplete={this.onKycIncomplete} />}
          {!isLoading && activeItem==='loan-documents' && <AdminLoanDocumentsComponent ownerId={loanRequest.ownerId} loanRequestId={loanRequest.objectId} />}
          {!isLoading && activeItem==='internal-documents' && <AdminLoanInternalDocumentsComponent loanRequestId={loanRequest.objectId} />}
        </SingleGridLayout>
      </Fragment>

    )
  }
}
