import React, {Component} from "react";
import {Modal, Header, Button, Icon, Form} from "semantic-ui-react";
import PropTypes from "prop-types";
import {StringUtils} from "../../../../components/strings";

export class LoanDocumentEditDialog extends Component {
  state = {
    loanDocumentId: this.props.identifier,
    description: this.props.description,
    expiryDate: this.props.expiryDate,
    extendedExpiryDate: this.props.extendedExpiryDate
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.identifier !== prevProps.identifier) this.setState({
      loanDocumentId: this.props.identifier,
      description: this.props.description,
      expiryDate: this.props.expiryDate,
      extendedExpiryDate: this.props.extendedExpiryDate
    });
  }

  handleChange = (e, {name, value}) => { this.setState({[name]: value})}

  render = () => {
    const { description, expiryDate, extendedExpiryDate} = this.state;

    return (
      <Modal open={this.props.open}>
        <Header icon={'edit outline'} color={'blue'} content={'Edit Loan Document Metadata'}/>
        <Modal.Content>
          <Form id={'loanDocument-edit-form'} size='large'>
            <Form.Input
              name={'description'}
              label={'Description'}
              value={description}
              onChange={this.handleChange}
            />
            <Form.Input
              label={'Expiry Date (MM/DD/YYYY)'}
              name={'expiryDate'}
              value={StringUtils.toISOString(expiryDate)}
              type={'date'}
              onChange={this.handleChange}
            />
            <Form.Input
              label={'Extended Expiry Date (MM/DD/YYYY)'}
              name={'extendedExpiryDate'}
              value={StringUtils.toISOString(extendedExpiryDate)}
              type={'date'}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button name={'noBtn'} color='red' inverted onClick={this.props.onNoClick}>
            <Icon name='cancel'/> Cancel
          </Button>
          <Button name={'yesBtn'} color='green' inverted onClick={() => this.props.onYesClick(this.state)}>
            <Icon name='checkmark'/> OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

};

LoanDocumentEditDialog.propTypes = {
  open: PropTypes.bool,
  identifier: PropTypes.string,
  description: PropTypes.string,
  expiryDate: PropTypes.instanceOf(Date),
  extendedExpiryDate: PropTypes.instanceOf(Date),
  priority: PropTypes.number,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func
};