import React, {Component} from 'react';
import SingleGridLayout from '../layout/single-grid';
import {Segment, Card, Label, Divider} from "semantic-ui-react";

import {withTranslation} from "react-i18next";

class ContactPage extends Component {
  render = () => {
    const {t} = this.props;

    return (
      <SingleGridLayout>
        <Segment padded>
          <Card.Group centered>
            <Card>
              <Card.Content>
                <Card.Header>Lam Ching Ching</Card.Header>
                <Card.Description>{t('contact-subscriptions')}</Card.Description>
              </Card.Content>
              <Card.Content>
                <Label basic color={'blue'} as={'a'} href={'mailto:lamcc@charismatic.capital'}>
                  lamcc@charismatic.capital
                </Label>
                <Divider hidden />
                <Card.Description>
                  {t('general-subscriptions')}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Kenneth Low</Card.Header>
                <Card.Description>{t('contact-loans')}</Card.Description>
              </Card.Content>
              <Card.Content>
                <Label basic color={'blue'} as={'a'} href={'mailto:kennethlow@charismatic.capital'}>
                  kennethlow@charismatic.capital
                </Label>
                <Divider hidden />
                <Card.Description>
                  {t('general-loans')}
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
      </SingleGridLayout>
    )
  }
}

export default withTranslation("translations")(ContactPage);