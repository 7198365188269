import React, {Fragment} from "react";
import {Segment, Label} from "semantic-ui-react";

export const SubscriptionDeclaration = ({t}) => {
  return (
    <Fragment>
      <Segment padded>
        <Label color={'black'} attached={'top left'}>{t('subscription-declaration-title')}</Label>
        <p>{t('subscription-declaration')}</p>
      </Segment>

      <Segment padded>
        <p>{t('subscription-declaration-thanks')}</p>
      </Segment>
    </Fragment>
  )
};