import React, {Component} from 'react';
import {Segment, Label, Button, Card} from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import LoanStatisticComponent from "../shared/loan-statistic-component";
import SubscriptionStatisticComponent from "../shared/subscription-statistic-component";

export default class DashboardPage extends Component {


  handleClick = (action) => {
    switch (action) {
      case "loan-requests":
        this.props.history.push("/pages/admin/loan-requests");
        break;
      case "subscriptions":
        this.props.history.push("/pages/admin/subscriptions");
        break;
      default:
        this.props.history.push("/pages/admin/dashboard");
    }
  };

  render = () => {
    let width = document.body.clientWidth;
    const cardCount = width > 768 ? 5 : 1;
    const cardCount2 = width > 768 ? 3 : 1;

    return (
      <SingleGridLayout isAdmin>
        <Segment padded>
          <Label attached={'top left'} color={'black'}>Loan Requests</Label>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount}>
              <LoanStatisticComponent />
              <LoanStatisticComponent status={"new"} />
              <LoanStatisticComponent status={"processing"} />
              <LoanStatisticComponent status={"rejected"} />
            </Card.Group>
          </Segment>
          <Segment basic textAlign={"right"}><Button color={'blue'} onClick={() => this.handleClick('loan-requests')}>Loan Requests</Button></Segment>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount2}>
              <LoanStatisticComponent status={"loan_rejected"} />
              <LoanStatisticComponent status={"loan_executed"} />
            </Card.Group>
          </Segment>
        </Segment>
        <Segment padded>
          <Label attached={'top left'} color={'black'}>Subscriptions</Label>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount}>
              <SubscriptionStatisticComponent />
              <SubscriptionStatisticComponent status={"new"} />
              <SubscriptionStatisticComponent status={"processing"} />
              <SubscriptionStatisticComponent status={"rejected"} />
            </Card.Group>
          </Segment>
          <Segment basic textAlign={"right"}><Button color={'blue'} onClick={() => this.handleClick('subscriptions')}>Subscriptions</Button></Segment>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount2}>
              <SubscriptionStatisticComponent status={"subscription_rejected"} />
              <SubscriptionStatisticComponent status={"subscription_executed"} />
            </Card.Group>
          </Segment>
        </Segment>
      </SingleGridLayout>);
  }
}