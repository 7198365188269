import React, {Component} from "react";
import {Segment, Label, Table} from "semantic-ui-react";
import { UserService} from "../../../services/user-service";

import {withTranslation} from "react-i18next";

class ProfileViewer extends Component {

  state = {
    currentUser: {}
  };

  componentDidMount = () => UserService.getUser(this.props.userId, this.onCurrentUserSuccess, console.log);

  onCurrentUserSuccess = currentUser => this.setState({ currentUser: currentUser});

  render = () => {
    const { currentUser} = this.state;
    const { name, mailing_address, nationality, profession, contact_number, email} = currentUser;
    const { t} = this.props;

    return (
          <Segment padded>
            <Label color={'black'} attached={'top left'}>{t('profile-title')}</Label>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{t('profile-full-name')}</Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('profile-mailing-address')}</Table.Cell>
                  <Table.Cell>{mailing_address}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('profile-nationality')}</Table.Cell>
                  <Table.Cell>{nationality}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('profile-profession')}</Table.Cell>
                  <Table.Cell>{profession}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('profile-contact')}</Table.Cell>
                  <Table.Cell>{contact_number}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('profile-email')}</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
    )
  };
};

export default withTranslation("translations")(ProfileViewer)