import React, {Component, Fragment} from 'react';
import {Button, Form, Label, Message, Segment, Table, Dimmer, Loader} from "semantic-ui-react";
import {MessageDialog} from "../../../components/message-dialog";
import {FileViewerDialog} from "../../../components/file-viewer-dialog";
import {LoanRequestService} from "../../../services/loan-request-service";

import { withTranslation} from "react-i18next";
import {StringUtils} from "../../../components/strings";

class LoanKycDocumentsComponent extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    kycDocuments: [],
    kycDocumentDescription: '',
    kycDocumentFile: [],
    kycDocumentCount: 0,
    kycDocumentTotalCount: 0,
    isUploading: false
  };

  componentDidMount = () => LoanRequestService.getDocuments(this.props.loanRequestId, 'kyc', this.onListSuccess, this.onError);

  onListSuccess = listItems => this.setState({kycDocuments: listItems, isLoading: false});

  onError = error => {
    if (!(error.message.includes('Table not found') || error.message.includes("Column 'attachments' does not exist"))) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving KYC Documents',
        dialogDescription: this.props.t('general-error-retrieving-records'),
        isError: true
      });
    }
  };

  onMessageDialogOk = (e, {name}) => this.setState({dialogOpen: false});

  viewKycDocument = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  /* KycDocument Form */
  onDataChange = (e, {name, value}) => this.setState({[name]: value});
  onFileChange = (e, {name}) => this.setState({[name]: e.target.files});

  uploadKycDocument = () => {
    const {kycDocumentFile} = this.state;

    this.setState({
      kycDocumentCount: 0,
      kycDocumentTotalCount: kycDocumentFile.length,
      isUploading: kycDocumentFile.length > 0
    }, () => {
      LoanRequestService.uploadDocument(this.props.loanRequestId, kycDocumentFile, 'kyc', '', null, null, this.onSaveKycDocumentSuccess, this.onUploadKycDocumentError)

      document.getElementById("kycDocument-form").reset();
    });

  };

  onSaveKycDocumentSuccess = (kycDocument, count) => {
    const { kycDocumentCount, kycDocumentTotalCount} = this.state;
    if (kycDocumentCount + count === kycDocumentTotalCount) {
      let loanRequestData = {
        objectId: this.props.loanRequestId,
        status: 'kyc_uploaded'
      };

      this.setState({
        kycDocumentCount: 0,
        kycDocumentTotalCount: 0,
        isUploading: false
      }, () => LoanRequestService.save(loanRequestData, this.onSaveLoanRequestSuccess, this.onUploadKycDocumentError));
    } else {
      this.setState({
        kycDocumentCount: kycDocumentCount + count
      });
    }

  };

  onSaveLoanRequestSuccess = () => {
    LoanRequestService.getDocuments(this.props.loanRequestId, 'kyc', this.onListSuccess, this.onError);
    LoanRequestService.notifyAdminOnKycUpload(this.props.loanRequestId, console.log, console.log);
  }

  onUploadKycDocumentError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading KYC Document',
    dialogMessage: "There's an error uploading or saving KYC Document. Please contact administrator.",
    dialogDescription: error.message,
    isError: true
  });

  render = () => {
    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, kycDocuments} = this.state;
    const {fileViewerDialogOpen, fileViewerFileUrl, isUploading} = this.state;

    const { t, kyc_expiry_date, kyc_extended_expiry_date} = this.props;

    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('loan-ift-expiry')}</Label>
          <Message warning>
            <p>
              {t('loan-ift-expiry-message')}
            </p>
          </Message>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{t('loan-ift-expiry')}</Table.Cell>
                <Table.Cell>{StringUtils.toLocaleDateString(kyc_expiry_date)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('loan-ift-extended')}</Table.Cell>
                <Table.Cell>{StringUtils.toLocaleDateString(kyc_extended_expiry_date)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('loan-kyc-upload')}</Label>

          { isUploading && <Dimmer active>
            <Loader indeterminate>Uploading - Please wait...</Loader>
          </Dimmer>}

          <Form id={'kycDocument-form'}>
            <Message>
              <Message.Header>
                {t('loan-kyc-message-title')}
              </Message.Header>
              <p>
                {t('loan-kyc-message-body')}
              </p>
            </Message>
            <Form.Input
                label={t('loan-kyc-form')}
                name={'kycDocumentFile'}
                type={'file'}
                accept={'.pdf,.png,.jpg,.jpeg'}
                multiple
                onChange={this.onFileChange}
              />
            <Button onClick={this.uploadKycDocument} color={'blue'}>{t('loan-kyc-upload-button')}</Button>
          </Form>
        </Segment>
        <Segment padded>
          <Label color={'black'} attached={'top left'}>{t('loan-kyc-support-list')}</Label>
          {kycDocuments.length === 0 && <Message negative>
            <Message.Header>
              {t('general-no-data')}
            </Message.Header>
            <p>
              {t('loan-kyc-not-found')}
            </p>
          </Message>}
          {kycDocuments.length > 0 && <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Upload Date</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>View</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                kycDocuments.map(kycDocument => (
                    <Table.Row key={kycDocument.objectId} positive={kycDocument.status === 'accepted'}
                               negative={kycDocument.status === 'rejected'}>
                      <Table.Cell>{kycDocument.document_description}</Table.Cell>
                      <Table.Cell>{new Date(kycDocument.created).toLocaleString()}</Table.Cell>
                      <Table.Cell textAlign={"center"}>
                        <Button color={'blue'} onClick={() => this.viewKycDocument(kycDocument.fileURL)}>View</Button>
                      </Table.Cell>
                      <Table.Cell>
                        {LoanRequestService.translateKYCStatus(kycDocument.status)}
                        <br/>
                        {['rejected', 'accepted'].includes(kycDocument.status) && new Date(kycDocument.approval_rejection_date).toLocaleString()}
                        <br/>
                        {['rejected', 'accepted'].includes(kycDocument.status) && kycDocument.approval_rejection_reason}
                      </Table.Cell>
                    </Table.Row>
                  )
                )

              }
            </Table.Body>
          </Table>}
        </Segment>

      </Fragment>

    )
  }
}

export default withTranslation("translations")(LoanKycDocumentsComponent);