import React, {Component, Fragment} from 'react';
import {Form, Segment, Button, Message, Label, Divider} from "semantic-ui-react";
import SingleLayout from "../layout/single";
import {UserService} from "../services/user-service";
import {MessageDialog} from "../components/message-dialog";
import DataConfirmationDialog from "../components/data-confirmation-dialog";
import {AccessRequestDialog} from "../components/access-request-dialog";
import {SiteTermsAndConditions} from "./terms";

import {withTranslation} from "react-i18next";
import LanguageToggler from "../components/language-toggler";

class StartPage extends Component {
  state = {
    email: '', password: '',
    dialogOpen: false,
    dialogTitle: 'Profile Update Required',
    dialogMessage: 'You appear not to have an profile. An up-to-date profile is required for any subscriptions or loan requests to be acknowledged and processed.',
    dialogDescription: 'You will now be brought to your profile page. Thank you for your interest.',
    isError: false,
    invalidLogin: false,
    disabledLogin: false,
    showPasswordReset: false,
    dataConfirmDialogOpen: false,
    accessRequestDialogOpen: false,
    termsAndConditionsDialogOpen: false
  };

  componentDidMount = () => {
    const { t} = this.props;
    this.setState({
      dialogTitle: t('profile-update-title'),
      dialogMessage: t('profile-update-message'),
      dialogDescription: t('profile-update-description')
    });
  };

  doLogin = () => {
    const {email, password} = this.state;
    const {t} = this.props;
    UserService.login(email, password, (user) => {
      let from = localStorage.getItem("from");
      if (from) {
        localStorage.removeItem("from");
        this.props.history.push(from);
      } else {
        if (user.isAdmin)
          this.props.history.push("/pages/admin/dashboard");
        else {
          if (user.name === null || user.name.trim() === '')
            this.setState({dialogOpen: true});
          else
            this.props.history.push("/pages/dashboard");
        }
      }
    }, error => {
      let messageFunc = (statusCode) => {
        switch (statusCode) {
          case 3003: return {
            invalidLogin: true,
            disabledLogin: false,
            invalidLoginMessage: t('start-login-fail-message')
          };
          case 3036: return {
            invalidLogin: false,
            disabledLogin: true,
            invalidLoginMessage: t('start-login-too-many-failed')
          };
          case 3090: return {
            invalidLogin: false,
            disabledLogin: true,
            invalidLoginMessage: t('start-login-disabled')
          };
          default: return {
            invalidLogin: true,
            disabledLogin: false,
            invalidLoginMessage: t('start-login-unknown')
          };
        }
      };
      this.setState(messageFunc(error.code));
    });
  };


  handleChange = (e, {name, value}) => this.setState({[name]: value});

  handleClick = () => this.doLogin();

  onMessageDialogOk = () => {
    localStorage.setItem("firstLogin", 'true');
    this.props.history.push('/pages/profile');
  };

  resetPassword = () => this.setState({dataConfirmDialogOpen: true});

  onDataConfirmDialogYesClick = descriptionData => {
    this.setState({dataConfirmDialogOpen: false});
    UserService.resetPassword(descriptionData.description, this.onResetPasswordSuccess, this.onResetPasswordError);
  };

  onDataConfirmDialogNoClick = () => this.setState({dataConfirmDialogOpen: false});

  onResetPasswordSuccess = () => this.setState({showPasswordReset: true});

  onResetPasswordError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Resetting Password',
    dialogMessage: this.props.t('start-password-reset-error'),
    dialogDescription: error.message,
    isError: true
  });

  /* Access Request */
  requestAccess = () => this.setState({ accessRequestDialogOpen: true});

  onAccessRequestDialogYesClick = ({email, message}) => {
    UserService.register(email, (user) => {
      UserService.updateStatus(user.objectId, 'DISABLED', this.onUpdateUserSuccess, this.onUpdateUserError);
      UserService.notifyAdminForAccessRequest(user, message, this.onAccessRequestSuccess, this.onAccessRequestError);
    }, this.onAccessRequestError);
  };

  onUpdateUserSuccess = response => {}
  onUpdateUserError = error => {}

  onAccessRequestError = error => {
    const {t} = this.props;

    this.setState({
      dialogOpen: true,
      dialogTitle: t('start-access-request-error'),
      dialogMessage: t('start-access-request-error-message'),
      dialogDescription: error.message,
      isError: true
    });
  }

  onAccessRequestSuccess = () => {
    const {t} = this.props;
    this.setState({
      dialogOpen: true,
      dialogTitle: t('start-access-request-success'),
      dialogMessage: t('start-access-request-success-message'),
      dialogDescription: '',
      isError: false
    });
  }

  onAccessRequestDialogNoClick = () => this.setState({accessRequestDialogOpen: false});

  showTermsAndConditions = () => this.setState({termsAndConditionsDialogOpen: true});
  closeTermsAndConditions = (e, data) => this.setState({termsAndConditionsDialogOpen: false});

  render = () => {
    const {accessRequestDialogOpen, dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, invalidLogin, disabledLogin, invalidLoginMessage, showPasswordReset, dataConfirmDialogOpen} = this.state;

    const { termsAndConditionsDialogOpen } = this.state;

    const { t } = this.props;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <AccessRequestDialog t={t} open={accessRequestDialogOpen} onYesClick={this.onAccessRequestDialogYesClick} onNoClick={this.onAccessRequestDialogNoClick} />

        <DataConfirmationDialog open={dataConfirmDialogOpen} title={t('start-password-reset')}
                                description={t('start-password-reset-instruction')}
                                onNoClick={this.onDataConfirmDialogNoClick}
                                onYesClick={this.onDataConfirmDialogYesClick}/>

        <SiteTermsAndConditions open={termsAndConditionsDialogOpen} onOkClick={this.closeTermsAndConditions}/>

        <SingleLayout>
          {(invalidLogin || disabledLogin) && <Message negative={invalidLogin} warning={disabledLogin}>
            <Message.Header>{t('start-login-fail')}</Message.Header>
            {invalidLoginMessage}
          </Message>}

          { showPasswordReset && <Message positive>
            <Message.Header>Password Reset Request Successful.</Message.Header>
            <p>
              If you are registered with our Portal, an email containing your new password will be sent to you.
            </p>
          </Message>}

          <Form size='large'>
            <Segment stacked>
              <Form.Input
                fluid
                icon='user'
                iconPosition='left'
                placeholder={t('start-email')}
                name={'email'}
                onChange={this.handleChange}/>
              <Form.Input
                fluid
                icon='lock'
                iconPosition='left'
                placeholder={t('start-password')}
                type='password'
                name={'password'}
                onChange={this.handleChange}
              />

              <Button primary fluid size='large' onClick={this.handleClick}>
                { t("start-login")}
              </Button>
              <Segment basic textAlign={"center"}>
                { t("start-forgot-password")} <Label onClick={this.resetPassword}>{ t("start-new-password")}</Label>
              </Segment>
            </Segment>
            <Segment basic textAlign={"center"}>
              <Label color={"black"} onClick={this.requestAccess}>{ t("start-request-access")}</Label>
            </Segment>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Segment basic textAlign={"center"}>
              <Label basic onClick={this.showTermsAndConditions}>{ t("start-tnc")}</Label>
            </Segment>
          </Form>
        </SingleLayout>

        <div style={{position: 'fixed', right: 0, top: 0}}>
          <LanguageToggler />
        </div>
      </Fragment>
    )
  }
}

export default withTranslation("translations")(StartPage);