import Backendless from "backendless";

import {TBL_LOAN_REQUEST_ATTACHMENTS, TBL_LOAN_REQUESTS} from "./tables";
import {
  ACCESS_REQUEST_ADDRESSEE_EMAIL,
  LOAN_REQUEST_ADDRESSEE,
  LOAN_REQUEST_ADDRESSEE_EMAIL,
  PORTAL_URL,
  PAGE_SIZE
} from "../config";

export const LoanRequestService = {
  save: (loanRequest, onSuccess, onError) => {
    Backendless.Data.of(TBL_LOAN_REQUESTS).save(loanRequest).then(onSuccess).catch(onError);
  },

  listPaginator: (filter, onSuccess, onError) => {
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause(filter);
    Backendless.Data.of(TBL_LOAN_REQUESTS).getObjectCount(queryBuilder).then(count => onSuccess(Math.ceil(count/PAGE_SIZE))).catch(onError);
  },
  list: (filter, sortString, page, onSuccess, onError) => {
    let queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder = queryBuilder.setOffset((page - 1) * PAGE_SIZE).setPageSize(PAGE_SIZE).setWhereClause(filter);
    if (sortString.trim() === '')
      queryBuilder = queryBuilder.setSortBy("created DESC");
    else
      queryBuilder = queryBuilder.setSortBy(sortString);

    Backendless.Data.of(TBL_LOAN_REQUESTS).find(queryBuilder).then(onSuccess).catch(onError);
  },

  get: (objectId, onSuccess, onError) => {
    Backendless.Data.of(TBL_LOAN_REQUESTS).findById(objectId).then(onSuccess).catch(onError);
  },

  countTotal: (status, ownerId, onSuccess, onError) => {
    let statusQuery = requestStatus => {
      switch (requestStatus) {
        case 'new': return "status='new'";
        case 'approved': return "status='term_sheet_signed'";
        case 'rejected': return "status='rejected'";
        case 'loan_rejected': return "status='loan_rejected'";
        case 'loan_executed': return "status='loan_executed'";
        case 'processing': return "status not in ('new', 'approved', 'rejected', 'loan_executed', 'loan_rejected')";
        default: return "all";
      }
    };

    let query = statusQuery(status);
    if (ownerId) query = query==='all' ? `ownerId='${ownerId}'` : `${query} and ownerId='${ownerId}'`;
    let queryBuilder = Backendless.DataQueryBuilder.create();

    if (query!=="all") queryBuilder = queryBuilder.setWhereClause(query);

    Backendless.Data.of(TBL_LOAN_REQUESTS).getObjectCount(queryBuilder).then(onSuccess).catch(onError);
  },

  translateStatus: (status) => {
    switch (status) {
      case 'new': return 'New Loan Request';
      case 'rejected': return 'Loan Request Rejected';
      case 'approved': return 'Loan Request Approved';
      case 'term_sheet': return 'Term Sheet Available.';
      case 'term_sheet_signed': return 'Term Sheet Signed.';
      case 'indicative_terms_sent': return 'Indicative Financing Terms Sent.';
      case 'kyc_requested': return 'KYC Requested.';
      case 'kyc_uploaded': return 'KYC Uploaded.';
      case 'kyc_incomplete': return 'KYC Incomplete or Invalid.';
      case 'loan_rejected': return 'Non Processing Loan';
      case 'loan_executed': return 'Loan Executed';
      default: return 'Unknown';
    }
  },

  translateKYCStatus: (status) => {
    switch (status) {
      case 'new': return 'New KYC Document';
      case 'rejected': return 'KYC Document Rejected';
      case 'accepted': return 'KYC Document Accepted';
      default: return 'Unknown';
    }
  },

  getFileName: fileName => {
    let nameTokens = fileName.split(/\./);
    nameTokens.pop();
    return nameTokens.join('.');
  },

  uploadDocument: (objectId, fileRef, documentType, documentDescription, expiryDate, extendedExpiryDate, onFileUploaded, onFileUploadError) => {
    Array.from(fileRef).forEach(fileObj => Backendless.Files.upload(fileObj, `loan-requests/${objectId}/${new Date().getTime()}`)
      .then(fileUrl => {
        fileUrl['status'] = 'new';
        fileUrl['document_type'] = documentType;
        fileUrl['document_description'] = documentDescription==='' ? LoanRequestService.getFileName(fileObj.name) : documentDescription;
        if (expiryDate) fileUrl['expiry_date'] = new Date(expiryDate);
        if (extendedExpiryDate) fileUrl['extended_expiry_date'] = new Date(extendedExpiryDate);
        Backendless.Data.of(TBL_LOAN_REQUEST_ATTACHMENTS).save(fileUrl)
          .then(savedAttachment => {
            Backendless.Data.of(TBL_LOAN_REQUESTS).addRelation(
              { objectId: objectId},
              "attachments:" + TBL_LOAN_REQUEST_ATTACHMENTS + ":n",
              [savedAttachment]
            ).then(count => onFileUploaded(savedAttachment, count)).catch(onFileUploadError);
          })
          .catch(onFileUploadError);
      })
      .catch(onFileUploadError));
  },

  updateDocument: (document, onSuccess, onError) => Backendless.Data.of(TBL_LOAN_REQUEST_ATTACHMENTS).save(document).then(onSuccess).catch(onError),

  getTermSheets: (loanRequestId, onSuccess, onError) => {
    let queryBuilder = Backendless.LoadRelationsQueryBuilder.create();
    queryBuilder = queryBuilder.setRelationName("attachments");
    queryBuilder = queryBuilder.setWhereClause("document_type in ('term-sheet', 'signed-term-sheet')").setSortBy('created DESC');

    Backendless.Data.of(TBL_LOAN_REQUESTS).loadRelations(loanRequestId, queryBuilder).then(onSuccess).catch(onError);
  },

  getDocuments: (loanRequestId, documentType, onSuccess, onError) => {
    let queryBuilder = Backendless.LoadRelationsQueryBuilder.create();
    queryBuilder = queryBuilder.setRelationName("attachments");
    queryBuilder = queryBuilder.setPageSize(100).setWhereClause(`document_type = '${documentType}'`).setSortBy('created DESC');

    Backendless.Data.of(TBL_LOAN_REQUESTS).loadRelations(loanRequestId, queryBuilder).then(onSuccess).catch(onError);
  },

  getDocumentsByQuery: (loanRequestId, documentType, query, onSuccess, onError) => {
    let queryBuilder = Backendless.LoadRelationsQueryBuilder.create();
    queryBuilder = queryBuilder.setRelationName("attachments");
    queryBuilder = queryBuilder.setPageSize(100).setWhereClause(`document_type = '${documentType}' and ${query}`).setSortBy('created DESC');

    Backendless.Data.of(TBL_LOAN_REQUESTS).loadRelations(loanRequestId, queryBuilder).then(onSuccess).catch(onError);
  },

  notifyAdmin: (loanRequestId, subject, message, onSuccess, onError) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(LOAN_REQUEST_ADDRESSEE_EMAIL);

    const templateValues = {
      addressee: LOAN_REQUEST_ADDRESSEE,
      subject: subject,
      message: message,
      link_text: 'View Loan Request',
      link: `${PORTAL_URL}/pages/admin/loan-request/${loanRequestId}`
    };

    Backendless.Messaging.sendEmailFromTemplate('Notification', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  },

  notifyAdminOnCreate: (loanRequestId, onSuccess, onError) => LoanRequestService.notifyAdmin(loanRequestId, 'New Loan Request', 'A new loan request has been received.', onSuccess, onError),
  notifyAdminOnTermSheetSigned: (loanRequestId, onSuccess, onError) => LoanRequestService.notifyAdmin(loanRequestId, 'Loan Request Update', 'A Signed Term Sheet has been uploaded.', onSuccess, onError),
  notifyAdminOnKycUpload: (loanRequestId, onSuccess, onError) => LoanRequestService.notifyAdmin(loanRequestId, 'Loan Request Update', 'A KYC Document has been uploaded.', onSuccess, onError),

  notifyUser: (user, loanRequestId, subject, message, onSuccess, onError) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(user.email);
    envelope.setCc(ACCESS_REQUEST_ADDRESSEE_EMAIL);

    const templateValues = {
      addressee: user.name,
      subject: subject,
      message: message,
      link_text: 'View Loan Request',
      link: `${PORTAL_URL}/pages/loan-request-view/${loanRequestId}`
    };

    Backendless.Messaging.sendEmailFromTemplate('Notification', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  }
};