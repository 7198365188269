import React, {Component, Fragment} from 'react';
import {Button, Label, Menu, Pagination, Segment, Table} from "semantic-ui-react";
import SingleGridLayout from '../../../layout/single-grid';
import {MessageDialog} from "../../../components/message-dialog";
import DataLoader from "../../../components/data-loader";
import {SubscriptionService} from "../../../services/subscription-service";
import {StringUtils} from "../../../components/strings";

export default class AdminSubscriptionsPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    subscriptions: [],
    corporateSubscriptions: [],
    isLoading: true,
    isCorporateLoading: true,
    activeItem: 'individual',
    indSortColumn: 'created',
    indSortOrder: 'DESC',
    indActivePage: 1,
    indTotalPages: 1,
    corpSortColumn: 'created',
    corpSortOrder: 'DESC',
    corpActivePage: 1,
    corpTotalPages: 1
  };

  labels = {
    individual: {
      button: 'Subscribe for an Individual Account',
      title: 'My Individual Account Subscriptions'
    },
    corporate: {
      button: 'Subscribe for a Corporate Account',
      title: 'My Corporate Account Subscriptions'
    }
  };


  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  loadData = () => {
    this.loadIndDataPaginator();
    this.loadCorpDataPaginator();
  };

  componentDidMount = () => this.loadData();

  loadIndDataPaginator = () => SubscriptionService.listPaginator('individual', '', (indTotalPages) => this.setState({indTotalPages}, this.loadIndData), this.onError);
  loadIndData = () => {
    const {indActivePage} = this.state;
    SubscriptionService.list('individual', '', this.generateIndSortString(), indActivePage, this.onListSuccess, this.onError);
  }
  onListSuccess = listItems => this.setState({ subscriptions: listItems, isLoading: false});

  loadCorpDataPaginator = () => SubscriptionService.listPaginator('corporate', '', (corpTotalPages) => this.setState({corpTotalPages}, this.loadCorpData), this.onCorporateError);
  loadCorpData = () => {
    const {corpActivePage} = this.state;
    SubscriptionService.list('corporate', '', this.generateCorpSortString(), corpActivePage, this.onCorporateListSuccess, this.onCorporateError);
  };
  onCorporateListSuccess = listItems => this.setState({ corporateSubscriptions: listItems, isCorporateLoading: false});

  onError = error => {
    if (error.message.toString().includes("Table not found by name")) {
      this.setState({ isLoading: false});
      return;
    }

    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Retrieving Subscriptions',
      dialogMessage: "There's an error retrieving records. Please contact administrator.",
      dialogDescription: error.message,
      isError: true,
      isLoading: false
    });
  };

  onCorporateError = error => {
    if (error.message.toString().includes("Table not found by name")) {
      this.setState({ isCorporateLoading: false});
      return;
    }

    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Retrieving Corporate Subscriptions.',
      dialogMessage: "There's an error retrieving records. Please contact administrator.",
      dialogDescription: error.message,
      isError: true,
      isCorporateLoading: false
    });
  };

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/dashboard");
  };

  viewSubscription = subscriptionId => this.props.history.push(`/pages/admin/subscription/${subscriptionId}`);

  sortInd = columnName => {
    const { indSortColumn, indSortOrder} = this.state;

    if (indSortColumn===columnName) {
      this.setState({
        indActivePage: 1,
        indSortOrder: indSortOrder==='DESC' ? 'ASC' : 'DESC'
      }, this.loadIndData);
    } else {
      this.setState({
        indActivePage: 1,
        indSortColumn: columnName,
        indSortOrder: 'ASC'
      }, this.loadIndData);
    }
  };

  sortCorp = columnName => {
    const { corpSortColumn, corpSortOrder} = this.state;

    if (corpSortColumn===columnName) {
      this.setState({
        corpActivePage: 1,
        corpSortOrder: corpSortOrder==='DESC' ? 'ASC' : 'DESC'
      }, this.loadCorpData);
    } else {
      this.setState({
        corpActivePage: 1,
        corpSortColumn: columnName,
        corpSortOrder: 'ASC'
      }, this.loadCorpData);
    }
  };

  formatSort = sortColumn => {
    switch (sortColumn) {
      case 'name': return 'Full Name';
      case 'created': return 'Request Date';
      case 'corporate_account_type': return 'Account Type';
      case 'name_of_company': return 'Name of Company';
      case 'place_of_corporation': return 'Place of Incorporation';
      default: return StringUtils.capitalize(sortColumn);
    }
  };

  formatSortOrder = sortOrder => {
    return sortOrder==='DESC' ? 'descending' : 'ascending';
  };

  generateIndSortString = () => {
    const { indSortColumn, indSortOrder} = this.state;

    return `${indSortColumn} ${indSortOrder}`;
  };

  generateCorpSortString = () => {
    const { corpSortColumn, corpSortOrder} = this.state;

    return `${corpSortColumn} ${corpSortOrder}`;
  };

  /* Pagination */
  handlePaginationChange = (e, { activePage }) => this.setState({ indActivePage: activePage }, this.loadIndData);
  handleCorpPaginationChange = (e, { activePage }) => this.setState({ corpActivePage: activePage }, this.loadCorpData);

  render = () => {
    const { dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, activeItem} = this.state;
    const { isLoading, subscriptions, isCorporateLoading, corporateSubscriptions} = this.state;

    const {indSortColumn, indSortOrder, indActivePage, indTotalPages} = this.state;
    const {corpSortColumn, corpSortOrder, corpActivePage, corpTotalPages} = this.state;

    return (
      <Fragment>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <SingleGridLayout isAdmin>
          { <Menu pointing secondary>
            <Menu.Item
              name='individual'
              active={activeItem === 'individual'}
              onClick={this.handleItemClick}
            >Individual Subscriptions</Menu.Item>

            <Menu.Item
              name='corporate'
              active={activeItem === 'corporate'}
              onClick={this.handleItemClick}
            >Corporate Subscriptions</Menu.Item>

          </Menu> }

          { activeItem==='individual' && <Fragment>
            <DataLoader isLoading={isLoading}
                        itemsLength={subscriptions.length}
                        notFoundMessage={'You have No Individual Subscriptions.'}
            />
            { !isLoading && subscriptions.length>0 &&
            <Segment padded>
              <Label color={'black'} attached={'top left'}>{ this.labels.individual.title}</Label>
              <Segment basic textAlign={"right"}>
                { indSortColumn==='' && <Fragment>No Sorting</Fragment>}
                { indSortColumn!=='' && <Fragment>Sorted by {this.formatSort(indSortColumn)} {this.formatSortOrder(indSortOrder)}</Fragment>}
              </Segment>
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell onClick={() => this.sortInd('name')}>Full Name</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortInd('created')}>Request Date</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortInd('nationality')}>Nationality</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortInd('profession')}>Profession</Table.HeaderCell>
                    <Table.HeaderCell>Contact Info</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortInd('status')}>Status</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    subscriptions.map(subscription => (
                        <Table.Row key={subscription.objectId}>
                          <Table.Cell>{ subscription.name }</Table.Cell>
                          <Table.Cell>{ new Date(subscription.created).toLocaleString() }</Table.Cell>
                          <Table.Cell>{ subscription.nationality}</Table.Cell>
                          <Table.Cell>{ subscription.profession }</Table.Cell>
                          <Table.Cell>
                            { subscription.contact_number }<br />
                            { subscription.email }
                          </Table.Cell>
                          <Table.Cell
                            negative={['rejected', 'subscription_rejected'].includes(subscription.status)}  positive={['approved', 'subscription_executed'].includes(subscription.status)}>
                            {SubscriptionService.translateStatus(subscription.status)} { ['rejected','approved'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.approval_rejection_date).toLocaleString()}</Fragment>}{ ['subscription_rejected','subscription_executed'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.subscription_approval_rejection_date).toLocaleString()}</Fragment>}
                            { subscription.status==='rejected' && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                            { ['subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                          </Table.Cell>
                          <Table.Cell>
                            <Button color={'blue'} onClick={() => this.viewSubscription(subscription.objectId)}>View</Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )

                  }
                </Table.Body>
              </Table>
              <Segment basic textAlign={"right"}>
                <Pagination
                    activePage={indActivePage}
                    boundaryRange={1}
                    onPageChange={this.handlePaginationChange}
                    size='mini'
                    siblingRange={1}
                    totalPages={indTotalPages}
                    ellipsisItem={undefined}
                    firstItem={undefined}
                    lastItem={undefined}
                    prevItem={undefined}
                    nextItem={undefined}
                />
              </Segment>
            </Segment>
            }
          </Fragment>}

          { activeItem==='corporate' && <Fragment>
            <DataLoader isLoading={isCorporateLoading}
                        itemsLength={corporateSubscriptions.length}
                        notFoundMessage={'You have No Corporate Subscriptions.'}
            />
            { !isCorporateLoading && corporateSubscriptions.length>0 &&
            <Segment padded>
              <Label color={'black'} attached={'top left'}>{ this.labels.corporate.title}</Label>
              <Segment basic textAlign={"right"}>
                { corpSortColumn==='' && <Fragment>No Sorting</Fragment>}
                { corpSortColumn!=='' && <Fragment>Sorted by {this.formatSort(corpSortColumn)} {this.formatSortOrder(corpSortOrder)}</Fragment>}
              </Segment>
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell onClick={() => this.sortCorp('corporate_account_type')}>Account Type</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortCorp('name_of_company')}>Name of Company</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortCorp('created')}>Request Date</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortCorp('place_of_incorporation')}>Place of Incorporation</Table.HeaderCell>
                    <Table.HeaderCell>Contact Info</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.sortCorp('status')}>Status</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    corporateSubscriptions.map(subscription => (
                        <Table.Row key={subscription.objectId}>
                          <Table.Cell>{ SubscriptionService.translateCorporateAccountType(subscription.corporate_account_type) }</Table.Cell>
                          <Table.Cell>{ subscription.name_of_company }</Table.Cell>
                          <Table.Cell>{ new Date(subscription.created).toLocaleString() }</Table.Cell>
                          <Table.Cell>{ subscription.place_of_incorporation}</Table.Cell>
                          <Table.Cell>
                            { subscription.contact_number }<br />
                            { subscription.email }
                          </Table.Cell>
                          <Table.Cell
                            negative={['rejected', 'subscription_rejected'].includes(subscription.status)}  positive={['approved', 'subscription_executed'].includes(subscription.status)}>
                            {SubscriptionService.translateStatus(subscription.status)} { ['rejected','approved'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.approval_rejection_date).toLocaleString()}</Fragment>}{ ['subscription_rejected','subscription_executed'].includes(subscription.status) && <Fragment><br/>{new Date(subscription.subscription_approval_rejection_date).toLocaleString()}</Fragment>}
                            { subscription.status==='rejected' && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                            { ['subscription_rejected', 'subscription_executed'].includes(subscription.status) && <Fragment><br/>{subscription.approval_rejection_reason}</Fragment>}
                          </Table.Cell>
                          <Table.Cell>
                            <Button color={'blue'} onClick={() => this.viewSubscription(subscription.objectId)}>View</Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )
                  }
                </Table.Body>
              </Table>

              <Segment basic textAlign={"right"}>
                <Pagination
                    activePage={corpActivePage}
                    boundaryRange={1}
                    onPageChange={this.handleCorpPaginationChange}
                    size='mini'
                    siblingRange={1}
                    totalPages={corpTotalPages}
                    ellipsisItem={undefined}
                    firstItem={undefined}
                    lastItem={undefined}
                    prevItem={undefined}
                    nextItem={undefined}
                />
              </Segment>
            </Segment>
            }
          </Fragment>}
        </SingleGridLayout>
      </Fragment>
    )
  }
}