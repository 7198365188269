import Backendless from "backendless";
import {TBL_USERS} from "./tables";
import {generate} from "generate-password";
import {
  ACCESS_REQUEST_ADDRESSEE,
  ACCESS_REQUEST_ADDRESSEE_EMAIL, PORTAL_URL,
  PAGE_SIZE
} from "../config";

export const UserService = {
  login: (email, password, onSuccess, onError) => {
    Backendless.UserService.login(email, password, true)
      .then(user => {
        user["isAdmin"] = user.email.match("charismatic.capital") !== null;
        onSuccess(user);
      })
      .catch(onError);
  },

  register: (email, onSuccess, onError) => {
    let user = new Backendless.User();
    user.email = email;
    let generatedPassword = generate({
      length: 10,
      numbers: true
    });
    user.password = generatedPassword;
    Backendless.UserService.register(user)
      .then(user => {
        user["isAdmin"] = user.email.match("charismatic.capital") !== null;
        UserService.sendRegistrationEmail(user, generatedPassword, console.log);
        onSuccess(user);
      })
      .catch(onError);
  },

  sendRegistrationEmail: (user, password, onSuccess) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(user.email);

    const templateValues = {
      email: user.email,
      password: password,
      subject: 'Charismatic Debt Equity Fund Account',
      line_1: 'You have been given an account on the Charismatic Capital Debt Equity Fund Portal.',
      line_2: 'Your access credentials are as follows.',
      line_3: 'For your security, you are advised to change your password upon login. the first time you login.',
      line_4: '', line_5: 'Thank you for your support.'
    };

    Backendless.Messaging.sendEmailFromTemplate('Password', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  },

  resetPassword: (email, onSuccess, onError) => {
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause(`email='${email}'`);
    Backendless.Data.of(TBL_USERS).find(queryBuilder).then(users => {
      if (users.length===1) {
        let user = users[0];
        let generatedPassword = generate({
          length: 10,
          numbers: true
        });
        user.password = generatedPassword;
        UserService.update(user, (updatedUser) => {
          UserService.sendResetPasswordEmail(updatedUser, generatedPassword, () => onSuccess());
        }, onError);

      } else
        onSuccess();
    }).catch(onError)
  },

  sendResetPasswordEmail: (user, password, onSuccess) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(user.email);

    const templateValues = {
      email: user.email,
      password: password,
      subject: 'Your New Password',
      line_1: 'You requested a new password on the Charismatic Capital Debt Equity Fund Portal.',
      line_2: 'Your new credentials are as follows.',
      line_3: 'For your security, you are advised to change your password upon login.',
      line_4: 'Contact us immediately if you have not initiated this request.',
      line_5: ''
    };

    Backendless.Messaging.sendEmailFromTemplate('Password', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  },

  sendNewPassword: (user, onSuccess, onError) => {
    let generatedPassword = generate({
      length: 10,
      numbers: true
    });
    user.password = generatedPassword;

    UserService.update(user, (updatedUser) => {
      UserService.sendNewPasswordEmail(updatedUser, generatedPassword, () => onSuccess());
    }, onError);

  },

  sendNewPasswordEmail: (user, password, onSuccess) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(user.email);

    const templateValues = {
      email: user.email,
      password: password,
      subject: 'Your New Password',
      line_1: 'For security reasons, the Charismatic Capital Debt Equity Fund Portal has reset your password.',
      line_2: 'Your new credentials are as follows.',
      line_3: 'You are advised to change your password upon login.',
      line_4: '',
      line_5: ''
    };

    Backendless.Messaging.sendEmailFromTemplate('Password', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  },

  notifyAdminForAccessRequest: (subject, message, onSuccess, onError) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(ACCESS_REQUEST_ADDRESSEE_EMAIL);

    let msg = (typeof message==="undefined" || message===null) ? '' : message;

    const templateValues = {
      addressee: ACCESS_REQUEST_ADDRESSEE,
      subject: `Access Request for ${subject.email}`,
      message: `${subject.email} has registered on the Portal. His message is:`,
      message_2: msg,
      link_text: 'View User Information',
      link: `${PORTAL_URL}/pages/admin/user/${subject.objectid}`

    };

    Backendless.Messaging.sendEmailFromTemplate('Notification', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  },


  isValidLogin: onSuccess => {
    Backendless.UserService.isValidLogin()
      .then(onSuccess)
      .catch(console.log);
  },

  currentUser: (onSuccess, onError) => {
    Backendless.UserService.getCurrentUser()
      .then(onSuccess)
      .catch(onError);
  },

  getUser: (userId, onSuccess, onError) => {
    Backendless.Data.of(TBL_USERS).findById(userId)
      .then(onSuccess)
      .catch(onError);
  },

  update: (user, onSuccess, onError) => Backendless.UserService.update(user).then(onSuccess).catch(onError),

  updateStatus: (userId, status, onSuccess, onError) => {
    fetch(`/api/backendless/user/${userId}/${status}`).then(response => onSuccess(response.ok)).catch(onError)
  },

  logout: onSuccess => {
    Backendless.UserService.logout()
      .then(onSuccess)
      .catch(console.log);
  },

  listPaginator: (type, search, onSuccess, onError) => {
    let searchString = search.trim() === '' ? '' : `and (name like '%${search}%' or email like '%${search}%')`;
    let queryBuilder = Backendless.DataQueryBuilder.create();
    if (type === 'system')
      queryBuilder = queryBuilder.setWhereClause(`email like '%@charismatic.capital' ${searchString}`);
    else
      queryBuilder = queryBuilder.setWhereClause(`email not like '%@charismatic.capital' ${searchString}`);

    Backendless.Data.of(TBL_USERS).getObjectCount(queryBuilder).then(count => onSuccess(Math.ceil(count/PAGE_SIZE))).catch(onError);
  },
  list: (type, search, sortString, page, onSuccess, onError) => {
    let searchString = search.trim() === '' ? '' : `and (name like '%${search}%' or email like '%${search}%')`;
    let queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder.setOffset((page - 1) * PAGE_SIZE).setPageSize(PAGE_SIZE);
    if (type === 'system')
      queryBuilder = queryBuilder.setWhereClause(`email like '%@charismatic.capital' ${searchString}`);
    else
      queryBuilder = queryBuilder.setWhereClause(`email not like '%@charismatic.capital' ${searchString}`);

    queryBuilder = queryBuilder.setSortBy(sortString);

    Backendless.Data.of(TBL_USERS).find(queryBuilder).then(onSuccess).catch(onError);
  },

  uploadTermsContract: (objectId, isSigned, fileRef, onFileUploaded, onFileUploadError) => {
    Array.from(fileRef).forEach(fileObj => Backendless.Files.upload(fileObj, `contracts/${objectId}/${new Date().getTime()}`)
      .then(fileUrl => {
        const user = isSigned ? {
          objectId: objectId,
          signed_terms_contract_url: fileUrl.fileURL,
          signed_terms_contract_date: new Date()
        } : {
          objectId: objectId,
          terms_contract_url: fileUrl.fileURL,
          terms_contract_date: new Date()
        };
        Backendless.UserService.update(user).then(onFileUploaded).catch(onFileUploadError);
      })
      .catch(onFileUploadError));
  }
};