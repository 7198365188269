import React, {Component} from "react";
import {Modal, Header, Button, Icon, Form, TextArea} from "semantic-ui-react";
import PropTypes from "prop-types";

import { withTranslation} from "react-i18next";

class DataConfirmationDialog extends Component {
  state = {
    value: ''
  };

  descriptionData = {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.initialValue !== prevProps.initialValue) this.setState({
      value: this.props.initialValue
    });
  }

  handleChange = (e, { name, value}) => {
    this.descriptionData[name] = value;
    this.setState({ value: value});
  };

  render = () => {
    const {open, title, description, onYesClick, onNoClick} = this.props;
    const {value} = this.state;
    const {t} = this.props;

    return (
      <Modal open={open}>
        <Header icon={'question circle outline'} color={'red'} content={title} />
        <Modal.Content>
          <Form id={'description-form'} size='large'>
            <Form.Input
              name={'description'}
              label={description}
              control={TextArea}
              rows={3}
              value={value}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button name={'noBtn'} color='red' inverted onClick={onNoClick}>
            <Icon name='cancel' /> {t('button-cancel')}
          </Button>
          <Button name={'yesBtn'} color='green' inverted onClick={() => onYesClick(this.descriptionData)}>
            <Icon name='checkmark' /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DataConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  initialValue: PropTypes.string,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func
};

export default withTranslation("translations")(DataConfirmationDialog);