import React, {Component, Fragment} from "react";
import {Segment, Label, Form, TextArea, Grid, Step, Button} from "semantic-ui-react";
import {CountryDropdown} from "react-country-region-selector";
import {SubscriptionService} from "../../../../services/subscription-service";
import {SubscriptionDeclaration} from "../declaration";
import approve from "approvejs/dist/approve";

import { withTranslation} from "react-i18next";

class SubscriptionIndividualForm extends Component {
  state = {
    step: 1,
    isEditing: this.props.isEditing
  };

  subscription = {
    account_type: 'individual',
    status: 'new'
  };

  componentDidMount = () => {
    if (this.props.isEditing) {
      this.subscription = this.props.subscription;
      this.setState({ subscription: this.subscription, isAdmin: this.props.isAdmin});
    }
  };

  handleChange = (e, {name, value}) => {
    this.subscription[name] = value;
    this.setState({subscription: this.subscription});
  };

  handleCountryChange = (name, country) => {
    this.subscription[name] = country;
    this.setState({subscription: this.subscription});
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false});
      return true;
    } else {
      this.setState({ [error_key]: error_message});
      return false;
    }
  };

  doValidate = () => {
    const {name, nationality, profession, contact_number, email, relation_to_usa} = this.subscription;

    let requiredRule = {
      required: true
    };

    let requiredEmailRule = {
      required: true, email: true
    };

    let isValid1 = this.doValidateImpl(name, requiredRule, 'name_error', 'Please enter your name.');
    let isValid2 = this.doValidateImpl(nationality, requiredRule, 'nationality_error', 'Please enter your Nationality.');
    let isValid3 = this.doValidateImpl(profession, requiredRule, 'profession_error', 'Please enter your Profession.');
    let isValid4 = this.doValidateImpl(contact_number, requiredRule, 'contact_number_error', 'Please enter your contact number.');
    let isValid5 = this.doValidateImpl(email, requiredEmailRule, 'email_error', 'Please enter your email address.');
    let isValid6 = this.doValidateImpl(relation_to_usa, requiredRule, 'relation_to_usa_error', 'Please state any relation to the USA.');

    return isValid1 && isValid2 && isValid3 && isValid4 && isValid5 && isValid6;
  };

  doSubmit = () => {
    const {step} = this.state;
    if (step===1 && !this.doValidate()) return;
    SubscriptionService.subscribe(this.subscription, this.onSubscriptionSuccess, this.props.onError);
  };

  onSubscriptionSuccess = savedSubscription => {
    console.log("Done. Check Backendless DB");
    const { isEditing, isAdmin } = this.state;

    if (isEditing)
      if (isAdmin)
        this.props.history.push(`/pages/admin/subscription/${savedSubscription.objectId}`);
      else
        this.props.history.push(`/pages/subscription/${savedSubscription.objectId}`);
    else {
      SubscriptionService.notifyAdminOnCreate(savedSubscription.objectId, console.log, console.log);
      this.props.history.push('/pages/subscriptions');
    }
  };

  stepping = delta => {
    const {step} = this.state;
    this.setState({step: (step + delta)});
    window.scroll(0, 0);
  };

  upStep = () => {
    const {step} = this.state;
    if (step===1 && !this.doValidate()) return;

    this.stepping(1);
  }
  downStep = () => this.stepping(-1);

  render = () => {
    const {isEditing, step} = this.state;
    const {name, nationality, profession, contact_number, email, relation_to_usa, comments} = this.subscription;
    const {name_error, nationality_error, profession_error, contact_number_error, email_error, relation_to_usa_error} = this.state;

    const {t} = this.props;

    return (
      <Fragment>
        <Grid>
          <Grid.Column width={5}>
            <Step.Group ordered vertical fluid>
              <Step completed={step >= 2} disabled={step < 1}>
                <Step.Content>
                  <Step.Title>{ t('subscription-form-ind-step1')}</Step.Title>
                </Step.Content>
              </Step>
              { !isEditing && <Step disabled={step < 3}>
                <Step.Content>
                  <Step.Title>{ t('subscription-form-ind-step2')}</Step.Title>
                </Step.Content>
              </Step> }
            </Step.Group>
          </Grid.Column>
          <Grid.Column width={11}>
            {step === 1 &&
            <Segment padded>
              <Label color={'black'} attached={'top left'}>{ t('subscription-form-ind-title')}</Label>
              <Form id={'individual-subscription-form'} size='large'>
                <Form.Input
                  label={ t('subscription-form-ind-full-name')}
                  name={'name'}
                  value={name}
                  error={name_error}
                  onChange={this.handleChange}
                />
                <Form.Field
                  label={ t('subscription-form-ind-nationality')}
                  name={'nationality'}
                  error={nationality_error}
                  control={() => <CountryDropdown defaultOptionLabel={t('selector-country')} value={nationality}
                                                  onChange={country => this.handleCountryChange('nationality', country)}/>}
                />
                <Form.Input
                  label={ t('subscription-form-ind-profession')}
                  name={'profession'}
                  value={profession}
                  error={profession_error}
                  onChange={this.handleChange}
                />
                <Form.Input
                  label={ t('subscription-form-ind-contact')}
                  name={'contact_number'}
                  value={contact_number}
                  type={'number'}
                  error={contact_number_error}
                  onChange={this.handleChange}
                />
                <Form.Input
                  label={ t('subscription-form-ind-email')}
                  name={'email'}
                  value={email}
                  error={email_error}
                  onChange={this.handleChange}
                />
                <Form.Field
                  label={ t('subscription-form-ind-usa')}
                  name={'relation_to_usa'}
                  value={relation_to_usa}
                  error={relation_to_usa_error}
                  control={TextArea}
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.Input
                  label={t('subscription-form-comments')}
                  name={'comments'}
                  value={comments}
                  onChange={this.handleChange}
                  control={TextArea}
                  rows={3}
                />
              </Form>
            </Segment>
            }
            { !isEditing && step===2 && <SubscriptionDeclaration t={t}/> }

            { !isEditing && step !== 2 && <Segment clearing>
              { step!==1 && <Button color={'red'} onClick={this.downStep}>{t('button-back')}</Button> }
              <Button floated={"right"} color={'black'} onClick={this.upStep}>{t('button-next')}</Button>
            </Segment>}

            { !isEditing && step===2 && <Segment padded>
              <Button color={'red'} onClick={this.downStep}>{t('button-back')}</Button>
              <Button floated={"right"} color={'blue'} onClick={this.doSubmit}>{t('button-submit')}</Button>
            </Segment>}

            { isEditing && step===1 && <Segment padded textAlign={"right"}>
              <Button color={'blue'} onClick={this.doSubmit}>{t('button-save')}</Button>
            </Segment>}
          </Grid.Column>
        </Grid>
      </Fragment>
    )
  };
};

export default withTranslation("translations")(SubscriptionIndividualForm)