import PropTypes from 'prop-types';
import React from "react";
import {Menu, Sidebar, Icon} from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

const userNavigateTo = (e, {name}) => {
  const target = targetName => {
    switch (targetName) {
      case 'LoansPage': return '/#/pages/loans';
      case 'SubscriptionsPage': return '/#/pages/subscriptions';
      case 'ProfileContractPage': return '/#/pages/profile/contract';
      case 'InfosheetsPage': return '/#/pages/infosheets';
      case 'ContactPage': return '/#/pages/contact';
      case 'LinkedInPage': return 'https://www.linkedin.com/company/charismatic-capital-limited';
      default: return '/#/pages/dashboard';
    }
  };

  if (name==='LinkedInPage')
    window.open(target(name), '_blank');
  else
    window.location.href = target(name);
};

export const UserSidebar = ({ visible }) => {
  const { t } = useTranslation("translations");

  return (
    <Sidebar
      as={Menu}
      animation={'push'}
      direction={'left'}
      visible={visible}
      inverted
      vertical
      width={'wide'}
      >
      <Menu.Item as={'a'} name={'LoansPage'} onClick={userNavigateTo}>
        {t('sidebar-loan-request')}
      </Menu.Item>

      <Menu.Item as={'a'} name={'SubscriptionsPage'} onClick={userNavigateTo}>
        {t('sidebar-fund-subscription')}
      </Menu.Item>

      <Menu.Item as={'a'} name={'ProfileContractPage'} onClick={userNavigateTo}>
        {t('sidebar-tnc')}
      </Menu.Item>

      <Menu.Item as={'a'} name={'InfosheetsPage'} onClick={userNavigateTo}>
        {t('sidebar-fund-information')}
      </Menu.Item>

      <Menu.Item as={'a'} name={'LinkedInPage'} onClick={userNavigateTo} icon>
        {t('sidebar-about-cc')}<Icon name={"linkedin"} />
      </Menu.Item>

      <Menu.Item as={'a'} name={'ContactPage'} onClick={userNavigateTo} icon>
        {t('sidebar-contacts')}
      </Menu.Item>
    </Sidebar>
  );
};

UserSidebar.propTypes = {
  visible: PropTypes.bool
};

const adminNavigateTo = (e, {name}) => {
  const target = targetName => {
    switch (targetName) {
      case 'AdminLoanRequestsPage': return '/#/pages/admin/loan-requests';
      case 'AdminInfosheetsPage': return '/#/pages/admin/infosheets';
      case 'AdminSubscriptionsPage': return '/#/pages/admin/subscriptions';
      case 'UsersPage': return '/#/pages/admin/users';
      default: return '/#/pages/admin/dashboard';
    }
  };

  window.location.href = target(name);
};

export const AdministratorSidebar = ({ visible }) => {
  return (
    <Sidebar
      as={Menu}
      animation={'push'}
      direction={'left'}
      visible={visible}
      inverted
      vertical
      width={'wide'}
    >
      <Menu.Item as={'a'} name={'AdminLoanRequestsPage'} onClick={adminNavigateTo}>
        Loan Request
      </Menu.Item>

      <Menu.Item as={'a'} name={'AdminSubscriptionsPage'} onClick={adminNavigateTo}>
        Fund Subscription
      </Menu.Item>

      <Menu.Item as={'a'} name={'AdminInfosheetsPage'} onClick={adminNavigateTo}>
        Charismatic Debt Equity Fund Information
      </Menu.Item>

      <Menu.Item as={'a'} name={'UsersPage'} onClick={adminNavigateTo}>
        Users
      </Menu.Item>
    </Sidebar>
  );
};

AdministratorSidebar.propTypes = {
  visible: PropTypes.bool
};