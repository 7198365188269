import React,{ Component, createRef} from "react";
import {Grid, Container, Sticky, Sidebar} from "semantic-ui-react";
import {OLSHeader} from "../components/ols-header";
import {AdministratorSidebar, UserSidebar} from "../components/sidebars";

export default class SingleGridLayout extends Component {
  contextRef = createRef();

  state = {
    showSidebar: false
  };

  onHamburgerClick = () => {
    window.scroll(0, 0);
    const { showSidebar} = this.state;
    this.setState({
      showSidebar: !showSidebar
    })
  };

  render = () => {
    const { showSidebar} = this.state;
    return (
      <div ref={this.contextRef}>
        <Sticky context={this.contextRef}>
          <OLSHeader onHamburgerClick={this.onHamburgerClick} isShowing={showSidebar} isAdmin={this.props.isAdmin}/>
        </Sticky>

        <Sidebar.Pushable>
          { !this.props.isAdmin && <UserSidebar visible={showSidebar} /> }
          { this.props.isAdmin && <AdministratorSidebar visible={showSidebar} /> }

            <Sidebar.Pusher dimmed={showSidebar}>
              <Container style={{minHeight: '75vh', width: '85vw', paddingBlockStart: 15, paddingLeft: 15, paddingRight: 15, paddingBlockEnd: 20}}>
                <Grid textAlign='left' verticalAlign='top'>
                  <Grid.Column>
                    { this.props.children}
                  </Grid.Column>
                </Grid>
              </Container>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>

    );
  };
}
