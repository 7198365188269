import React, {Component, Fragment} from 'react';
import {Button, Label, Segment, Table} from "semantic-ui-react";
import SingleGridLayout from '../../layout/single-grid';
import {MessageDialog} from "../../components/message-dialog";
import {InfosheetService} from "../../services/infosheet-service";
import {FileViewerDialog} from "../../components/file-viewer-dialog";
import DataLoader from "../../components/data-loader";

import {withTranslation} from "react-i18next";

class InfosheetsPage extends Component {
  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    infosheets: [],
    infosheetDescription: '',
    infosheetFile: [],
    isLoading: true
  };


  componentDidMount = () => InfosheetService.listPublished(this.onListSuccess, this.onError);

  onListSuccess = listItems => {
    this.setState({ infosheets: listItems, isLoading: false});
  };

  onError = error => {
    if (!error.message.includes('Table not found')) {
      this.setState({
        dialogOpen: true,
        dialogTitle: 'Error Retrieving Infosheets',
        dialogDescription: error.message,
        isError: true,
        isLoading: false
      });
    } else
      this.setState({isLoading: false});
  };

  onMessageDialogOk = (e, {name}) => {
    const { isError } = this.state;
    this.setState({dialogOpen: false});

    if (isError) this.props.history.push("/pages/dashboard");
  };

  viewInfosheet = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({ fileViewerDialogOpen: false});

  render = () => {
    const { dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError, infosheets, isLoading} = this.state;
    const { fileViewerDialogOpen, fileViewerFileUrl} = this.state;
    const { t} = this.props;

    return (
      <Fragment>
        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl} onOkClick={this.onFileViewerDialogOkClick} />

        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>

        <SingleGridLayout>
          <DataLoader isLoading={isLoading}
                      itemsLength={infosheets.length}
                      notFoundMessage={'There are currently no Fund Information available.'}
          />
          { !isLoading && infosheets.length>0 &&
            <Segment padded>
              <Label color={'black'} attached={'top left'}>{t('infosheets-title')}</Label>

              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('infosheet-description')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('infosheet-date')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"center"}>{t('infosheet-view')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    infosheets.map(infosheet => (
                        <Table.Row key={infosheet.objectId}>
                          <Table.Cell>{ infosheet.description }</Table.Cell>
                          <Table.Cell>{ new Date(infosheet.created).toLocaleString() }</Table.Cell>
                          <Table.Cell textAlign={"center"}>
                            <Button color={'blue'} onClick={() => this.viewInfosheet(infosheet.fileURL)}>{t('button-view')}</Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )
                  }
                </Table.Body>
              </Table>
            </Segment>
          }
        </SingleGridLayout>
      </Fragment>

    )
  }
}

export default withTranslation("translations")(InfosheetsPage);