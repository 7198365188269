import React, {Component} from 'react';
import {Segment, Label, Button, Card} from "semantic-ui-react";
import SingleGridLayout from "../layout/single-grid";
import LoanStatisticComponent from "./shared/loan-statistic-component";
import SubscriptionStatisticComponent from "./shared/subscription-statistic-component";
import {withTranslation} from "react-i18next";

class DashboardPage extends Component {

  handleClick = (action) => {
    switch (action) {
      case "loan-requests":
        this.props.history.push("/pages/loans");
        break;
      case "subscriptions":
        this.props.history.push("/pages/subscriptions");
        break;
      case "open-individual-account":
        this.props.history.push("/pages/subscription-request-new/individual");
        break;
      case "open-corporate-account":
        this.props.history.push("/pages/subscription-request-new/corporate");
        break;
      case "request-loan":
        this.props.history.push("/pages/loan-request");
        break;
      default:
        this.props.history.push("/pages/dashboard");
    }
  };

  render = () => {
    let ownerId = localStorage.getItem('userId');
    let width = document.body.clientWidth;
    const cardCount = width > 768 ? 5 : 1;
    const cardCount2 = width > 768 ? 3 : 1;

    const { t } = this.props;

    return (
      <SingleGridLayout>
        <Segment padded>
          <Label attached={'top left'} color={'black'}>{t('dashboard-loans')}</Label>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount}>
              <LoanStatisticComponent ownerId={ownerId} />
              <LoanStatisticComponent ownerId={ownerId} status={"new"} />
              <LoanStatisticComponent ownerId={ownerId} status={"processing"} />
              <LoanStatisticComponent ownerId={ownerId} status={"rejected"} />
            </Card.Group>
          </Segment>
          <Segment basic textAlign={"right"}><Button color={'blue'} onClick={() => this.handleClick('loan-requests')}>{t('dashboard-loans-button')}</Button></Segment>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount2}>
              <LoanStatisticComponent ownerId={ownerId} status={"loan_rejected"} />
              <LoanStatisticComponent ownerId={ownerId} status={"loan_executed"} />
            </Card.Group>
          </Segment>
        </Segment>
        <Segment padded>
          <Label attached={'top left'} color={'black'}>{t('dashboard-subscriptions')}</Label>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount}>
              <SubscriptionStatisticComponent ownerId={ownerId} />
              <SubscriptionStatisticComponent ownerId={ownerId} status={"new"} />
              <SubscriptionStatisticComponent ownerId={ownerId} status={"processing"} />
              <SubscriptionStatisticComponent ownerId={ownerId} status={"rejected"} />
            </Card.Group>
          </Segment>
          <Segment basic textAlign={"right"}><Button color={'blue'} onClick={() => this.handleClick('subscriptions')}>{t('dashboard-subscriptions-button')}</Button></Segment>
          <Segment>
            <Card.Group centered itemsPerRow={cardCount2}>
              <SubscriptionStatisticComponent ownerId={ownerId} status={"subscription_rejected"} />
              <SubscriptionStatisticComponent ownerId={ownerId} status={"subscription_executed"} />
            </Card.Group>
          </Segment>
        </Segment>
        <Segment padded>
        <Label attached={'top left'} color={'black'}>{t('dashboard-shortcut')}</Label>
        <Segment><Button basic fluid color={'blue'} onClick={() => this.handleClick('open-individual-account')}>
          {t('dashboard-shortcut-individual-subscription')}</Button></Segment>
        <Segment><Button basic fluid color={'blue'} onClick={() => this.handleClick('open-corporate-account')}>
          {t('dashboard-shortcut-corporate-subscription')}</Button></Segment>
        <Segment><Button basic fluid color={'blue'} onClick={() => this.handleClick('request-loan')}>
          {t('dashboard-shortcut-loan-request')}</Button></Segment>
      </Segment>
      </SingleGridLayout>);
  }
}

export default withTranslation("translations")(DashboardPage)