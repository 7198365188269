import React from 'react'
import { Select } from 'semantic-ui-react'


export const LoanTypeSelector = ({ t, name, value, onChange}) => {
  const loanTypes = [
    { key: 'individual', value: 'individual', text: t('selector-loan_type-individual') },
    { key: 'corporate', value: 'corporate', text: t('selector-loan_type-corporate') }
  ];

  return (
    <Select placeholder={t('selector-loan_type')}
            options={loanTypes}
            name={name}
            value={value} onChange={onChange} />
  );
}



export const UserTypeSelector = ({t, name, value, onChange}) => {
  const userTypes = [
    { key: 'borrower', value: 'borrower', text: t('selector-borrower_referrer-borrower') },
    { key: 'referrer', value: 'referrer', text: t('selector-borrower_referrer-referrer') }
  ];
  return (
    <Select placeholder={t('selector-borrower_referrer')}
            options={userTypes}
            name={name}
            value={value} onChange={onChange} />
  );
}

export const YesNoSelector = ({t, name, value, onChange, placeholder}) => {
  const options = [
    { key: 'yes', value: 'yes', text: t('button-yes') },
    { key: 'no', value: 'no', text: t('button-no') }
  ];
  return (
    <Select placeholder={placeholder}
            options={options}
            name={name}
            value={value} onChange={onChange} />
  );
}