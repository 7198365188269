import React, {Component} from 'react';
import SingleGridLayout from '../../../layout/single-grid';
import { Segment } from "semantic-ui-react";

const title = 'Message!';

export default class UserCreatePage extends Component {
  state = {
    message: "UserCreate Page"
  };
  
  componentDidMount = () => {
  };

  render = () => {
    const {message} = this.state;
    
    return (
      <SingleGridLayout title={title}>
        <Segment color={'red'}>
          {message}
        </Segment>
      </SingleGridLayout>
    )
  }
}