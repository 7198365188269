import React from "react";
import {Form, Label, Segment, TextArea} from "semantic-ui-react";

export const SignatoryForm = props => {
  const isLoan = props.actionType === 'loan';
  const {signatory_name, signatory_contact_number, signatory_email} = props.data ? props.data : {};
  const {signatory_name_error, signatory_contact_number_error, signatory_email_error} = props.errors ? props.errors : {};

  return (
    <Segment padded>
      <Label color={'black'} attached={'top left'}>{props.title}</Label>
      <Form size='large'>
        <Form.Input
          label={props.nameLabel}
          name={'name'}
          value={signatory_name}
          error={signatory_name_error}
          onChange={props.onDataChange}
        />
        <Form.Input
          label={'Contact Number (Mobile Number)'}
          name={'contact_number'}
          value={signatory_contact_number}
          type={'number'}
          error={signatory_contact_number_error}
          onChange={props.onDataChange}
        />
        <Form.Input
          label={'Email Address'}
          name={'email'}
          value={signatory_email}
          error={signatory_email_error}
          onChange={props.onDataChange}
        />
        { isLoan && <Form.Field
          label={'Shareholdings'}
          name={'share_holdings'}
          control={TextArea}
          rows={2}
          onChange={props.onDataChange}
        />}
        { isLoan && <Form.Field
          label={'Any relation to USA'}
          name={'relation_to_usa'}
          control={TextArea}
          rows={2}
          onChange={props.onDataChange}
        />}
      </Form>
    </Segment>
  )
};