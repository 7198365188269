export const BACKENDLESS_APP_ID = "D099681D-92C1-44DE-9F7F-FEC3F2A9D48D";
export const BACKENDLESS_APP_KEY = "35101D10-F5CD-485C-9B71-8526CB967C2F";
export const BACKENDLESS_REST_KEY = "372D9FC0-1145-4B6B-BCCD-E4F6664B5ADB";

export const LOAN_REQUEST_ADDRESSEE = 'Kang Jie';
export const LOAN_REQUEST_ADDRESSEE_EMAIL = 'kangjie.ang@charismatic.capital';
export const SUBSCRIPTION_ADDRESSEE = 'Ching Ching';
export const SUBSCRIPTION_ADDRESSEE_EMAIL = 'lamcc@charismatic.capital';

export const PORTAL_URL = 'https://portal.charismatic.fund/#';

export const ACCESS_REQUEST_ADDRESSEE = 'Ching Ching, Kang Jie';
export const ACCESS_REQUEST_ADDRESSEE_EMAIL = ['kangjie.ang@charismatic.capital', 'lamcc@charismatic.capital'];

export const PAGE_SIZE = 20;