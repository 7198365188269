import React, { Component, Fragment } from 'react';
import SubscriptionIndividualForm from "./components/form/individual";
import SubscriptionCorporateForm from "./components/form/corporate";
import SingleGridLayout from "../../layout/single-grid";
import {MessageDialog} from "../../components/message-dialog";

export default class SubscriptionCreatePage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    isEditing: false,
    subscription: {},
    isLoading: true,
    isAdmin: false
  };

  componentDidMount = () => {
    if (this.props.location.state) {
      const {subscription, isAdmin} = this.props.location.state;

      if (subscription) {
        this.setState({ isEditing: true, isAdmin: isAdmin, subscription: subscription });
      }

      this.setState({isLoading: false});
    } else
      this.setState(() => ({subscriptionType: this.props.match.params.subscriptionType, isLoading: false }));
  };

  onMessageDialogOk = (e, {name}) => {
    this.setState({dialogOpen: false});
  };

  onError = error => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Error Subscribing. Please contact administrator.',
      dialogDescription: error.message,
      isError: true
    });

  };

  render = () => {
    const { isEditing, isAdmin, subscription, isLoading} = this.state;

    const {subscriptionType} = this.state;
    const isIndividual = isEditing ? subscription.account_type === 'individual' : subscriptionType === 'individual';

    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, isError} = this.state;

    return (
      <SingleGridLayout>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       onOkClick={this.onMessageDialogOk}/>
        { !isLoading && <Fragment>
          { isIndividual ? <SubscriptionIndividualForm onError={this.onError} isEditing={isEditing} subscription={subscription} isAdmin={isAdmin} {...this.props} /> : <SubscriptionCorporateForm onError={this.onError} isEditing={isEditing} subscription={subscription} isAdmin={isAdmin} {...this.props} /> }
        </Fragment>}
      </SingleGridLayout>
    )
  }
}