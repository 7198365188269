import Backendless from "backendless";

import {
  TBL_SUBSCRIPTION_ATTACHMENTS,
  TBL_SUBSCRIPTIONS
} from "./tables";
import {
  ACCESS_REQUEST_ADDRESSEE_EMAIL, PAGE_SIZE,
  PORTAL_URL, SUBSCRIPTION_ADDRESSEE,
  SUBSCRIPTION_ADDRESSEE_EMAIL
} from "../config";

export const corporateTypes = [
  { key: 'corporation', value: 'corporation', text: 'Corporation' },
  { key: 'partnership', value: 'partnership', text: 'Partnership', full_text: 'Partnership (Other than a Limited Liability Partnership' },
  { key: 'trust', value: 'trust', text: 'Trust' }
];

export const SubscriptionService = {
  save: (subscription, onSuccess, onError) => {
    Backendless.Data.of(TBL_SUBSCRIPTIONS).save(subscription).then(onSuccess).catch(onError);
  },

  get: (objectId, onSuccess, onError) => {
    Backendless.Data.of(TBL_SUBSCRIPTIONS).findById(objectId).then(onSuccess).catch(onError);
  },

  countTotal: (status, ownerId, onSuccess, onError) => {
    let statusQuery = requestStatus => {
      switch (requestStatus) {
        case 'new': return "status='new'";
        case 'approved': return "status in ('approved', 'processing')";
        case 'rejected': return "status='rejected'";
        case 'processing': return "status in ('approved', 'processing')";
        case 'subscription_executed': return "status='subscription_executed'";
        case 'subscription_rejected': return "status='subscription_rejected'";
        default: return "all";
      }
    };

    let query = statusQuery(status);
    if (ownerId) query = query==='all' ? `ownerId='${ownerId}'` : `${query} and ownerId='${ownerId}'`;
    let queryBuilder = Backendless.DataQueryBuilder.create();

    if (query!=="all") queryBuilder = queryBuilder.setWhereClause(query);

    Backendless.Data.of(TBL_SUBSCRIPTIONS).getObjectCount(queryBuilder).then(onSuccess).catch(onError);
  },

  listPaginator: (loanType, search, onSuccess, onError) => {
    let searchString = search.trim()==='' ? '' : `and (name like '%${search}%)`;
    let queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder = queryBuilder.setWhereClause(`account_type='${loanType}' ${searchString}`);

    Backendless.Data.of(TBL_SUBSCRIPTIONS).getObjectCount(queryBuilder).then(count => onSuccess(Math.ceil(count/PAGE_SIZE))).catch(onError);
  },

  list: (loanType, search, sortString, page, onSuccess, onError) => {
    let searchString = search.trim()==='' ? '' : `and (name like '%${search}%)`;
    let queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder = queryBuilder.setOffset((page - 1) * PAGE_SIZE).setPageSize(PAGE_SIZE);
    queryBuilder = queryBuilder.setWhereClause(`account_type='${loanType}' ${searchString}`);
    queryBuilder = queryBuilder.setSortBy(sortString);

    Backendless.Data.of(TBL_SUBSCRIPTIONS).find(queryBuilder).then(onSuccess).catch(onError);
  },

  listByOwner: (ownerId, loanType, search, onSuccess, onError) => {
    let searchString = search.trim()==='' ? '' : `and (name like '%${search}%)`;
    let queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder = queryBuilder.setPageSize(100).setWhereClause(`ownerId='${ownerId}' and account_type='${loanType}' ${searchString}`);
    queryBuilder = queryBuilder.setSortBy("created DESC");

    Backendless.Data.of(TBL_SUBSCRIPTIONS).find(queryBuilder).then(onSuccess).catch(onError);
  },

  subscribe: (subscription, onSuccess, onError) => {
    Backendless.Data.of(TBL_SUBSCRIPTIONS).save(subscription).then(onSuccess).catch(onError);
  },

  translateStatus: status => {
    switch (status) {
      case 'new': return 'New';
      case 'rejected': return 'Subscription Request Rejected.';
      case 'approved': return 'Subscription Request Approved.';
      case 'processing': return 'Processing.';
      case 'subscription_rejected': return 'Non Processing Subscription';
      case 'subscription_executed': return 'Subscription Executed';
      default: return 'Unknown';
    }
  },
  translateCorporateAccountType: accountType => {
    let accType = corporateTypes.filter(cType => cType.key===accountType)[0];
    return accType ? accType.text : 'Unknown';
  },

  translateUploadedBy: uploadedBy => uploadedBy==='user' ? 'You' : 'Fund Admin',
  translateUploadedByAdmin: uploadedBy => uploadedBy==='admin' ? 'Fund Admin' : 'User',

  uploadDocument: (objectId, fileRef, documentType, documentDescription, uploadedBy, onFileUploaded, onFileUploadError) => {
    Array.from(fileRef).forEach(fileObj => Backendless.Files.upload(fileObj, `subscriptions/${objectId}/${new Date().getTime()}`)
      .then(fileUrl => {
        fileUrl['status'] = 'new';
        fileUrl['document_type'] = documentType;
        fileUrl['uploaded_by_role'] = uploadedBy;
        fileUrl['document_description'] = documentDescription;
        fileUrl['published'] = uploadedBy==='user';
        Backendless.Data.of(TBL_SUBSCRIPTION_ATTACHMENTS).save(fileUrl)
          .then(savedAttachment => {
            Backendless.Data.of(TBL_SUBSCRIPTIONS).addRelation(
              { objectId: objectId},
              "attachments:" + TBL_SUBSCRIPTION_ATTACHMENTS + ":n",
              [savedAttachment]
            ).then(count => onFileUploaded(savedAttachment, count)).catch(onFileUploadError);
          })
          .catch(onFileUploadError);
      })
      .catch(onFileUploadError));
  },

  updateDocument: (document, onSuccess, onError) => Backendless.Data.of(TBL_SUBSCRIPTION_ATTACHMENTS).save(document).then(onSuccess).catch(onError),

  getDocuments: (subscriptionId, documentType, publishedOnly, onSuccess, onError) => {
    let queryBuilder = Backendless.LoadRelationsQueryBuilder.create();
    queryBuilder = queryBuilder.setRelationName("attachments");
    if (publishedOnly) queryBuilder = queryBuilder.setWhereClause("published=true");
    queryBuilder = queryBuilder.setWhereClause(`document_type = '${documentType}'`);
    queryBuilder = queryBuilder.setPageSize(100).setSortBy('created DESC');

    Backendless.Data.of(TBL_SUBSCRIPTIONS).loadRelations(subscriptionId, queryBuilder).then(onSuccess).catch(onError);
  },

  notifyAdmin: (subscriptionId, subject, message, onSuccess, onError) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(SUBSCRIPTION_ADDRESSEE_EMAIL);

    const templateValues = {
      addressee: SUBSCRIPTION_ADDRESSEE,
      subject: subject,
      message: message,
      link_text: 'View Subscription Request',
      link: `${PORTAL_URL}/pages/admin/subscription/${subscriptionId}`
    };

    Backendless.Messaging.sendEmailFromTemplate('Notification', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  },

  notifyAdminOnCreate: (subscriptionId, onSuccess, onError) => SubscriptionService.notifyAdmin(subscriptionId, 'New Subscription', 'A new subscription request has been received.', onSuccess, onError),

  notifyUser: (user, subscriptionId, subject, message, onSuccess, onError) => {
    const envelope = new Backendless.Messaging.EmailEnvelope();
    envelope.setTo(user.email);
    envelope.setCc(ACCESS_REQUEST_ADDRESSEE_EMAIL);

    const templateValues = {
      addressee: user.name,
      subject: subject,
      message: message,
      link_text: 'View Subscription',
      link: `${PORTAL_URL}/pages/subscription/${subscriptionId}`
    };

    Backendless.Messaging.sendEmailFromTemplate('Notification', envelope, templateValues)
      .then(onSuccess).catch(console.log);
  }
};