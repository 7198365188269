import React, {Component} from "react";
import {Card, Statistic} from "semantic-ui-react";
import {SubscriptionService} from "../../services/subscription-service";
import {withTranslation} from "react-i18next";

class SubscriptionStatisticComponent extends Component {
  state = {
    color: "black",
    label: "",
    count: 0,
    status: this.props.status,
    ownerId: this.props.ownerId
  };

  componentDidMount = () => this.loadData();

  getColorLabel = () => {
    const {status} = this.state;
    const {t} = this.props;
    switch (status) {
      case 'new': return { color: "blue", label: t('dashboard-new') };
      case 'processing': return { color: "yellow", label: t('dashboard-processing') };
      case 'rejected': return { color: "red", label: t('dashboard-requests-rejected') };
      case 'subscription_rejected': return { color: "red", label: t('dashboard-subscriptions-non-processing')  };
      case 'subscription_executed': return { color: "green", label: t('dashboard-subscriptions-executed')  };
      default: return { color: "black", label: t('dashboard-total') };
    }
  };

  loadData = () => {
    const {status, ownerId} = this.state;
    SubscriptionService.countTotal(status, ownerId, (count) => this.setState({count: count}), console.log);
  };

  render = () => {
    const { count} = this.state;
    const { color, label} = this.getColorLabel();

    return (
      <Card color={color}>
        <Card.Content textAlign={"center"}>
          <Statistic color={color}>
            <Statistic.Label>
              {label}
            </Statistic.Label>
            <Statistic.Value>
              {count}
            </Statistic.Value>
          </Statistic>
        </Card.Content>
      </Card>
    );
  }
}

export default withTranslation("translations")(SubscriptionStatisticComponent)
