import React, {Component} from "react";
import {Segment, Label, Table, Form, TextArea, Button, Message} from "semantic-ui-react";
import { UserService} from "../../../services/user-service";
import SingleGridLayout from "../../../layout/single-grid";
import {FileViewerDialog} from "../../../components/file-viewer-dialog";
import {MessageDialog} from "../../../components/message-dialog";
import {StringUtils} from "../../../components/strings";

export default class UserView extends Component {

  state = {
    fileViewerDialogOpen: false,
    fileViewerFileUrl: '',
    currentUser: {},
    dialogOpen: false,
    dialogTitle: '', dialogMessage: '', dialogDescription: '',
    isError: false,
    isLoading: true
  };

  componentDidMount = () => {
    const {userId} = this.props.match.params;
    UserService.getUser(userId, this.onCurrentUserSuccess, console.log);
  };

  onCurrentUserSuccess = currentUser => {
    this.setState({currentUser: currentUser});
  };

  onError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Retrieving Loan Request',
    dialogMessage: "There's an error retrieving the requested record. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  /* Indicative Financing Terms */
  handleChange = (e, {name, value}) => {
    const { currentUser} = this.state;
    currentUser[name] = value;
    this.setState({currentUser: currentUser});
  };

  doSendIndicativeTerms = () => {
    const {currentUser} = this.state;

    UserService.update({
      objectId: currentUser.objectId,
      indicative_terms: currentUser.indicative_terms,
      indicative_terms_date: new Date(),
      status: 'indicative_terms_sent'
    }, this.indicativeTermsUpdated, this.onError)
  };

  indicativeTermsUpdated = user => this.setState({
    dialogOpen: true,
    dialogTitle: 'Indicative Financing Terms Sent',
    dialogMessage: "Indicative Financing Terms saved successfully",
    isError: false,
    currentUser: user
  });

  /* Term Sheet */
  onFileChange = (e, {name}) => this.setState({[name]: e.target.files});

  uploadTermsContract = () => {
    const {currentUser, terms_contract} = this.state;

    if (currentUser && currentUser.objectId && terms_contract)
      UserService.uploadTermsContract(currentUser.objectId, false, terms_contract,this.termsContractUploaded, this.termsContractUploadError);

  };

  termsContractUploaded = user => this.setState({currentUser: user});

  termsContractUploadError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Uploading Term Sheet',
    dialogMessage: "There's an error uploading the Term Sheet. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  viewTermsContract = (fileUrl) => this.setState({
    fileViewerFileUrl: fileUrl,
    fileViewerDialogOpen: true
  });

  onFileViewerDialogOkClick = () => this.setState({fileViewerDialogOpen: false});

  doChangePassword = () => {
    const {currentUser} = this.state;

    if (currentUser.password && currentUser.password.toString().trim() === '') {
      delete currentUser['password'];
      return;
    }

    UserService.update({
      objectId: currentUser.objectId,
      password: currentUser.password
    }, this.onSavePassword, this.onSavePasswordError)
  };

  onSavePassword = user => {
    this.setState({
      dialogOpen: true,
      dialogTitle: 'Password Set',
      dialogMessage: "Password has been set for the user.",
      isError: false,
      currentUser: user
    });
  };

  onSavePasswordError = error => this.setState({
    dialogOpen: true,
    dialogTitle: 'Error Setting Password',
    dialogMessage: "There's an error setting password for the user. Please contact administrator.",
    dialogDescription: error.message,
    isError: true,
    isLoading: false
  });

  onMessageDialogOk = () => this.setState({dialogOpen: false});


  render = () => {
    const {currentUser} = this.state;

    const { name, mailing_address, nationality, profession, contact_number, email, indicative_terms, indicative_terms_date, terms_contract_url, terms_contract_date, signed_terms_contract_url, signed_terms_contract_date, password} = currentUser;

    const { fileViewerDialogOpen, fileViewerFileUrl} = this.state;

    const {dialogOpen, dialogTitle, dialogMessage, dialogDescription, dialogOkLabel, isError} = this.state;

    return (
      <SingleGridLayout isAdmin>
        <MessageDialog open={dialogOpen}
                       title={dialogTitle}
                       message={dialogMessage}
                       description={dialogDescription}
                       isError={isError}
                       okLabel={dialogOkLabel}
                       onOkClick={this.onMessageDialogOk}/>

        <FileViewerDialog open={fileViewerDialogOpen} fileUrl={fileViewerFileUrl}
                          onOkClick={this.onFileViewerDialogOkClick}/>
      <Segment padded>
        <Label color={'black'} attached={'top left'}>Profile</Label>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>{name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Mailing Address</Table.Cell>
              <Table.Cell>{mailing_address}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Nationality</Table.Cell>
              <Table.Cell>{nationality}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Profession</Table.Cell>
              <Table.Cell>{profession}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Contact Number (Mobile Number)</Table.Cell>
              <Table.Cell>{contact_number}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Segment color={"red"}>
          <Label attached={"top"} color={"red"}>Set Password</Label>
          <Form>
            <Form.Input
              label={'Key in a new Password on behalf of the user'}
              name={'password'}
              type={'password'}
              value={StringUtils.denull(password)}
              onChange={this.handleChange}
            />
          </Form>

        </Segment>
        <Segment basic textAlign={"right"}>
          <Button color={'blue'} onClick={this.doChangePassword}>Set Password</Button>
        </Segment>
      </Segment>

      <Segment padded>
          <Label color={'black'} attached={'top left'}>Indicative Financing Terms</Label>
          <Form id={'indicative-terms-form'} size='large'>
            <Form.Field
              label={'Indicative Financing Terms'}
              name={'indicative_terms'}
              value={indicative_terms}
              control={TextArea}
              rows={10}
              onChange={this.handleChange}
            />
          </Form>
          <Segment padded style={{minHeight: 75}}>
            {indicative_terms_date && `Indicative Financing Terms saved on ${new Date(indicative_terms_date).toLocaleString()}`}

            <Button floated={"right"} color={'blue'} onClick={this.doSendIndicativeTerms}>Send Terms</Button>
          </Segment>
        </Segment>

        <Segment padded>
          <Label color={'black'} attached={'top left'}>Terms and Contract</Label>
          {!terms_contract_date && <Message negative>
            <Message.Header>
              No Data Found
            </Message.Header>
            <p>
              No Terms and Contract Uploaded.
            </p>
          </Message>
          }
          { terms_contract_date &&
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Upload Date</Table.Cell>
                <Table.Cell>{new Date(terms_contract_date).toLocaleString()}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Terms and Contract</Table.Cell>
                <Table.Cell>
                  <Button color={'blue'} onClick={() => this.viewTermsContract(terms_contract_url)}>View</Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          }

          {!terms_contract_date &&
          <Segment>
            <Form>
              <Form.Input
                label={'Terms and Contract'}
                name={'terms_contract'}
                type={'file'}
                accept={'.pdf'}
                onChange={this.onFileChange}
              />

              <Button onClick={this.uploadTermsContract} color={'blue'}>Upload Terms and Contract</Button>
            </Form>
          </Segment>}

          { terms_contract_date && !signed_terms_contract_date && <Message negative>
            <Message.Header>
              No Data Found
            </Message.Header>
            <p>
              No Signed Terms and Contract Uploaded.
            </p>
          </Message>
          }
          { signed_terms_contract_date &&
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Upload Date</Table.Cell>
                <Table.Cell>{new Date(signed_terms_contract_date).toLocaleString()}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Signed Terms and Contract</Table.Cell>
                <Table.Cell>
                  <Button color={'blue'} onClick={() => this.viewTermsContract(signed_terms_contract_url)}>View</Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          }
        </Segment>
      </SingleGridLayout>
    )
  };
};
